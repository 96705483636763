import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getCostTypesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllCostTypes,
    selectError,
    updateCostType,
    selectSelectedCostType,
    setSelectedCostType,
    addCostType,
    selectCount,
    resetCostTypesSlice,
} from '../../../common/store/slices/costTypesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { CostTypeItemData } from '../../../common/interfaces/businessDataItem/costTypeItem';

const COST_TYPES_TABLE_TITLE = 'Cost types';
const COST_TYPES_DISPLAY_SINGULAR = 'Cost type';
export const COST_TYPE_NAME_KEY = 'cost_type';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (costType: CostTypeItemData) =>
            formatStatus(costType.active.toString()),
    },
];

/**
 * Editable form fields for CostType
 * The keys must map to CostTypeItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<CostTypeItemData> = {
    // the keys must match CostTypeItem properties
    cost_type: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${COST_TYPES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<CostTypeItemData> => {
            return { cost_type: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<CostTypeItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<CostTypeItemData> = {
    ...createFormSchema,
    cost_type: {
        ...createFormSchema.cost_type,
        disabled: true,
    },
};

const AdminCostTypes = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useCostTypes(): AdminBusinessDataSelectors<CostTypeItemData> {
        const dispatch = useDispatch();

        const items: CostTypeItemData[] = useSelector(selectAllCostTypes);
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: CostTypeItemData | null = useSelector(
            selectSelectedCostType,
        );
        useEffect(() => {
            dispatch(getCostTypesList());
            dispatch(setSelectedCostType(null));

            return () => {
                dispatch(resetCostTypesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (costType: CostTypeItemData) => {
        if (costType) {
            dispatch(setSelectedCostType(costType.cost_type));
            await dispatch(getCostTypesList());
        }
    };

    const handleItemUpdate = async (data: CostTypeItemData) => {
        dispatch(setSelectedCostType(null));
        const dispatchPromise = dispatch<any>(updateCostType(data));
        await handleBusinessDataNotification({
            businessDataType: COST_TYPE_NAME_KEY,
            businessDataDisplayNameLower: COST_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.cost_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        cost_type,
        active,
    }: CostTypeItemData) => {
        const dispatchPromise = dispatch<any>(addCostType(active, cost_type));
        await handleBusinessDataNotification({
            businessDataType: COST_TYPE_NAME_KEY,
            businessDataDisplayNameLower: COST_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: cost_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementCostTypes">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={COST_TYPES_DISPLAY_SINGULAR}
                itemNameKey={COST_TYPE_NAME_KEY}
                title={COST_TYPES_TABLE_TITLE}
                useItemList={useCostTypes}
            />
        </div>
    );
};

export default AdminCostTypes;
