import 'regenerator-runtime';
import { Workbook } from 'exceljs';
import React, { useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Container,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import FileUpload, { FileType } from '../../../../common/components/FileUpload';
import { useHistory } from 'react-router-dom';
import { ExcelUtil } from '../../../../common/utils/excel';
import instructorManagementApi from '../../../../imt/api/instructorManagementApi';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import TemplateExportModal, {
    TemplateExportProps,
} from './TemplateExportModal';

export const PAGE_TITLE = 'Import freelancer schedule';
export const CONTAINER_HEADER = 'File select';
const IMPORT_FREELANCERS_BUTTON = 'Import';
const IMPORTING_FREELANCERS_BUTTON = 'Importing';
const CANCEL_IMPORT_FREELANCERS_BUTTON = 'Cancel';

export const ImportFreelancer = () => {
    const [file, setFile] = useState<FileType>(null);
    const [resetKey, setResetKey] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [importing, setImporting] = useState(false);
    const [showTemplateExportModal, setShowTemplateExportModal] =
        useState(false);

    const { addNotification } = useNotifications();
    const history = useHistory();

    const handleFileUpload = async () => {
        const workbook = new Workbook();
        const excelUtil = new ExcelUtil(workbook);
        await excelUtil.readWorkbook(file);
        const instructors_availability = [];
        for (const [_, value] of Object.entries(
            excelUtil.freelancerAvailability.instructors_availability,
        )) {
            instructors_availability.push(value);
        }

        const bulkFreelancerPaylod = {
            start_date: excelUtil.freelancerAvailability.start_date,
            end_date: excelUtil.freelancerAvailability.end_date,
            instructors_availability,
        };

        try {
            setImporting(true);
            const response = await instructorManagementApi.batchAddBlockedTimes(
                bulkFreelancerPaylod,
            );

            if (response.result.not_found_instructors.length > 0) {
                const noInstructorList =
                    response.result.not_found_instructors.toString();
                addNotification({
                    id: `import-freelancer-schedule-${Date.now()}`,
                    ...{
                        type: 'error',
                        content: `The following instructors do not exist in they system: ${noInstructorList}`,
                    },
                });
            } else {
                history.push({
                    pathname: '/activities/instructors',
                });

                addNotification({
                    id: `import-freelancer-schedule-${Date.now()}`,
                    ...{
                        type: 'success',
                        content: 'Freelancer schedule updated successfully.',
                    },
                });
            }
        } catch (error) {
            if (
                error.response.message ===
                'Unavailable dates conflicting with previous assignments.'
            ) {
                setErrorMessage(
                    'Download excel file containing the instructors and their conflicting assignments.',
                );
                const workbook = new Workbook();
                const excelUtil = new ExcelUtil(workbook);
                excelUtil
                    .generateErrorReport(error.response.conflicting_assignments)
                    .saveAsUrl();
            }

            addNotification({
                id: `import-freelancer-schedule-${Date.now()}`,
                ...{
                    type: 'error',
                    content: error.response.message,
                },
            });
        } finally {
            setImporting(false);
        }
    };

    const handleFileChange = (event: CustomEvent<any>) => {
        const value: FileType = event.detail.value;
        setResetKey(Math.random().toString(36));
        setFile(value);
        setErrorMessage('');
    };

    const handleCancelImport = () => {
        history.push({
            pathname: '/activities/instructors',
        });
    };

    const templateExportProps: TemplateExportProps = {
        showTemplateExportModal,
        setShowTemplateExportModal,
    };

    return (
        <>
            <Header variant="h1">{PAGE_TITLE}</Header>
            <Box margin={{ top: 'l' }}>
                <Container
                    header={<Header variant="h2">{CONTAINER_HEADER}</Header>}
                >
                    <SpaceBetween direction="vertical" size="xs">
                        <Button
                            onClick={() => setShowTemplateExportModal(true)}
                            data-testid="template-download-btn"
                        >
                            Generate Excel Template
                        </Button>
                        <Box variant="p">
                            Using the generate template button, select a date
                            range less than 6 months and download the template.
                            Your selected date range will populate in column A
                            of the template.
                        </Box>
                        <Box variant="p">
                            Add the freelance trainers email in column B, row 1.
                            Add "Available" or "Unavailable" next to each
                            corresponding date in column B. You can add any free
                            text notes next to each corresponding date in column
                            C of the template, notes will not be uploaded and
                            will be for your reference only. Multiple freelance
                            trainers availability can be added into the
                            template, repeat the process in the remaining
                            columns for additional trainers.
                        </Box>
                        <Box variant="p">
                            Upload the completed availability template. If there
                            are scheduling conflicts, the upload will fail and
                            you will be able to download an excel spreadsheet
                            containing all conflicting activities.
                        </Box>
                        <Box margin={{ bottom: 'l' }}>
                            <FileUpload
                                buttonText={'Choose file'}
                                value={file}
                                onChange={(event) => handleFileChange(event)}
                                accept={'.xlsx,.xls'}
                                key={resetKey}
                            />
                        </Box>
                        <Alert visible={!!errorMessage} type="warning">
                            {errorMessage}{' '}
                            <a href="/" id="error-file-download">
                                {' '}
                            </a>
                        </Alert>
                    </SpaceBetween>
                </Container>
                <Box margin={{ top: 'l', bottom: 'l' }} float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={handleCancelImport}
                            data-testid="cancel-import-activities"
                        >
                            {CANCEL_IMPORT_FREELANCERS_BUTTON}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleFileUpload}
                            disabled={!file || importing}
                            data-testid="import-activities-btn"
                        >
                            {importing
                                ? IMPORTING_FREELANCERS_BUTTON
                                : IMPORT_FREELANCERS_BUTTON}
                        </Button>
                    </SpaceBetween>
                </Box>
            </Box>
            <TemplateExportModal {...templateExportProps} />
        </>
    );
};

export default ImportFreelancer;
