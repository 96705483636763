import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getCertificationsList,
    selectIsLoading,
    selectIsLoaded,
    selectAllCertifications,
    selectError,
    updateCertification,
    selectSelectedCertification,
    setSelectedCertification,
    addCertification,
    selectCount,
    resetCertificationsSlice,
} from '../../../common/store/slices/certificationsSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { CertificationItemData } from '../../../common/interfaces/businessDataItem/certificationItem';

const CERTIFICATIONS_TABLE_TITLE = 'Certifications';
const CERTIFICATIONS_DISPLAY_SINGULAR = 'Certification';
export const CERTIFICATIONS_NAME_KEY = 'certification';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (certification: CertificationItemData) =>
            formatStatus(certification.active.toString()),
    },
];

/**
 * Editable form fields for Certification
 * The keys must map to CertificationItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<CertificationItemData> = {
    // the keys must match InstructorDataType properties
    certification: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${CERTIFICATIONS_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<CertificationItemData> => {
            return { certification: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<CertificationItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<CertificationItemData> = {
    ...createFormSchema,
    certification: {
        ...createFormSchema.certification,
        disabled: false,
    },
};

const AdminCertifications = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();
    const currentItem: CertificationItemData | null = useSelector(
        selectSelectedCertification,
    );

    function useCertifications(): AdminBusinessDataSelectors<
        CertificationItemData
    > {
        const dispatch = useDispatch();

        const items: CertificationItemData[] = useSelector(
            selectAllCertifications,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);

        useEffect(() => {
            dispatch(getCertificationsList());
            dispatch(setSelectedCertification(null));

            return () => {
                dispatch(resetCertificationsSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (certification: CertificationItemData) => {
        if (certification) {
            dispatch(setSelectedCertification(certification.certification));
            await dispatch(getCertificationsList());
        }
    };

    const handleItemUpdate = async (data: CertificationItemData) => {
        dispatch(setSelectedCertification(null));
        const dispatchPromise = dispatch<any>(
            updateCertification(data, currentItem),
        );
        await handleBusinessDataNotification({
            businessDataType: CERTIFICATIONS_NAME_KEY,
            businessDataDisplayNameLower: CERTIFICATIONS_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.certification,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        certification,
        active,
    }: CertificationItemData) => {
        const dispatchPromise = dispatch<any>(
            addCertification(active, certification),
        );
        await handleBusinessDataNotification({
            businessDataType: CERTIFICATIONS_NAME_KEY,
            businessDataDisplayNameLower: CERTIFICATIONS_DISPLAY_SINGULAR.toLowerCase(),
            itemName: certification,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementCertifications">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={CERTIFICATIONS_DISPLAY_SINGULAR}
                itemNameKey={CERTIFICATIONS_NAME_KEY}
                title={CERTIFICATIONS_TABLE_TITLE}
                useItemList={useCertifications}
            />
        </div>
    );
};

export default AdminCertifications;
