import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
    Alert,
    Spinner,
    Button,
    ButtonDropdown,
    TablePropertyFiltering,
    Modal,
    FormField,
    Input,
    Badge,
} from '@amzn/awsui-components-react';
import {
    DateRangePicker,
    DateRangePickerProps,
    Table,
    Pagination,
    CollectionPreferences,
    Link as HTTPLink,
} from '@amzn/awsui-components-react-v3';
import {
    ActivityData,
    CommercialPrivateCustomer,
    Customer,
    Invoice,
} from '../../../interfaces/activity';
import Can from '../../../../common/components/Can';
import TableHeader from '../../../../common/components/TableHeader/TableHeader';
import { Actions } from '../../../../common/constants/auth';
import {
    AJAX_CALL_ERROR,
    ACTIVITY_TABLE_TITLE,
    LOADING_TEXT,
    CREATE_ACTIVITY_BUTTON,
    PAGE_SELECTOR_OPTIONS,
    IMPORT_ACTIVITIES_BUTTON,
    EMPTY_STRING,
} from '../../../../common/constants/grimsby';
import { useSelector, useDispatch } from 'react-redux';
import {
    getActivityList,
    selectActivityList,
    selectError,
    selectIsLoading,
    selectIsLoaded,
    selectPagesCount,
    selectCurrentPageIndex,
    selectTotalActivitiesCount,
    selectSize,
    setFrom,
    setCurrentPageIndex,
    setTotalActivitiesCount,
    setIsLoaded,
    setSize,
    initializeUserPreference,
    selectVisibleColumns,
    setVisibleColumns,
    setSavedFilter,
    setSearchText,
    setSortFields,
    selectSearchText,
    selectSavedFilter,
    selectDateRangeFilter,
    setDateRangeFilter,
} from '../../../store/slices/activityListSlice';
import {
    resetLocationsSlice,
    initializeLocationsListQueryParams,
    getLocationsList,
    selectAllActiveLocations,
    selectIsLoading as selectIsLocationListLoading,
    selectIsLoaded as selectIsLocationListLoaded,
} from '../../../../common/store/slices/locationsSlice';
import {
    DATE_RANGE_FILTER_KEY,
    formatGrimsbyDate,
    formatNumber,
} from '../Common/Common';
import {
    updatePreferencesAndSavedFilters,
    selectIsLoading as selectIsUserLoading,
    selectUserId,
    selectUser,
    selectActivityListSavedFilterLookup,
    selectActivityListSavedFilters,
} from '../../../../common/store/slices/userSlice';
import debounce from '../../../../common/utils/debounce';
import handleLocalStorageSetItem from '../../../../common/utils/handleLocalStorageSetItem';
import {
    getActivityStatusesList,
    selectAllActiveActivityStatuses,
    selectIsLoaded as selectIsActivityStatusListLoaded,
    selectIsLoading as selectIsActivityStatusListLoading,
} from '../../../../common/store/slices/activityStatusesSlice';
import {
    getActivityTypesList,
    selectAllActiveActivityTypes,
    selectIsLoaded as selectIsActivityTypeListLoaded,
    selectIsLoading as selectIsActivityTypeListLoading,
} from '../../../../common/store/slices/activityTypesSlice';
import {
    getInstructorRoleTypesList,
    selectAllActiveInstructorRoleTypes,
    selectIsLoaded as selectIsInstructorRoleTypeListLoaded,
    selectIsLoading as selectIsInstructorRoleTypeListLoading,
} from '../../../../common/store/slices/instructorRoleTypesSlice';
import {
    getInstructorTypesList,
    selectAllActiveInstructorTypes,
    selectIsLoaded as selectIsInstructorTypeListLoaded,
    selectIsLoading as selectIsInstructorTypeListLoading,
} from '../../../../common/store/slices/instructorTypesSlice';
import {
    getRegionsList,
    selectAllActiveRegions,
    selectIsLoading as selectIsRegionListLoading,
    selectIsLoaded as selectIsRegionListLoaded,
} from '../../../../common/store/slices/regionsSlice';
import {
    getActivityAudiencesList,
    selectAllActiveActivityAudiences,
    selectIsLoaded as selectIsActivityAudienceListLoaded,
    selectIsLoading as selectIsActivityAudienceListLoading,
} from '../../../../common/store/slices/activityAudiencesSlice';
import {
    getActivityModalitiesList,
    selectAllActiveActivityModalities,
    selectIsLoaded as selectIsActivityModalityListLoaded,
    selectIsLoading as selectIsActivityModalityListLoading,
} from '../../../../common/store/slices/activityModalitiesSlice';
import {
    getCoursesList,
    selectAllActiveCourses,
    selectIsLoaded as selectIsCourseListLoaded,
    selectIsLoading as selectIsCourseListLoading,
} from '../../../../common/store/slices/coursesSlice';
import {
    getDeliveryLanguagesList,
    selectAllActiveDeliveryLanguages,
    selectIsLoaded as selectIsDeliveryLanguageLoaded,
    selectIsLoading as selectIsDeliveryLanguageLoading,
} from '../../../../common/store/slices/deliveryLanguagesSlice';
import {
    getPartnerInitiativesList,
    selectAllActivePartnerInitiatives,
    selectIsLoaded as selectIsPartnerInitiativeListLoaded,
    selectIsLoading as selectIsPartnerInitiativeListLoading,
} from '../../../../common/store/slices/partnerInitiativesSlice';
import {
    resetAtpCompaniesSlice,
    initializeAtpCompaniesListQueryParams,
    getAtpCompaniesList,
    selectAllActiveAtpCompanies,
    selectIsLoading as selectIsAtpListLoading,
    selectIsLoaded as selectIsAtpListLoaded,
} from '../../../../common/store/slices/atpCompaniesSlice';
import { formatString } from '../../../../common/utils/formatString';
import { formatStringArray } from '../../../../common/utils/formatStringArray';
import handleActivityListNotification from '../../../../common/utils/handleActivityListNotification';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import { PropertyFilterKeys } from '../../../enums/propertyFilterKeys';
import { ActivityFilters } from '../../../interfaces/activityFilters';
import { ScheduleManagementAPIQueryParams } from '../../../interfaces/queryParams';
import { getKikuURL } from '../../../../common/utils/getKikuURL';

import {
    FieldArray,
    Form,
    Formik,
    FormikProps,
    Field,
    FieldProps,
} from 'formik';
import { FieldMetaProps } from 'formik/dist/types';

import './ActivityList.scss';
import { ACTIVITY_PATH } from '../../../constants/path';
import { ActivityAudienceItemData } from '../../../../common/interfaces/businessDataItem/activityAudienceItem';
import {
    dateRangeFilterI18nStrings,
    differenceInDays,
} from '../../../../common/utils/date-range-picker';
import {PartnerInitiativeItemData} from "../../../../common/interfaces/businessDataItem/partnerInitiativeItem";

interface SavedFilter {
    id: string;
    name: string;
    filter: ActivityFilters;
}
interface SavedFilterForm {
    filters: Array<SavedFilter>;
}

export const APPROVED_COURSE_STATUS = 'Approved';

enum ActivityTableColumnId {
    AdditionalOwners = 'additional_owners',
    Name = 'activity_name',
    BillingStatus = 'billing_status',
    StartDate = 'start_date',
    EndDate = 'end_date',
    Status = 'activity_status',
    Type = 'activity_type',
    Audience = 'activity_audience',
    PartnerInitiative = 'partner_initiative',
    CourseName = 'course_name',
    Instructors = 'instructors',
    Modality = 'activity_modality',
    Customers = 'customers',
    Location = 'location',
    ClassRequestSIM = 'class_request_sim',
    DeliveryLanguage = 'delivery_language',
    OperationsOwner = 'operations_owner',
    Program = 'program',
    Providers = 'provider',
    Region = 'delivery_region',
    Requestor = 'requestor',
    TOFStatus = 'tof_status',
    LMSId = 'lms_id',
    Attended = 'attended',
    Registered = 'registered',
    ClassSize = 'class_size',
    Scheduler = 'scheduler',
    CustomerSuccessManager = 'customer_support_manager'
}

export const ACTIVITY_FILTER_STORAGE_KEY = 'activity_filter';
const MANAGE_FILTER_CONFIG = {
    text: 'Manage filters',
    id: 'manage_filters',
} as const;

enum PropertyFilterLabels {
    AdditionalOwners = 'Additional owner',
    Audience = 'Audience',
    Status = 'Status',
    Type = 'Type',
    UnassignedInstructorRole = 'Unassigned Instructor Role',
    AssignedInstructorRole = 'Assigned Instructor Role',
    Geography = 'Geo',
    Region = 'Region',
    City = 'City',
    Country = 'Country',
    Providers = 'Provider',
    DeliveryCountries = 'Delivery countries',
    DeliveryLanguage = 'Delivery language',
    Course = 'Course',
    CustomerName = 'Customer name',
    InstructorName = 'Instructor name',
    InstructorType = 'Instructor type',
    LMSLocatorID = 'LMS locator ID',
    Program = 'Program',
    Modality = 'Modality',
    OperationsOwner = 'Operations owner',
    SFDCOportunityID = 'SFDC opportunity ID',
    SIMID = 'SIM ID',
    BillingStatus = 'Billing status',
    TOFStatus = 'TOF status',
    Requestor = 'Requestor',
    Scheduler = 'Scheduler',
    CustomerSuccessManager = 'Customer success manager',
    PartnerInitiative = 'Partner initiative',
}

export const columnDefinitionsAttributesLookup: {
    [key: string]: Array<string>;
} = {
    activity_name: ['activity_name'],
    activity_type: ['activity_type'],
    additional_owners: ['additional_owners'],
    start_date: ['delivery_sessions.start_timestamp'],
    end_date: ['delivery_sessions.end_timestamp'],
    activity_status: ['activity_status'],
    audience: ['activity_audience'],
    partner_initiative: ['partner_initiative'],
    delivery_language: ['delivery_language'],
    course_name: ['course_name'],
    modality: ['activity_modality'],
    operations_owner: ['operations_owner'],
    program: ['program'],
    provider: ['provider'],
    delivery_region: ['delivery_region'],
    scheduler: ['scheduler'],
    customer_support_manager: ['customer_support_manager'],
};

const getCourseIndices = (
    tokens: Array<TablePropertyFiltering.FilteringToken>,
) =>
    tokens.reduce(
        (acc, token, index) => {
            if (token.propertyKey === PropertyFilterKeys.Course) {
                acc.course = index;
            }
            return acc;
        },
        {
            course: -1,
        } as {
            course: number;
        },
    );

const isValidRangeFunction = (range: any) => {
    if (range.type === 'absolute') {
        const [startDateWithoutTime] = range.startDate.split('T');
        const [endDateWithoutTime] = range.endDate.split('T');

        if (!startDateWithoutTime || !endDateWithoutTime) {
            return {
                valid: false,
                errorMessage:
                    'The selected date range is incomplete. Select a start and end date for the date range.',
            } as DateRangePickerProps.InvalidRangeResult;
        }

        if (differenceInDays(range.startDate, range.endDate) >= 366) {
            return {
                valid: false,
                errorMessage:
                    'The selected date range is too large. Select a range up to one year.',
            } as DateRangePickerProps.InvalidRangeResult;
        }

        if (differenceInDays(range.startDate, range.endDate) < 1) {
            return {
                valid: false,
                errorMessage:
                    'The selected date range is too small. Select a range larger than one day.',
            } as DateRangePickerProps.InvalidRangeResult;
        }
    } else if (range.type === 'relative') {
        if (isNaN(range.amount)) {
            return {
                valid: false,
                errorMessage:
                    'The selected date range is incomplete. Specify a duration for the date range.',
            } as DateRangePickerProps.InvalidRangeResult;
        }
    }
    return { valid: true } as DateRangePickerProps.ValidRangeResult;
};

const parseFiltersIntoTokens = ({
    search_text,
    propertyFilters,
}: ActivityFilters): Array<TablePropertyFiltering.FilteringToken> => {
    const freeTextTokens: Array<TablePropertyFiltering.FilteringToken> =
        search_text
            ? search_text?.split(' ').map((textVal) => ({
                  isFreeText: true,
                  label: `"${textVal}"`,
                  negated: false,
                  propertyKey: null as any,
                  propertyLabel: null as any,
                  value: textVal,
              }))
            : [];
    const propertyTokens: Array<TablePropertyFiltering.FilteringToken> =
        Object.entries(propertyFilters).reduce(
            (acc, [propertyFilterKey, propertyFilters]) => {
                acc = [
                    ...acc,
                    ...(propertyFilters
                        ? propertyFilters?.map((propertyFilter) => ({
                              label: propertyFilter,
                              negated: false,
                              propertyKey: propertyFilterKey,
                              propertyLabel: PROPERTY_FILTER_TO_LABEL_MAP.get(
                                  propertyFilterKey as PropertyFilterKeys,
                              ),
                              value: propertyFilter,
                          }))
                        : []),
                ];
                return acc;
            },
            [] as Array<any>,
        );
    return [...freeTextTokens, ...propertyTokens];
};

const PROPERTY_FILTER_TO_LABEL_MAP: ReadonlyMap<
    PropertyFilterKeys,
    PropertyFilterLabels
> = new Map([
    [
        PropertyFilterKeys.AdditionalOwners,
        PropertyFilterLabels.AdditionalOwners,
    ],
    [PropertyFilterKeys.Audience, PropertyFilterLabels.Audience],
    // need to add backend support before adding this filter
    // [PropertyFilterKeys.PartnerInitiative, PropertyFilterLabels.PartnerInitiative],
    [PropertyFilterKeys.BillingStatus, PropertyFilterLabels.BillingStatus],
    [PropertyFilterKeys.Status, PropertyFilterLabels.Status],
    [PropertyFilterKeys.Type, PropertyFilterLabels.Type],
    [
        PropertyFilterKeys.UnassignedInstructorRole,
        PropertyFilterLabels.UnassignedInstructorRole,
    ],
    [
        PropertyFilterKeys.AssignedInstructorRole,
        PropertyFilterLabels.AssignedInstructorRole,
    ],
    [PropertyFilterKeys.Geography, PropertyFilterLabels.Geography],
    [PropertyFilterKeys.Region, PropertyFilterLabels.Region],
    [PropertyFilterKeys.City, PropertyFilterLabels.City],
    [PropertyFilterKeys.Country, PropertyFilterLabels.Country],
    [
        PropertyFilterKeys.DeliveryLanguage,
        PropertyFilterLabels.DeliveryLanguage,
    ],
    [PropertyFilterKeys.Course, PropertyFilterLabels.Course],
    [PropertyFilterKeys.CustomerName, PropertyFilterLabels.CustomerName],
    [PropertyFilterKeys.Program, PropertyFilterLabels.Program],
    [PropertyFilterKeys.InstructorName, PropertyFilterLabels.InstructorName],
    [PropertyFilterKeys.InstructorType, PropertyFilterLabels.InstructorType],
    [PropertyFilterKeys.LMSLocatorID, PropertyFilterLabels.LMSLocatorID],
    [PropertyFilterKeys.Modality, PropertyFilterLabels.Modality],
    [PropertyFilterKeys.OperationsOwner, PropertyFilterLabels.OperationsOwner],
    [PropertyFilterKeys.Providers, PropertyFilterLabels.Providers],
    [
        PropertyFilterKeys.SFDCOportunityID,
        PropertyFilterLabels.SFDCOportunityID,
    ],
    [PropertyFilterKeys.SIMID, PropertyFilterLabels.SIMID],
    [PropertyFilterKeys.TOFStatus, PropertyFilterLabels.TOFStatus],
    [PropertyFilterKeys.Requestor, PropertyFilterLabels.Requestor],
    [PropertyFilterKeys.Scheduler, PropertyFilterLabels.Scheduler],
    [PropertyFilterKeys.CustomerSuccessManager, PropertyFilterLabels.CustomerSuccessManager],
]);

interface ActivityListProps {
    noDefaultDate?: boolean | null;
}

const ActivityList = (props: ActivityListProps) => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const isLoaded = useSelector(selectIsLoaded);
    const activityList = useSelector(selectActivityList);
    const pagesCount = useSelector(selectPagesCount);
    const currentPageIndex = useSelector(selectCurrentPageIndex);
    const totalActivitiesCount = useSelector(selectTotalActivitiesCount);
    const size = useSelector(selectSize);
    const isUserLoading = useSelector(selectIsUserLoading);
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const isLocationListLoaded = useSelector(selectIsLocationListLoaded);
    const isActivityStatusListLoading = useSelector(
        selectIsActivityStatusListLoading,
    );
    const isActivityStatusListLoaded = useSelector(
        selectIsActivityStatusListLoaded,
    );
    const activityStatusList = useSelector(selectAllActiveActivityStatuses);
    const isActivityAudienceListLoaded = useSelector(
        selectIsActivityAudienceListLoaded,
    );
    const isActivityAudienceListLoading = useSelector(
        selectIsActivityAudienceListLoading,
    );
    const isCourseListLoading = useSelector(selectIsCourseListLoading);
    const isCourseListLoaded = useSelector(selectIsCourseListLoaded);
    const deliveryLanguageList = useSelector(selectAllActiveDeliveryLanguages);
    const isDeliveryLanguageListLoaded = useSelector(
        selectIsDeliveryLanguageLoaded,
    );
    const isDeliveryLanguageListLoading = useSelector(
        selectIsDeliveryLanguageLoading,
    );
    const isActivityModalityListLoading = useSelector(
        selectIsActivityModalityListLoading,
    );
    const isActivityModalityListLoaded = useSelector(
        selectIsActivityModalityListLoaded,
    );
    const isPartnerInitiativeListLoading = useSelector(
        selectIsPartnerInitiativeListLoading,
    );
    const isPartnerInitiativeListLoaded = useSelector(
        selectIsPartnerInitiativeListLoaded,
    );
    const isActivityTypeListLoading = useSelector(
        selectIsActivityTypeListLoading,
    );
    const isActivityTypeListLoaded = useSelector(
        selectIsActivityTypeListLoaded,
    );
    const isInstructorRoleTypeListLoading = useSelector(
        selectIsInstructorRoleTypeListLoading,
    );
    const isInstructorRoleTypeListLoaded = useSelector(
        selectIsInstructorRoleTypeListLoaded,
    );
    const isInstructorTypeListLoading = useSelector(
        selectIsInstructorTypeListLoading,
    );
    const isInstructorTypeListLoaded = useSelector(
        selectIsInstructorTypeListLoaded,
    );
    const isAtpListLoading = useSelector(selectIsAtpListLoading);
    const isAtpListLoaded = useSelector(selectIsAtpListLoaded);
    const atpList = useSelector(selectAllActiveAtpCompanies);
    const activityTypeList = useSelector(selectAllActiveActivityTypes);
    const instructorRoleTypeList = useSelector(
        selectAllActiveInstructorRoleTypes,
    );
    const instructorTypeList = useSelector(selectAllActiveInstructorTypes);
    const activityModalityList = useSelector(selectAllActiveActivityModalities);
    const partnerInitiativeList = useSelector(selectAllActivePartnerInitiatives);
    const courseList = useSelector(selectAllActiveCourses);
    const [lastSelectedCourse, setLastSelectedCourse] = useState('');
    const visibleColumns = useSelector(selectVisibleColumns);
    const history = useHistory();
    const userId = useSelector(selectUserId);
    const locationList = useSelector(selectAllActiveLocations);
    const regionList = useSelector(selectAllActiveRegions);
    const activityAudienceList = useSelector(selectAllActiveActivityAudiences);
    const userProfile = useSelector(selectUser);
    const isRegionListLoading = useSelector(selectIsRegionListLoading);
    const isRegionListLoaded = useSelector(selectIsRegionListLoaded);
    const rangeFilter = useSelector(selectDateRangeFilter);
    const searchText = useSelector(selectSearchText);
    const savedFilter = useSelector(selectSavedFilter);
    const hasFilters = !!(searchText || savedFilter);
    const savedFilters = useSelector(selectActivityListSavedFilters);
    const savedFilterNames = savedFilters.map((item) => item.name);
    const savedFilterLookup = useSelector(selectActivityListSavedFilterLookup);
    const { addNotification } = useNotifications();
    const dispatch = useDispatch();
    const [isSaveFilterModalActive, setIsSaveFilterModalActive] =
        useState(false);

    const [isManageFilterModalActive, setIsManageFilterModalActive] =
        useState(false);
    const [savedFilterName, setSavedFilterName] = useState('');
    const [filterTokens, setFilterTokens] = useState(
        [] as Array<TablePropertyFiltering.FilteringToken>,
    );

    const [validateOnChange, setValidateOnChange] = useState(false);
    const [currentSortingColumn, setCurrentSortingColumn] = useState({
        sortingColumn: { sortingField: 'delivery_sessions.end_timestamp' },
        isDescending: false,
    });

    const CONTENT_SELECTOR_OPTIONS = {
        label: '',
        options: [
            {
                id: ActivityTableColumnId.Name,
                label: 'Activity Name',
                editable: false,
                visible: true,
            },
            {
                id: ActivityTableColumnId.LMSId,
                label: 'LMS locator ID',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.StartDate,
                label: 'Start Date',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.EndDate,
                label: 'End Date',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.Status,
                label: 'Status',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.ClassSize,
                label: 'Class size',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Registered,
                label: 'Registered',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Attended,
                label: 'Attended',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.ClassRequestSIM,
                label: 'Class request SIM',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.DeliveryLanguage,
                label: 'Delivery language',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Type,
                label: 'Type',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.Audience,
                label: 'Audience',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.PartnerInitiative,
                label: 'Partner initiative',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.CourseName,
                label: 'Course name',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.Instructors,
                label: 'Instructors',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.Modality,
                label: 'Modality',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.Customers,
                label: 'Customers',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.OperationsOwner,
                label: 'Operations owner',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.AdditionalOwners,
                label: 'Additional owners',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Program,
                label: 'Program',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Providers,
                label: 'Provider',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Region,
                label: 'Region',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Requestor,
                label: 'Requestor',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Location,
                label: 'Location',
                editable: true,
                visible: true,
            },
            {
                id: ActivityTableColumnId.BillingStatus,
                label: 'Billing status',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.TOFStatus,
                label: 'Tof status',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.Scheduler,
                label: 'Scheduler',
                editable: true,
                visible: false,
            },
            {
                id: ActivityTableColumnId.CustomerSuccessManager,
                label: 'Customer success manager',
                editable: true,
                visible: false,
            },
        ],
    };

    const columnDefinitions: Array<any> = [
        {
            id: ActivityTableColumnId.Name,
            header: 'Activity name',
            sortingField: 'activity_name',
            width: 400,
            cell: (activity: ActivityData) => (
                <Link
                    to={`/activities/${activity.pk}`}
                    className="activity-link"
                >
                    {activity.activity_name}
                </Link>
            ),
        },
        {
            id: ActivityTableColumnId.LMSId,
            header: 'LMS locator ID',
            minWidth: 20,
            width: 150,
            cell: (activity: ActivityData) =>
                activity?.lms_id ? (
                    <HTTPLink
                        href={getKikuURL(activity.lms_id)}
                        target="_blank"
                        data-testid="lms-class-link"
                    >
                        {formatString(activity.lms_id)}
                    </HTTPLink>
                ) : (
                    EMPTY_STRING
                ),
        },
        {
            id: ActivityTableColumnId.StartDate,
            header: 'Start date',
            minWidth: 20,
            width: 180,
            sortingField: 'delivery_sessions.start_timestamp',
            cell: (activity: ActivityData) =>
                activity?.delivery_sessions[0]?.start_timestamp
                    ? formatGrimsbyDate(
                          activity.delivery_sessions[0].start_timestamp,
                          activity.delivery_timezone,
                      )
                    : '',
        },
        {
            id: ActivityTableColumnId.EndDate,
            header: 'End date',
            minWidth: 20,
            width: 180,
            sortingField: 'delivery_sessions.end_timestamp',
            cell: (activity: ActivityData) =>
                activity?.delivery_sessions[
                    activity.delivery_sessions.length - 1
                ]?.end_timestamp
                    ? formatGrimsbyDate(
                          activity.delivery_sessions[
                              activity.delivery_sessions.length - 1
                          ].end_timestamp,
                          activity.delivery_timezone,
                      )
                    : '',
        },
        {
            id: ActivityTableColumnId.Status,
            header: 'Status',
            minWidth: 20,
            width: 120,
            sortingField: 'activity_status',
            cell: (activity: ActivityData) =>
                formatString(activity.activity_status),
        },
        {
            id: ActivityTableColumnId.ClassSize,
            header: 'Class size',
            minWidth: 20,
            width: 120,
            cell: (activity: ActivityData) => formatNumber(activity.class_size),
        },
        {
            id: ActivityTableColumnId.Registered,
            header: 'Registered',
            minWidth: 20,
            width: 120,
            cell: (activity: ActivityData) => formatNumber(activity.registered),
        },
        {
            id: ActivityTableColumnId.Attended,
            header: 'Attended',
            minWidth: 20,
            width: 120,
            cell: (activity: ActivityData) => formatNumber(activity.attended),
        },
        {
            id: ActivityTableColumnId.Audience,
            header: 'Audience',
            minWidth: 20,
            width: 250,
            cell: (activity: ActivityData) =>
                formatString(activity.activity_audience),
        },
        {
            id: ActivityTableColumnId.ClassRequestSIM,
            header: 'Class request SIM',
            minWidth: 20,
            width: 120,
            cell: (activity: ActivityData) =>
                formatString(activity.class_request_sim),
        },
        {
            id: ActivityTableColumnId.DeliveryLanguage,
            header: 'Delivery language',
            minWidth: 20,
            width: 180,
            sortingFields: 'delivery_language',
            cell: (activity: ActivityData) =>
                formatString(activity.delivery_language),
        },
        {
            id: ActivityTableColumnId.Type,
            header: 'Type',
            minWidth: 20,
            width: 120,
            sortingField: 'activity_type',
            cell: (activity: ActivityData) =>
                formatString(activity.activity_type),
        },
        {
            id: ActivityTableColumnId.CourseName,
            header: 'Course name',
            minWidth: 20,
            width: 250,
            sortingField: 'course_name',
            cell: (activity: ActivityData) =>
                formatString(activity.course_name),
        },
        {
            id: ActivityTableColumnId.Instructors,
            header: 'Instructors',
            minWidth: 20,
            width: 320,
            cell: (activity: ActivityData) =>
                activity.instructors?.map((i) => {
                    if (i.type === 'External') {
                        return (
                            <p key={`${i.pk}-name`}>
                                {i.name} <Badge color="blue">FL</Badge>
                            </p>
                        );
                    } else {
                        return <p key={`${i.pk}-name`}>{i.name}</p>;
                    }
                }),
        },
        {
            id: ActivityTableColumnId.Modality,
            header: 'Modality',
            minWidth: 20,
            width: 120,
            sortingField: 'activity_modality',
            cell: (activity: ActivityData) =>
                formatString(activity.activity_modality),
        },
        {
            id: ActivityTableColumnId.PartnerInitiative,
            header: 'Partner initiative',
            minWidth: 20,
            width: 120,
            sortingField: 'partner_initiative',
            cell: (activity: ActivityData) =>
                formatString(activity.partner_initiative),
        },
        {
            id: ActivityTableColumnId.Customers,
            header: 'Customers',
            minWidth: 20,
            width: 320,
            cell: (activity: ActivityData) =>
                formatStringArray(
                    (activity.customers as Array<Customer>)?.map(
                        (c) => c.customer_name,
                    ),
                ),
        },
        {
            id: ActivityTableColumnId.OperationsOwner,
            header: 'Operations owner',
            minWidth: 20,
            width: 200,
            sortingField: 'operations_owner',
            cell: (activity: ActivityData) =>
                formatString(activity.operations_owner),
        },
        {
            id: ActivityTableColumnId.AdditionalOwners,
            header: 'Additional owners',
            minWidth: 20,
            width: 200,
            cell: (activity: ActivityData) =>
                formatStringArray(
                    (activity.additional_owners ?? []).map(
                        (owner) => owner.additional_owner_name,
                    ),
                ),
        },
        {
            id: ActivityTableColumnId.Program,
            header: 'Program',
            minWidth: 20,
            width: 200,
            sortingField: 'program',
            cell: (activity: ActivityData) => formatString(activity.program),
        },
        {
            id: ActivityTableColumnId.Providers,
            header: 'Provider',
            minWidth: 20,
            width: 140,
            sortingField: 'provider',
            cell: (activity: ActivityData) => formatString(activity.provider),
        },
        {
            id: ActivityTableColumnId.Region,
            header: 'Region',
            minWidth: 20,
            width: 140,
            sortingField: 'delivery_region',
            cell: (activity: ActivityData) =>
                formatString(activity.delivery_region),
        },
        {
            id: ActivityTableColumnId.Requestor,
            header: 'Requestor',
            minWidth: 20,
            width: 200,
            cell: (activity: ActivityData) => formatString(activity.requestor),
        },
        {
            id: ActivityTableColumnId.Location,
            header: 'Location',
            minWidth: 20,
            width: 320,
            cell: (activity: ActivityData) =>
                formatStringArray([
                    activity.delivery_city,
                    activity.delivery_state,
                    activity.delivery_country,
                ]),
        },
        {
            id: ActivityTableColumnId.BillingStatus,
            header: 'Billing status',
            minWidth: 20,
            width: 180,
            cell: (activity: ActivityData) =>
                formatStringArray(
                    (activity.billing_invoices as Array<Invoice>)?.map(
                        (i) => i.billing_status,
                    ),
                ),
        },
        {
            id: ActivityTableColumnId.TOFStatus,
            header: 'TOF status',
            minWidth: 20,
            width: 180,
            cell: (activity: ActivityData) =>
                formatStringArray(
                    (
                        activity.customers as Array<CommercialPrivateCustomer>
                    )?.map((c) => c.tof_status),
                ),
        },
        {
            id: ActivityTableColumnId.Scheduler,
            header: 'Scheduler',
            minWidth: 20,
            width: 200,
            sortingField: 'scheduler',
            cell: (activity: ActivityData) => formatString(activity.scheduler),
        },
        {
            id: ActivityTableColumnId.CustomerSuccessManager,
            header: 'Customer success manager',
            minWidth: 20,
            width: 200,
            sortingField: 'customer_support_manager',
            cell: (activity: ActivityData) => formatString(activity.customer_support_manager),
        },
    ];

    const getSavedFilterFormValues = (): SavedFilterForm => {
        return {
            filters: savedFilters.map((filter) => {
                return {
                    id: `${Date.now()}_${filter.name}`,
                    ...filter,
                };
            }),
        };
    };

    // lifecycle method to initialize and reset business data slices
    useEffect(() => {
        // initialize query params for business data slices
        // this code block should only run once
        [
            initializeLocationsListQueryParams,
            initializeAtpCompaniesListQueryParams,
        ].forEach((initializeQueryParams) =>
            dispatch(
                initializeQueryParams({
                    active: null,
                    size: 0,
                }),
            ),
        );

        return () => {
            // reset business data slices
            // this code block should only run once
            [resetLocationsSlice, resetAtpCompaniesSlice].forEach(
                (resetFunction) => dispatch(resetFunction()),
            );
        };
    }, [dispatch]);

    // lifecycle method to fetch (and refetch) business data
    useEffect(() => {
        (
            [
                [
                    !isLocationListLoaded && !isLocationListLoading,
                    getLocationsList,
                ],
                [
                    !isActivityAudienceListLoaded &&
                        !isActivityAudienceListLoading,
                    getActivityAudiencesList,
                ],
                [!isCourseListLoaded && !isCourseListLoading, getCoursesList],
                [
                    !isDeliveryLanguageListLoading &&
                        !isDeliveryLanguageListLoaded,
                    getDeliveryLanguagesList,
                ],
                [
                    !isActivityModalityListLoading &&
                        !isActivityModalityListLoaded,
                    getActivityModalitiesList,
                ],
                [!isRegionListLoaded && !isRegionListLoading, getRegionsList],
                [
                    !isActivityStatusListLoading && !isActivityStatusListLoaded,
                    getActivityStatusesList,
                ],
                [
                    !isActivityTypeListLoaded && !isActivityTypeListLoading,
                    getActivityTypesList,
                ],
                [
                    !isPartnerInitiativeListLoaded && !isPartnerInitiativeListLoading,
                    getPartnerInitiativesList,
                ],
                [
                    !isInstructorRoleTypeListLoaded &&
                        !isInstructorRoleTypeListLoading,
                    getInstructorRoleTypesList,
                ],
                [
                    !isInstructorTypeListLoaded && !isInstructorTypeListLoading,
                    getInstructorTypesList,
                ],
                [!isAtpListLoaded && !isAtpListLoading, getAtpCompaniesList],
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    // lifecycle method to initialize and reset activity list slice
    useEffect(() => {
        if (dispatch) {
            if (!props.noDefaultDate) {
                dispatch(
                    setDateRangeFilter({
                        type: 'relative',
                        amount: 12,
                        unit: 'week',
                        key: 'next-twelve-weeks',
                    }),
                );
            }
            let storedFilterString = localStorage.getItem(
                ACTIVITY_FILTER_STORAGE_KEY,
            );
            if (storedFilterString) {
                let { search_text, propertyFilters, dateRange } = JSON.parse(
                    storedFilterString,
                ) as ActivityFilters;
                const parsedTokens = parseFiltersIntoTokens({
                    search_text,
                    propertyFilters,
                    dateRange,
                });
                if (!dateRange?.type) {
                    dateRange = {
                        type: 'relative',
                        amount: 12,
                        unit: 'week',
                        key: 'next-twelve-weeks',
                    };
                }
                dispatch(setDateRangeFilter(dateRange));
                setFilterTokens(parsedTokens);
                dispatch(setSearchText(search_text));
                dispatch(setSavedFilter(propertyFilters));
            }
            dispatch(initializeUserPreference());
            dispatch(getActivityList());

            return () => {
                dispatch(setFrom(0));
                dispatch(setIsLoaded(false));
                dispatch(setTotalActivitiesCount(0));
            };
        }
    }, [dispatch, props.noDefaultDate]);

    const setSavedFilterAndGetActivityList = debounce<ActivityFilters>(
        ({ search_text, propertyFilters }) => {
            dispatch(setSearchText(search_text));
            dispatch(setSavedFilter(propertyFilters));
            dispatch(getActivityList());
        },
    );

    const handlePaginationChange = (event: any) => {
        const { currentPageIndex } = event.detail;
        let isSuccessful = true;

        // if the BE update is successful or no BE update, update the global state
        if (isSuccessful) {
            // the following logic handles both pagination operations and
            // page size changing due to the current Polaris design
            let updatedPageIndex = currentPageIndex;
            let from = (updatedPageIndex - 1) * size;

            // Update global state
            dispatch(setCurrentPageIndex(updatedPageIndex));
            dispatch(setFrom(from));
            dispatch(getActivityList());
        }
    };

    const generateContentSelectorOptions = (
        contentSelection: Array<string> | undefined,
    ): Array<any> => {
        if (!contentSelection) {
            return [CONTENT_SELECTOR_OPTIONS];
        }

        const contentSelectionSet = new Set(contentSelection);
        return [
            {
                label: CONTENT_SELECTOR_OPTIONS.label,
                options: CONTENT_SELECTOR_OPTIONS.options
                    .filter((opt) => opt.id)
                    .map((opt) => ({
                        id: opt.id,
                        label: opt.label,
                        editable: opt.editable,
                        visible: contentSelectionSet.has(opt.id!),
                    })),
            },
        ];
    };

    const handleContentSelectionChange = async (event: any) => {
        const contentSelection = event.detail.visibleContent;

        if (contentSelection) {
            // update the user's preferences of visible columns
            const dispatchPromise = updateUserPreference({
                visible_columns: contentSelection,
                page_size: event.detail.pageSize,
            });

            // add notification
            await handleActivityListNotification(
                dispatchPromise,
                addNotification,
            );

            // update the global state no matter whether BE update is successful
            // since the current Polaris will auto filter out the invisible columns
            // by CSS and we don't have full control on it by only JS.
            dispatch(setVisibleColumns(contentSelection));
            dispatch(setSize(event.detail.pageSize));
            dispatch(getActivityList());
        }
    };

    const validateFilterName = (
        { filters }: SavedFilterForm,
        filterItem: SavedFilter,
        meta: FieldMetaProps<any>,
    ) => {
        if (!filterItem.name) {
            return 'Filter name is required';
        }

        if (meta.initialValue === meta.value) {
            return '';
        }

        const restFilters = filters.filter((item) => item.id !== filterItem.id);

        const isDuplicate = restFilters.some((item: SavedFilter) => {
            return item.name === filterItem.name && item.id !== filterItem.id;
        });

        if (isDuplicate) {
            return 'A filter with this name already exists. Please select another name.';
        } else {
            return '';
        }
    };

    const filteringOptions: Array<any> = [
        {
            groupValuesLabel: PropertyFilterLabels.AdditionalOwners,
            propertyKey: PropertyFilterKeys.AdditionalOwners,
            propertyLabel: PropertyFilterLabels.AdditionalOwners,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.Audience,
            propertyKey: PropertyFilterKeys.Audience,
            propertyLabel: PropertyFilterLabels.Audience,
            values: activityAudienceList.map(
                (activityAudience: ActivityAudienceItemData) =>
                    activityAudience.activity_audience,
            ),
        },
        /* Need additional backend work to support this
        {
            groupValuesLabel: PropertyFilterLabels.PartnerInitiative,
            propertyKey: PropertyFilterKeys.PartnerInitiative,
            propertyLabel: PropertyFilterLabels.PartnerInitiative,
            values: partnerInitiativeList.map(
                (partnerInitiative: PartnerInitiativeItemData) =>
                    partnerInitiative.partner_initiative,
            ),
        },
        */
        {
            groupValuesLabel: PropertyFilterLabels.BillingStatus,
            propertyKey: PropertyFilterKeys.BillingStatus,
            propertyLabel: PropertyFilterLabels.BillingStatus,
            values: [
                'Accrued',
                'Billed',
                'Billing failed',
                'Billing in Progress',
                'Draft',
                'To be billed',
                'To be billed - manually',
                'To be accrued',
                'Submitted',
            ],
        },
        {
            groupValuesLabel: PropertyFilterLabels.City,
            propertyKey: PropertyFilterKeys.City,
            propertyLabel: PropertyFilterLabels.City,
            values: locationList.map((locationData) => locationData.city),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Country,
            propertyKey: PropertyFilterKeys.Country,
            propertyLabel: PropertyFilterLabels.Country,
            values: locationList
                .sort((a, b) => {
                    if (a.country.toUpperCase() > b.country.toUpperCase()) {
                        return 1;
                    }

                    return -1;
                })
                .map((locationData) => locationData.country),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Course,
            propertyKey: PropertyFilterKeys.Course,
            propertyLabel: PropertyFilterLabels.Course,
            values: courseList.map((course) => course.course),
        },
        {
            groupValuesLabel: PropertyFilterLabels.CustomerName,
            propertyKey: PropertyFilterKeys.CustomerName,
            propertyLabel: PropertyFilterLabels.CustomerName,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.DeliveryLanguage,
            propertyKey: PropertyFilterKeys.DeliveryLanguage,
            propertyLabel: PropertyFilterLabels.DeliveryLanguage,
            values: deliveryLanguageList.map((lang) => lang.delivery_language),
        },
        {
            groupValuesLabel: PropertyFilterLabels.InstructorName,
            propertyKey: PropertyFilterKeys.InstructorName,
            propertyLabel: PropertyFilterLabels.InstructorName,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.InstructorType,
            propertyKey: PropertyFilterKeys.InstructorType,
            propertyLabel: PropertyFilterLabels.InstructorType,
            values: instructorTypeList.map(
                (instructorType) => instructorType.instructor_type,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.LMSLocatorID,
            propertyKey: PropertyFilterKeys.LMSLocatorID,
            propertyLabel: PropertyFilterLabels.LMSLocatorID,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.Modality,
            propertyKey: PropertyFilterKeys.Modality,
            propertyLabel: PropertyFilterLabels.Modality,
            values: activityModalityList.map(
                (modality) => modality.activity_modality,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.OperationsOwner,
            propertyKey: PropertyFilterKeys.OperationsOwner,
            propertyLabel: PropertyFilterLabels.OperationsOwner,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.Program,
            propertyKey: PropertyFilterKeys.Program,
            propertyLabel: PropertyFilterLabels.Program,
            values: userProfile?.profile.programs || [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.Providers,
            propertyKey: PropertyFilterKeys.Providers,
            propertyLabel: PropertyFilterLabels.Providers,
            values: atpList.map((atpData) => atpData.atp_company),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Region,
            propertyKey: PropertyFilterKeys.Region,
            propertyLabel: PropertyFilterLabels.Region,
            values: regionList.map((regionData) => regionData.region),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Requestor,
            propertyKey: PropertyFilterKeys.Requestor,
            propertyLabel: PropertyFilterLabels.Requestor,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.SFDCOportunityID,
            propertyKey: PropertyFilterKeys.SFDCOportunityID,
            propertyLabel: PropertyFilterLabels.SFDCOportunityID,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.SIMID,
            propertyKey: PropertyFilterKeys.SIMID,
            propertyLabel: PropertyFilterLabels.SIMID,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.Status,
            propertyKey: PropertyFilterKeys.Status,
            propertyLabel: PropertyFilterLabels.Status,
            values: activityStatusList.map(
                (activityStatus) => activityStatus.activity_status,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.TOFStatus,
            propertyKey: PropertyFilterKeys.TOFStatus,
            propertyLabel: PropertyFilterLabels.TOFStatus,
            values: [
                'Not Started',
                'With Customer',
                'Signature Received',
                'Expired',
            ],
        },
        {
            groupValuesLabel: PropertyFilterLabels.Type,
            propertyKey: PropertyFilterKeys.Type,
            propertyLabel: PropertyFilterLabels.Type,
            values: activityTypeList.map(
                (activityType) => activityType.activity_type,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.UnassignedInstructorRole,
            propertyKey: PropertyFilterKeys.UnassignedInstructorRole,
            propertyLabel: PropertyFilterLabels.UnassignedInstructorRole,
            values: instructorRoleTypeList.map(
                (instructorRoleType) => instructorRoleType.instructor_role_type,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.AssignedInstructorRole,
            propertyKey: PropertyFilterKeys.AssignedInstructorRole,
            propertyLabel: PropertyFilterLabels.AssignedInstructorRole,
            values: instructorRoleTypeList.map(
                (instructorRoleType) => instructorRoleType.instructor_role_type,
            ),
        },
        {
            groupValuesLabel: PropertyFilterLabels.Scheduler,
            propertyKey: PropertyFilterKeys.Scheduler,
            propertyLabel: PropertyFilterLabels.Scheduler,
            values: [],
        },
        {
            groupValuesLabel: PropertyFilterLabels.CustomerSuccessManager,
            propertyKey: PropertyFilterKeys.CustomerSuccessManager,
            propertyLabel: PropertyFilterLabels.CustomerSuccessManager,
            values: [],
        },
    ];

    const updateUserPreference = (data: {
        [key: string]: number | Array<string>;
    }): Promise<boolean> => {
        if (userId && data) {
            return dispatch<any>(
                updatePreferencesAndSavedFilters(userId, {
                    preferences: {
                        activity_list: data,
                    },
                }),
            );
        }
        return Promise.resolve(false);
    };

    const refreshPagination = () => {
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));
    };

    const handleSortingChange = async (event: any) => {
        const { sortingColumn, isDescending } = event.detail;
        const order = isDescending ? 'desc' : 'asc';

        setCurrentSortingColumn(event.detail);

        const sortFields: Array<ScheduleManagementAPIQueryParams.SortFieldsItem> =
            [
                {
                    [sortingColumn.sortingField]: {
                        order: order,
                    },
                },
            ];

        dispatch(setSortFields(sortFields));
        dispatch(getActivityList());
    };

    const getSavedFilterItems = (): Array<ButtonDropdown.Item> => {
        const manageItem = {
            text: MANAGE_FILTER_CONFIG.text,
            id: MANAGE_FILTER_CONFIG.id,
            disabled: false,
        } as ButtonDropdown.Item;

        return [
            {
                id: 'filter_items',
                text: '',
                items: savedFilterNames.map((name) => ({
                    text: name,
                    id: name,
                    disable: false,
                })),
            },
            {
                id: 'manage_items',
                text: '',
                items: [manageItem],
            },
        ];
    };

    const updateUserSavedFilters = (updatedFilters?: SavedFilterForm) => {
        if (userId) {
            if (!updatedFilters) {
                return dispatch<any>(
                    updatePreferencesAndSavedFilters(userId, {
                        saved_filters_smt: [
                            ...savedFilters,
                            {
                                name: savedFilterName.trim(),
                                filter: {
                                    search_text: searchText,
                                    propertyFilters:
                                        savedFilter as ScheduleManagementAPIQueryParams.PropertyFilterParams,
                                    dateRange: rangeFilter,
                                },
                            },
                        ],
                    }),
                );
            } else {
                const updatedFilterToSave: Array<{
                    name: string;
                    filter: ActivityFilters;
                }> = updatedFilters.filters.map((filterItem) => {
                    return {
                        name: filterItem.name,
                        filter: {
                            propertyFilters: filterItem.filter.propertyFilters,
                            search_text: filterItem.filter.search_text,
                            dateRange: rangeFilter,
                        },
                    };
                });

                return dispatch<any>(
                    updatePreferencesAndSavedFilters(userId, {
                        saved_filters_smt: updatedFilterToSave,
                    }),
                );
            }
        }
        return Promise.resolve(false);
    };

    const handleDateSelected = (event: any) => {
        let selectedDateRange = event.detail?.value;
        dispatch(setDateRangeFilter(selectedDateRange));
        // get the current filters from local storage
        let currentFilters = localStorage.getItem(ACTIVITY_FILTER_STORAGE_KEY);
        if (currentFilters) {
            // filters exist, now override previous daterange with new value
            let currentFiltersMap = JSON.parse(currentFilters);
            currentFiltersMap[DATE_RANGE_FILTER_KEY] = selectedDateRange;
            handleLocalStorageSetItem(
                ACTIVITY_FILTER_STORAGE_KEY,
                JSON.stringify(currentFiltersMap),
            );
        } else {
            // filters don't exist, set selected date range
            // and defaults for other keys
            handleLocalStorageSetItem(
                ACTIVITY_FILTER_STORAGE_KEY,
                JSON.stringify({
                    dateRange: selectedDateRange,
                    search_text: null,
                    propertyFilters: {},
                }),
            );
        }
        dispatch(getActivityList());
    };

    const handlePropertyFilteringChange = (
        tokens: Array<TablePropertyFiltering.FilteringToken>,
    ) => {
        const indices = getCourseIndices(tokens);

        if (
            indices.course !== -1 &&
            tokens[indices.course].value !== lastSelectedCourse
        ) {
            tokens = [...tokens];
            setLastSelectedCourse(tokens[indices.course].value);
        }

        if (indices.course === -1 && !!lastSelectedCourse) {
            setLastSelectedCourse('');
        }

        setFilterTokens(tokens);
        // on change of property keys, ensure date range filters are retained (if exist)
        let currentFilters = localStorage.getItem(ACTIVITY_FILTER_STORAGE_KEY);
        let dateRangeFilters = {};
        if (currentFilters) {
            let activityFilters = JSON.parse(currentFilters);
            if (DATE_RANGE_FILTER_KEY in activityFilters) {
                dateRangeFilters = activityFilters[DATE_RANGE_FILTER_KEY];
            }
        }

        if (tokens?.length) {
            const activityFilters = tokens.reduce(
                (acc, opt) => {
                    if (opt.isFreeText && opt.propertyKey === null) {
                        acc.search_text = acc.search_text
                            ? `${acc.search_text} ${opt.value}`
                            : `${opt.value}`;
                    } else {
                        const key = opt.propertyKey as PropertyFilterKeys;
                        if (!acc.propertyFilters[key]) {
                            acc.propertyFilters[key] = [];
                        }

                        (acc.propertyFilters[key] as Array<string>).push(
                            opt.value,
                        );
                    }
                    return acc;
                },
                {
                    search_text: null,
                    propertyFilters: {},
                    dateRange: dateRangeFilters,
                } as ActivityFilters,
            );

            refreshPagination();
            setSavedFilterAndGetActivityList(activityFilters);
            handleLocalStorageSetItem(
                ACTIVITY_FILTER_STORAGE_KEY,
                JSON.stringify(activityFilters),
            );
        } else {
            localStorage.removeItem(ACTIVITY_FILTER_STORAGE_KEY);

            refreshPagination();
            dispatch(setSearchText(null));
            dispatch(setSavedFilter(null));
            dispatch(getActivityList());
        }
    };

    const handleSavedFilterChange = (id: string) => {
        if (!savedFilterLookup) {
            return;
        }

        if (id === MANAGE_FILTER_CONFIG.id) {
            // Polaris guidelines: form with multiple fields
            // set validation on change to false when the form loads for the first time.
            setValidateOnChange(false);

            // show manage filters dialog
            setIsManageFilterModalActive(true);
            return;
        }

        handlePropertyFilteringChange(
            parseFiltersIntoTokens(savedFilterLookup[id]),
        );

        dispatch(setDateRangeFilter(savedFilterLookup[id].dateRange));
    };

    if (error) {
        return (
            <Alert header="Error" type="error">
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (isLoaded) {
        const tableHeaderComponent = (
            <TableHeader
                title={ACTIVITY_TABLE_TITLE}
                actions={
                    <>
                        {hasFilters && (
                            <Button
                                variant="normal"
                                disabled={isLoading || isUserLoading}
                                data-testid="ActivityListActionsSaveFilters"
                                onClick={() => setIsSaveFilterModalActive(true)}
                            >
                                Save Filters
                            </Button>
                        )}
                        {!!savedFilterNames?.length && savedFilterLookup && (
                            <ButtonDropdown
                                data-testid="ActivityListActionsLoadFilters"
                                items={getSavedFilterItems()}
                                onItemClick={({ detail: { id } }) => {
                                    handleSavedFilterChange(id);
                                }}
                            >
                                Load filters
                            </ButtonDropdown>
                        )}
                        <Can
                            perform={Actions.ACTIVITY_MODIFY}
                            yes={() => (
                                <Button
                                    variant="normal"
                                    data-testid="ActivityImportButton"
                                    onClick={() =>
                                        history.push({
                                            pathname:
                                                ACTIVITY_PATH.ACTIVITY_IMPORT,
                                        })
                                    }
                                    label={IMPORT_ACTIVITIES_BUTTON}
                                >
                                    {IMPORT_ACTIVITIES_BUTTON}
                                </Button>
                            )}
                        />
                        <Can
                            perform={Actions.ACTIVITY_MODIFY}
                            yes={() => (
                                <Button
                                    icon="add-plus"
                                    variant="primary"
                                    data-testid="ActivityListActionsCreate"
                                    onClick={() =>
                                        history.push({
                                            pathname: ACTIVITY_PATH.CREATE,
                                        })
                                    }
                                    label={CREATE_ACTIVITY_BUTTON}
                                >
                                    {CREATE_ACTIVITY_BUTTON}
                                </Button>
                            )}
                        />
                    </>
                }
                count={totalActivitiesCount ? totalActivitiesCount : ''}
            />
        );

        const emptyTableComponent = (
            <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                    <b>No Activities</b>
                </div>
                <p className="awsui-util-mb-s">No activities to display.</p>
            </div>
        );

        const saveFilters = async (updatedFilters?: SavedFilterForm) => {
            const filterNameExists =
                !!savedFilterLookup?.[savedFilterName.trim()];

            // when saving a new filter
            if (!updatedFilters && filterNameExists) {
                return;
            }
            const isSuccessful: boolean = await updateUserSavedFilters(
                updatedFilters,
            );
            addNotification({
                id: `update-user-instructor-list-saved-filters-${Date.now}`,
                ...(isSuccessful
                    ? {
                          type: 'success',
                          content:
                              'You have successfully saved the activity filters.',
                      }
                    : {
                          type: 'error',
                          content:
                              'An error occurred while saving the activity filters.',
                      }),
            });

            if (isSuccessful) {
                setSavedFilterName('');
                if (!updatedFilters) {
                    setIsSaveFilterModalActive(false);
                } else {
                    setIsManageFilterModalActive(false);
                }
            }
        };

        return (
            <>
                <Table
                    data-testid="ActivityListTable"
                    loading={isLoading || isUserLoading}
                    loadingText={LOADING_TEXT}
                    columnDefinitions={columnDefinitions}
                    items={activityList}
                    header={tableHeaderComponent}
                    empty={emptyTableComponent}
                    wrapLines={false}
                    visibleColumns={visibleColumns}
                    resizableColumns
                    sortingColumn={currentSortingColumn?.sortingColumn}
                    sortingDescending={currentSortingColumn?.isDescending}
                    onSortingChange={handleSortingChange}
                    preferences={
                        <CollectionPreferences
                            title="Preferences"
                            confirmLabel="Confirm"
                            cancelLabel="Cancel"
                            preferences={{
                                visibleContent: visibleColumns,
                                pageSize: size,
                            }}
                            onConfirm={handleContentSelectionChange}
                            pageSizePreference={{
                                title: 'Page size',
                                options: PAGE_SELECTOR_OPTIONS,
                            }}
                            visibleContentPreference={{
                                title: 'Select visible columns',
                                options:
                                    generateContentSelectorOptions(
                                        visibleColumns,
                                    ),
                            }}
                        />
                    }
                    filter={
                        <div className="activity-list-filter-container">
                            <span className="activity-list-property-filters">
                                <TablePropertyFiltering
                                    filteringLabel="Activity table filter text input"
                                    filteringFunction={null}
                                    filteringOptions={filteringOptions}
                                    placeholder="Search by activity name or LMS Id or property"
                                    hideOperations={true}
                                    groupPropertiesText="Properties"
                                    groupValuesText="Values"
                                    clearFiltersText="Clear filters"
                                    allowFreeTextFiltering={true}
                                    tokens={filterTokens}
                                    onPropertyFilteringChange={({
                                        detail: { tokens },
                                    }) => handlePropertyFilteringChange(tokens)}
                                    filteringEmpty={
                                        <span className="awsui-util-status-inactive">
                                            Enter to search
                                        </span>
                                    }
                                    id="activity-list-property-filter"
                                />
                            </span>
                            <span className="activity-list-daterange-picker">
                                <DateRangePicker
                                    data-testid="ActivityListDateRangePicker"
                                    i18nStrings={dateRangeFilterI18nStrings}
                                    value={rangeFilter}
                                    onChange={handleDateSelected}
                                    placeholder="Filter by a date and time range"
                                    className="date-range-filter"
                                    isValidRange={isValidRangeFunction}
                                    timeInputFormat={`hh:mm`}
                                    relativeOptions={[
                                        {
                                            type: 'relative',
                                            amount: -4,
                                            unit: 'week',
                                            key: 'last-four-weeks',
                                        },
                                        {
                                            type: 'relative',
                                            amount: -1,
                                            unit: 'week',
                                            key: 'last-one-weeks',
                                        },
                                        {
                                            type: 'relative',
                                            amount: 1,
                                            unit: 'week',
                                            key: 'next-one-week',
                                        },
                                        {
                                            type: 'relative',
                                            amount: 4,
                                            unit: 'week',
                                            key: 'next-four-weeks',
                                        },
                                        {
                                            type: 'relative',
                                            amount: 12,
                                            unit: 'week',
                                            key: 'next-twelve-weeks',
                                        },
                                    ]}
                                />
                            </span>
                            <span></span>
                        </div>
                    }
                    pagination={
                        <Pagination
                            data-testid="ActivityListPagination"
                            pagesCount={pagesCount}
                            currentPageIndex={currentPageIndex}
                            onChange={handlePaginationChange}
                        />
                    }
                />
                <Modal
                    visible={isSaveFilterModalActive}
                    header="Save filter settings"
                    data-testid="ActivityListSaveFilterModal"
                    footer={
                        <span className="awsui-util-f-r">
                            <Button
                                data-testid="ActivityListSaveFilterModalCancel"
                                variant="link"
                                onClick={() =>
                                    setIsSaveFilterModalActive(false)
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="ActivityListSaveFilterModalSave"
                                disabled={
                                    !savedFilterName ||
                                    !!savedFilterLookup?.[
                                        savedFilterName.trim()
                                    ]
                                }
                                variant="primary"
                                loading={isUserLoading}
                                onClick={() => saveFilters()}
                            >
                                {isUserLoading ? 'Saving' : 'Save'}
                            </Button>
                        </span>
                    }
                    onDismiss={() => setIsSaveFilterModalActive(false)}
                >
                    <FormField
                        label="List name"
                        description="Create a name for this list's filter settings"
                        errorText={
                            !!savedFilterLookup?.[savedFilterName.trim()]
                                ? 'A filter with this name already exists. Please select another name.'
                                : null
                        }
                    >
                        <Input
                            data-testid="InstructorListSaveFilterModalInput"
                            value={savedFilterName}
                            onInput={(e) => setSavedFilterName(e.detail.value)}
                        />
                    </FormField>
                </Modal>
                {isManageFilterModalActive && (
                    <Formik
                        validateOnChange={validateOnChange}
                        validateOnBlur={validateOnChange}
                        initialValues={getSavedFilterFormValues()}
                        onSubmit={saveFilters}
                    >
                        {(formikValues: FormikProps<SavedFilterForm>) => (
                            <Modal
                                className="saved-filters"
                                header="Manage saved filters"
                                data-testid="ActivityListManageFilterModal"
                                visible={isManageFilterModalActive}
                                onDismiss={() =>
                                    setIsManageFilterModalActive(false)
                                }
                                footer={
                                    <span className="awsui-util-f-r">
                                        <Button
                                            data-testid="ActivityListManageFilterModalCancel"
                                            variant="link"
                                            onClick={() =>
                                                setIsManageFilterModalActive(
                                                    false,
                                                )
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            data-testid="ActivityListManageFilterUpdateButton"
                                            variant="primary"
                                            onClick={() => {
                                                if (!formikValues.dirty) {
                                                    setIsManageFilterModalActive(
                                                        false,
                                                    );
                                                    return;
                                                }

                                                // Polaris guidelines: form with multiple fields
                                                // set validation on change and blur after first submit
                                                if (!validateOnChange) {
                                                    setValidateOnChange(true);
                                                }

                                                formikValues.submitForm();
                                            }}
                                            loading={isUserLoading}
                                        >
                                            {isUserLoading ? 'Saving' : 'Save'}
                                        </Button>
                                    </span>
                                }
                            >
                                <Form className="awsui-grid">
                                    <div className="saved-filters--header awsui-util-mb-l awsui-util-pb-s">
                                        <h5 className="awsui-util-font-size-2">
                                            Saved filters
                                        </h5>
                                    </div>
                                    <FieldArray name="filters">
                                        {(arrayHelper) => (
                                            <>
                                                {formikValues.values.filters.map(
                                                    (filter, index) => (
                                                        <Field
                                                            key={`filter_${index}`}
                                                            name={`filters[${index}].name`}
                                                            validate={() =>
                                                                validateFilterName(
                                                                    formikValues.values,
                                                                    filter,
                                                                    formikValues.getFieldMeta(
                                                                        `filters[${index}].name`,
                                                                    ),
                                                                )
                                                            }
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                                meta,
                                                            }: FieldProps) => (
                                                                <FormField
                                                                    errorText={
                                                                        meta.error ??
                                                                        ''
                                                                    }
                                                                    secondaryControl={
                                                                        <Button
                                                                            data-testid={`ActivityListManageFilterModalDelete_${index}`}
                                                                            formAction="none"
                                                                            onClick={() => {
                                                                                arrayHelper.remove(
                                                                                    index,
                                                                                );

                                                                                form.validateForm();
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    }
                                                                >
                                                                    <Input
                                                                        data-testid={`ActivityListManageFilterModalInput_${index}`}
                                                                        name={
                                                                            field.name
                                                                        }
                                                                        value={
                                                                            field.value
                                                                        }
                                                                        onInput={({
                                                                            detail,
                                                                        }) => {
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                detail.value,
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormField>
                                                            )}
                                                        </Field>
                                                    ),
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                </Form>
                            </Modal>
                        )}
                    </Formik>
                )}
            </>
        );
    } else {
        return <Spinner data-testid="ActivityListLoadingSpinner" />;
    }
};

export default ActivityList;
