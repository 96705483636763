import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Alert,
    Button,
    Spinner,
    Table,
    TextFilter,
    Pagination,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import {
    AJAX_CALL_ERROR,
    GRIMSBY_PAGE_COUNT,
    LOADING_TEXT,
} from '../../../../common/constants/grimsby';
import {
    resetCoursesListSlice,
    getCoursesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllCourses,
    selectError,
    selectCurrentPageIndex,
    selectTotalCoursesCount,
    selectPagesCount,
    setSearchText,
    setFrom,
    setCurrentPageIndex,
} from '../../../../common/store/slices/courseListSlice';
import Can from '../../../../common/components/Can';
import { Actions } from '../../../../common/constants/auth';
import { selectSearchText } from '../../../../common/store/slices/courseListSlice';
import { columnDefinitions } from './AdminCoursesList.definitions';

export const USER_TABLE_FILTER_TEXT = 'Search courses by title';
const COURSES_TABLE_TITLE = 'Courses';
export const COURSES_NAME_KEY = 'course';

const AdminCoursesList = () => {
    const error = useSelector(selectError);
    const isLoading = useSelector(selectIsLoading);
    const isLoaded = useSelector(selectIsLoaded);
    const courseList = useSelector(selectAllCourses);
    const pagesCount = useSelector(selectPagesCount);
    const searchText = useSelector(selectSearchText);
    const totalCoursesCount = useSelector(selectTotalCoursesCount);
    const currentPageIndex = useSelector(selectCurrentPageIndex);
    const [filterText, setFilterText] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCoursesList());
        return () => {
            dispatch(resetCoursesListSlice());
        };
    }, [dispatch]);

    function handlerDelayedFilterChange() {
        dispatch(setFrom(0));
        dispatch(setSearchText(filterText));
        dispatch(getCoursesList());
    }

    if (error) {
        return (
            <Alert
                data-testid="AdminCoursesListError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (isLoaded) {
        return (
            <Table
                data-testid="AdminCoursesList"
                loading={isLoading}
                loadingText={LOADING_TEXT}
                columnDefinitions={columnDefinitions}
                items={courseList}
                stickyHeader={true}
                stickyColumns={{
                    first: 1,
                    last: 0,
                }}
                header={
                    <Header
                        variant="h1"
                        actions={
                            <Can
                                perform={Actions.SYSTEM_ADMINISTRATION}
                                yes={() => (
                                    <SpaceBetween
                                        direction="horizontal"
                                        size="xs"
                                    >
                                        <Button
                                            iconName="add-plus"
                                            variant="primary"
                                            data-testid="AdminCoursesListActionsCreate"
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/admin/business-data/courses/create`,
                                                })
                                            }
                                        >
                                            Add new course
                                        </Button>
                                    </SpaceBetween>
                                )}
                            />
                        }
                    >
                        {`${COURSES_TABLE_TITLE} (${totalCoursesCount})`}
                    </Header>
                }
                empty={
                    <div className="awsui-util-t-c">
                        <div className="awsui-util-pt-s awsui-util-mb-xs">
                            <b>No courses</b>
                        </div>
                        <p className="awsui-util-mb-s">
                            No courses to display.
                        </p>
                    </div>
                }
                filter={
                    <TextFilter
                        filteringText={filterText}
                        filteringPlaceholder={USER_TABLE_FILTER_TEXT}
                        filteringAriaLabel={USER_TABLE_FILTER_TEXT}
                        onChange={(event) =>
                            setFilterText(event.detail.filteringText)
                        }
                        onDelayedChange={handlerDelayedFilterChange}
                    />
                }
                pagination={
                    <Pagination
                        pagesCount={pagesCount}
                        disabled={isLoading}
                        currentPageIndex={currentPageIndex}
                        onChange={(event) => {
                            dispatch(
                                setCurrentPageIndex(
                                    event.detail.currentPageIndex,
                                ),
                            );
                            const from =
                                (event.detail.currentPageIndex - 1) *
                                GRIMSBY_PAGE_COUNT;
                            dispatch(setFrom(from));
                            dispatch(getCoursesList());
                        }}
                    />
                }
            ></Table>
        );
    } else {
        return <Spinner data-testid="AdminCoursesListSpinner" />;
    }
};

export default AdminCoursesList;