import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getRevenueTypesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllRevenueTypes,
    selectError,
    updateRevenueType,
    selectSelectedRevenueType,
    setSelectedRevenueType,
    addRevenueType,
    selectCount,
    resetRevenueTypesSlice,
} from '../../../common/store/slices/revenueTypesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { RevenueTypeItemData } from '../../../common/interfaces/businessDataItem/revenueTypeItem';

const REVENUE_TYPES_TABLE_TITLE = 'Revenue types';
const REVENUE_TYPES_DISPLAY_SINGULAR = 'Revenue type';
export const REVENUE_TYPE_NAME_KEY = 'revenue_type';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (revenueType: RevenueTypeItemData) =>
            formatStatus(revenueType.active.toString()),
    },
];

/**
 * Editable form fields for RevenueType
 * The keys must map to RevenueTypeItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<RevenueTypeItemData> = {
    // the keys must match RevenueTypeItem properties
    revenue_type: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${REVENUE_TYPES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<RevenueTypeItemData> => {
            return { revenue_type: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<RevenueTypeItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<RevenueTypeItemData> = {
    ...createFormSchema,
    revenue_type: {
        ...createFormSchema.revenue_type,
        disabled: true,
    },
};

const AdminRevenueTypes = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useRevenueTypes(): AdminBusinessDataSelectors<
        RevenueTypeItemData
    > {
        const dispatch = useDispatch();

        const items: RevenueTypeItemData[] = useSelector(selectAllRevenueTypes);
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: RevenueTypeItemData | null = useSelector(
            selectSelectedRevenueType,
        );
        useEffect(() => {
            dispatch(getRevenueTypesList());
            dispatch(setSelectedRevenueType(null));

            return () => {
                dispatch(resetRevenueTypesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (revenueType: RevenueTypeItemData) => {
        if (revenueType) {
            dispatch(setSelectedRevenueType(revenueType.revenue_type));
            await dispatch(getRevenueTypesList());
        }
    };

    const handleItemUpdate = async (data: RevenueTypeItemData) => {
        dispatch(setSelectedRevenueType(null));
        const dispatchPromise = dispatch<any>(updateRevenueType(data));
        await handleBusinessDataNotification({
            businessDataType: REVENUE_TYPE_NAME_KEY,
            businessDataDisplayNameLower: REVENUE_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.revenue_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        revenue_type,
        active,
    }: RevenueTypeItemData) => {
        const dispatchPromise = dispatch<any>(
            addRevenueType(active, revenue_type),
        );
        await handleBusinessDataNotification({
            businessDataType: REVENUE_TYPE_NAME_KEY,
            businessDataDisplayNameLower: REVENUE_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: revenue_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementRevenueTypes">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={REVENUE_TYPES_DISPLAY_SINGULAR}
                itemNameKey={REVENUE_TYPE_NAME_KEY}
                title={REVENUE_TYPES_TABLE_TITLE}
                useItemList={useRevenueTypes}
            />
        </div>
    );
};

export default AdminRevenueTypes;
