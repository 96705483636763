import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getFreelancerStatusesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllFreelancerStatuses,
    selectError,
    updateFreelancerStatus,
    selectSelectedFreelancerStatus,
    setSelectedFreelancerStatus,
    addFreelancerStatus,
    selectCount,
    resetFreelancerStatusesSlice,
} from '../../../common/store/slices/freelancerStatusesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { FreelancerStatusItemData } from '../../../common/interfaces/businessDataItem/freelancerStatusItem';
import { ProgramTypeItemData } from '../../../common/interfaces/businessDataItem/programTypeItem';

const FREELANCER_STATUSES_TABLE_TITLE = 'Freelancer statuses';
const FREELANCER_STATUSES_DISPLAY_SINGULAR = 'Freelancer status';
export const FREELANCER_STATUSES_NAME_KEY = 'freelancer_status';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (freelancerStatus: FreelancerStatusItemData) =>
            formatStatus(freelancerStatus.active.toString()),
    },
];

/**
 * Editable form fields for FreelancerStatus
 * The keys must map to FreelancerStatusItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<ProgramTypeItemData> = {
    // the keys must match FreelancerDataStatus properties
    freelancer_status: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${FREELANCER_STATUSES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<FreelancerStatusItemData> => {
            return { freelancer_status: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<FreelancerStatusItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<FreelancerStatusItemData> = {
    ...createFormSchema,
    freelancer_status: {
        ...createFormSchema.freelancer_status,
        disabled: true,
    },
};

const AdminFreelancerStatuses = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useFreelancerStatuses(): AdminBusinessDataSelectors<
        FreelancerStatusItemData
    > {
        const dispatch = useDispatch();

        const items: FreelancerStatusItemData[] = useSelector(
            selectAllFreelancerStatuses,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: FreelancerStatusItemData | null = useSelector(
            selectSelectedFreelancerStatus,
        );
        useEffect(() => {
            dispatch(getFreelancerStatusesList());
            dispatch(setSelectedFreelancerStatus(null));

            return () => {
                dispatch(resetFreelancerStatusesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        freelancerStatus: FreelancerStatusItemData,
    ) => {
        if (freelancerStatus) {
            dispatch(
                setSelectedFreelancerStatus(freelancerStatus.freelancer_status),
            );
            await dispatch(getFreelancerStatusesList());
        }
    };

    const handleItemUpdate = async (data: FreelancerStatusItemData) => {
        dispatch(setSelectedFreelancerStatus(null));
        const dispatchPromise = dispatch<any>(updateFreelancerStatus(data));
        await handleBusinessDataNotification({
            businessDataType: FREELANCER_STATUSES_NAME_KEY,
            businessDataDisplayNameLower: FREELANCER_STATUSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.freelancer_status,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        freelancer_status,
        active,
    }: any) => {
        const dispatchPromise = dispatch<any>(
            addFreelancerStatus(active, freelancer_status),
        );
        await handleBusinessDataNotification({
            businessDataType: FREELANCER_STATUSES_NAME_KEY,
            businessDataDisplayNameLower: FREELANCER_STATUSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: freelancer_status,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementFreelancerStatuses">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={FREELANCER_STATUSES_DISPLAY_SINGULAR}
                itemNameKey={FREELANCER_STATUSES_NAME_KEY}
                title={FREELANCER_STATUSES_TABLE_TITLE}
                useItemList={useFreelancerStatuses}
            />
        </div>
    );
};

export default AdminFreelancerStatuses;
