import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { InitialFormState } from '../AdminBusinessData/AdminBusinessData';
import {
    Button,
    Checkbox,
    CustomDetailEvent,
    Form,
    FormField,
    Input,
    Multiselect,
    RadioGroup,
    Select,
} from '@amzn/awsui-components-react';
import { ErrorState } from '../AdminBusinessData/AdminBusinessData.FormModal';
import { validationTypeMap } from '../../../common/utils/formValidation';
import { getOptionsAndLookupForSelectInput } from '../../../imt/components/Instructor/FormSections/FormSections.common';

interface AdminAtpCompanyFormSchemaBase<BusinessDataType> {
    required: boolean;
    disabled: boolean;
    type: string; // data type
    defaultValue: any;
    formDataTransform: (val: any) => Partial<BusinessDataType>;
}

export enum FormDataTypes {
    STRING = 'string',
    BOOLEAN = 'boolean', // handled by a checkbox
    YESNO = 'yes_no', // handled by yes/no radio buttons
    LIST = 'list', // lists of inputs
}

export const EMPTY_COORDINATOR_EMAILS = `No training coordinators have been added`;

export interface AdminAtpCompanyFormSchema<BusinessDataType> {
    [key: string]: AdminAtpCompanyFormSchemaBase<BusinessDataType>;
}

export interface AdminAtpCompanyFormProps<BusinessDataType> {
    initialState: BusinessDataType;
    mode: string;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    saveItem: (data: BusinessDataType) => Promise<void>;
    formSchema: AdminAtpCompanyFormSchema<BusinessDataType>;
    displaySingular: string;
    nameKey: string;
}

const statusRadioItems: Array<RadioGroup.RadioButtonDefinition> = [
    {
        value: 'true',
        label: 'Active',
    },
    {
        value: 'false',
        label: 'Inactive',
    },
];

const AdminAtpCompanyForm = <BusinessDataType extends InitialFormState>({
    initialState,
    mode,
    setIsModalOpen,
    isModalOpen,
    saveItem,
    formSchema,
    displaySingular,
    nameKey,
}: PropsWithChildren<AdminAtpCompanyFormProps<BusinessDataType>>) => {
    const [formState, setFormState] = useState(initialState);
    const [errors, setErrors] = useState({} as ErrorState);
    const [isValid, setIsValid] = useState(true);

    const handleFieldEvent = (changes: Partial<BusinessDataType>) => {
        setFormState((values: BusinessDataType) => {
            return { ...values, ...changes };
        });
    };

    const addCoordinatorEmail = () => {
        const currentEmails =
            formState?.training_coordinators_emails &&
            Array.isArray(formState?.training_coordinators_emails)
                ? (formState?.training_coordinators_emails as string[])
                : ([] as string[]);

        setFormState({
            ...formState,
            training_coordinators_emails: [...currentEmails, ''],
        });
    };

    const removeEmail = (index: number, emails?: string[]) => {
        if (!emails) {
            return;
        }

        const updated = [...emails];

        updated.splice(index, 1);

        setFormState({
            ...formState,
            training_coordinators_emails: updated,
        });
    };

    const validateForm = useCallback(
        (formItem: Partial<BusinessDataType>) => {
            if (!formSchema) {
                return false;
            }
            const errors: ErrorState = {};
            let valid = true;
            Object.entries(formSchema).forEach(([key, value]) => {
                // don't run validation on yes/no radio button and boolean checkboxes because
                // it will always be submitted with a value of either true or false
                // and we don't need to set error on `false` value
                if (
                    value.required &&
                    value.type !== FormDataTypes.YESNO &&
                    value.type !== FormDataTypes.BOOLEAN &&
                    (formItem[key]?.length === 0 || !formItem[key])
                ) {
                    if (valid) {
                        valid = false;
                    }
                    errors[key] = 'Required.';
                }

                if (
                    value.type === FormDataTypes.LIST &&
                    Array.isArray(formItem[key]) && key === 'training_coordinators_emails'
                ) {
                    formItem[key]?.forEach((item: any, index: number) => {
                        const emailValidation = validationTypeMap.email(item);
                        if (emailValidation) {
                            if (valid) {
                                valid = false;
                            }

                            errors[`${key}_${index}`] = emailValidation;
                        }
                    });
                }
            });
            setErrors(errors);
            return valid;
        },
        [formSchema],
    );

    useEffect(() => {
        setFormState(initialState);
        setErrors({} as ErrorState);
        setIsValid(true);
    }, [initialState, setFormState]);

    useEffect(() => {
        if (!isValid) {
            setIsValid(validateForm(formState));
        }
    }, [formState, isValid, validateForm, setIsValid]);

    const fundingProgramList = [
        {
            funding_program: 'Acceleration',
            value: 'Acceleration',
        },
        {
            funding_program: 'Quantum Leap',
            value: 'Quantum Leap',
        },
        {
            funding_program: 'Acceleration - Project Hamilton',
            value: 'Acceleration - Project Hamilton',
        },
    ];
    const parseValuesIntoSelectedOptions = (
        values: Array<string>,
        lookup: { [p: string]: Select.Option },
    ) => {
        return values.reduce(
            (acc: Array<{ label: string; id: string }>, program: string) => {
                if (lookup[program]) {
                    acc.push(lookup[program]);
                }
                return acc;
            },
            [],
        );
    };
    const {
        valueLookup: fundingProgramLookup,
        valueOptions: fundingProgramOptions,
    } = getOptionsAndLookupForSelectInput<any>(
        fundingProgramList,
        (funding_program: any) => ({
            label: funding_program.funding_program,
            id: funding_program.value as string,
        }),
    );

    return (
        <Form
            data-testid="Admin-ATP-form"
            actions={
                <div className="awsui-util-pt-xl">
                    <Button
                        variant="link"
                        data-testid={`AdminBusinessData${mode}-${nameKey}-CancelBtn`}
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        data-testid={`AdminBusinessData${mode}-${nameKey}-SubmitBtn`}
                        formAction="submit"
                        onClick={async () => {
                            const isValid = validateForm(formState);
                            if (isValid) {
                                setIsModalOpen(false);
                                await saveItem(formState);
                            }
                            setIsValid(isValid);
                        }}
                    >
                        {mode === 'Update' ? 'Save' : 'Add'}
                    </Button>
                </div>
            }
        >
            <div className="awsui-grid">
                {isModalOpen && (
                    <div className="awsui-grid">
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12">
                                <FormField
                                    label={`${displaySingular} name`}
                                    stretch={true}
                                    errorText={errors['atp_company']}
                                >
                                    <Input
                                        name="atp_company"
                                        value={formState?.atp_company}
                                        disabled={
                                            formSchema['atp_company'].disabled
                                        }
                                        onChange={(
                                            e: CustomDetailEvent<Input.ChangeDetail>,
                                        ) => {
                                            handleFieldEvent(
                                                formSchema[
                                                    'atp_company'
                                                ].formDataTransform(
                                                    e.detail.value,
                                                ),
                                            );
                                        }}
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-atp_company-Input`}
                                    />
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12">
                                <FormField
                                    label="Provider display name"
                                    stretch={true}
                                    errorText={errors['atp_company_display_name']}
                                >
                                    <Input
                                        name="atp_company_display_name"
                                        value={formState?.atp_company_display_name}
                                        onChange={(
                                            e: CustomDetailEvent<Input.ChangeDetail>,
                                        ) => {
                                            handleFieldEvent(
                                                formSchema[
                                                    'atp_company_display_name'
                                                ].formDataTransform(
                                                    e.detail.value,
                                                ),
                                            );
                                        }}
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-atp_company_display_name-Input`}
                                    />
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12">
                                <FormField label="Provider type" stretch={true}>
                                    <Checkbox
                                        name="is_atp"
                                        checked={formState?.is_atp ?? false}
                                        onChange={(
                                            e: CustomEvent<Checkbox.ChangeDetail>,
                                        ) => {
                                            handleFieldEvent(
                                                formSchema[
                                                    'is_atp'
                                                ].formDataTransform(
                                                    e.detail.checked,
                                                ),
                                            );
                                        }}
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-is_atp-Checkbox`}
                                    >
                                        ATP company
                                    </Checkbox>
                                    <Checkbox
                                        name="is_freelance_agency"
                                        checked={
                                            formState?.is_freelance_agency ??
                                            false
                                        }
                                        onChange={(
                                            e: CustomEvent<Checkbox.ChangeDetail>,
                                        ) => {
                                            handleFieldEvent(
                                                formSchema[
                                                    'is_freelance_agency'
                                                ].formDataTransform(
                                                    e.detail.checked,
                                                ),
                                            );
                                        }}
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-is_freelance_agency-Checkbox`}
                                    >
                                        Freelance agency
                                    </Checkbox>
                                    <Checkbox
                                        name="is_reseller"
                                        checked={
                                            formState?.is_reseller ?? false
                                        }
                                        onChange={(
                                            e: CustomEvent<Checkbox.ChangeDetail>,
                                        ) => {
                                            handleFieldEvent(
                                                formSchema[
                                                    'is_reseller'
                                                ].formDataTransform(
                                                    e.detail.checked,
                                                ),
                                            );
                                        }}
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-is_reseller-Checkbox`}
                                    >
                                        Reseller
                                    </Checkbox>
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12">
                                <FormField
                                    errorText={errors?.funding_program_list}
                                    label="Funding programs"
                                >
                                    <Multiselect
                                        className={errors?.funding_program_list}
                                        data-testid={`AdminAtpCompanies${mode}FundingPrograms`}
                                        options={fundingProgramOptions}
                                        placeholder="Select Funding program"
                                        checkboxes={true}
                                        selectedOptions={parseValuesIntoSelectedOptions(
                                            formState?.funding_program_list || [],
                                            fundingProgramLookup,
                                        )}
                                        onChange={(event) =>
                                            handleFieldEvent(
                                                formSchema[
                                                    'funding_program_list'
                                                ].formDataTransform(
                                                    event.detail.selectedOptions.map(
                                                        (funding_program) =>
                                                            funding_program.label,
                                                    ),
                                                ),
                                            )
                                        }
                                    />
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12">
                                <FormField
                                    label="Associated Programs (auto set)"
                                    stretch={true}
                                >
                                    <Input
                                        name="associated_programs"
                                        value={
                                            formState?.associated_programs &&
                                            Array.isArray(
                                                formState?.associated_programs,
                                            )
                                                ? formState
                                                      ?.associated_programs[0]
                                                : 'Not set'
                                        }
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-associated_programs-Input`}
                                        disabled={true}
                                    />
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12">
                                <FormField
                                    label="SPMS ID"
                                    stretch={true}
                                    errorText={errors['spms_id']}
                                >
                                    <Input
                                        name="spms_id"
                                        value={formState?.spms_id}
                                        onChange={(
                                            e: CustomDetailEvent<Input.ChangeDetail>,
                                        ) => {
                                            handleFieldEvent(
                                                formSchema[
                                                    'spms_id'
                                                ].formDataTransform(
                                                    e.detail.value,
                                                ),
                                            );
                                        }}
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-spms_id-Input`}
                                    />
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12">
                                <FormField
                                    label="Gilmore license ID"
                                    stretch={true}
                                    errorText={errors['gilmore_license_id']}
                                >
                                    <Input
                                        name="gilmore_license_id"
                                        value={formState?.gilmore_license_id}
                                        onChange={(
                                            e: CustomDetailEvent<Input.ChangeDetail>,
                                        ) => {
                                            handleFieldEvent(
                                                formSchema[
                                                    'gilmore_license_id'
                                                ].formDataTransform(
                                                    e.detail.value,
                                                ),
                                            );
                                        }}
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-gilmore_license_id-Input`}
                                    />
                                </FormField>
                            </div>
                        </div>
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12 awsui-util-font-size-3">
                                Training coordinators
                            </div>
                            <div className="col-12">
                                {formState?.training_coordinators_emails &&
                                !!formState?.training_coordinators_emails
                                    .length ? (
                                    <FormField
                                        label="Training coordinator's email address"
                                        stretch={true}
                                        data-testid="AdminBusinessData-atp_company-training_coordinators"
                                    >
                                        {formState?.training_coordinators_emails?.map(
                                            (email: string, index: number) => (
                                                <div
                                                    className="awsui-util-pb-l"
                                                    key={index}
                                                >
                                                    <FormField
                                                        errorText={
                                                            errors[
                                                                `training_coordinators_emails_${index}`
                                                            ]
                                                        }
                                                        secondaryControl={
                                                            <Button
                                                                data-testid={`AdminBusinessData${mode}-${nameKey}-training_coordinators_emails_${index}-remove-Button`}
                                                                onClick={() => {
                                                                    removeEmail(
                                                                        index,
                                                                        formState.training_coordinators_emails,
                                                                    );
                                                                }}
                                                            >
                                                                Remove
                                                            </Button>
                                                        }
                                                    >
                                                        <Input
                                                            name={`training_coordinators_emails_${index}`}
                                                            value={email}
                                                            onChange={(
                                                                e: CustomEvent<Input.ChangeDetail>,
                                                            ) => {
                                                                const currentEmails =
                                                                    formState.training_coordinators_emails
                                                                        ? [
                                                                              ...formState.training_coordinators_emails,
                                                                          ]
                                                                        : [];

                                                                // remove current value at index and insert the new value
                                                                currentEmails.splice(
                                                                    index,
                                                                    1,
                                                                    e.detail
                                                                        .value,
                                                                );
                                                                handleFieldEvent(
                                                                    formSchema[
                                                                        'training_coordinators_emails'
                                                                    ].formDataTransform(
                                                                        currentEmails,
                                                                    ),
                                                                );
                                                            }}
                                                            data-testid={`AdminBusinessData${mode}-${nameKey}-training_coordinators_emails_${index}-Input`}
                                                        />
                                                    </FormField>
                                                </div>
                                            ),
                                        )}
                                    </FormField>
                                ) : (
                                    <div
                                        className="awsui-text-secondary awsui-util-pb-l"
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-training_coordinators_emails-empty`}
                                    >
                                        {EMPTY_COORDINATOR_EMAILS}
                                    </div>
                                )}
                                <Button
                                    onClick={() => {
                                        addCoordinatorEmail();
                                    }}
                                    data-testid="AdminBusinessDataUpdate-atp_company-add_coordinator_Button"
                                >
                                    Add training coordinator
                                </Button>
                            </div>
                        </div>
                        <div className="awsui-row awsui-util-spacing-v-s">
                            <div className="col-12">
                                <FormField
                                    label="Provider status"
                                    stretch={true}
                                >
                                    <RadioGroup
                                        value={`${formState?.active}`}
                                        items={statusRadioItems}
                                        onChange={(
                                            e: CustomDetailEvent<RadioGroup.ChangeDetail>,
                                        ) => {
                                            const val = formSchema[
                                                'active'
                                            ].formDataTransform(e.detail.value);
                                            handleFieldEvent(val);
                                        }}
                                        data-testid={`AdminBusinessData${mode}-${nameKey}-active-RadioGrp`}
                                        ariaRequired={true}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Form>
    );
};

export default AdminAtpCompanyForm;
