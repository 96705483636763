import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getPartnerInitiativesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllPartnerInitiatives,
    selectError,
    updatePartnerInitiative,
    selectSelectedPartnerInitiative,
    setSelectedPartnerInitiative,
    addPartnerInitiative,
    selectCount,
    resetPartnerInitiativesSlice,
} from '../../../common/store/slices/partnerInitiativesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { PartnerInitiativeItemData } from '../../../common/interfaces/businessDataItem/partnerInitiativeItem';

const PARTNER_INITIATIVE_TYPES_TABLE_TITLE = 'Partner initiatives';
const PARTNER_INITIATIVE_TYPES_DISPLAY_SINGULAR = 'Partner initiative';
export const PARTNER_INITIATIVE_NAME_KEY = 'partner_initiative';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (partnerInitiative: PartnerInitiativeItemData) =>
            formatStatus(partnerInitiative.active.toString()),
    },
];

/**
 * Editable form fields for PartnerInitiative
 * The keys must map to PartnerInitiativeItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<PartnerInitiativeItemData> = {
    // the keys must match PartnerInitiativeItem properties
    partner_initiative: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${PARTNER_INITIATIVE_TYPES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<PartnerInitiativeItemData> => {
            return { partner_initiative: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<PartnerInitiativeItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<PartnerInitiativeItemData> = {
    ...createFormSchema,
    partner_initiative: {
        ...createFormSchema.partner_initiative,
        disabled: true,
    },
};

const AdminPartnerInitiatives = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function usePartnerInitiatives(): AdminBusinessDataSelectors<
        PartnerInitiativeItemData
    > {
        const dispatch = useDispatch();

        const items: PartnerInitiativeItemData[] = useSelector(
            selectAllPartnerInitiatives,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: PartnerInitiativeItemData | null = useSelector(
            selectSelectedPartnerInitiative,
        );
    useEffect(() => {
        dispatch(getPartnerInitiativesList());
        dispatch(setSelectedPartnerInitiative(null));

        return () => {
            dispatch(resetPartnerInitiativesSlice());
        };
    }, [dispatch]);
    return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        partnerInitiative: PartnerInitiativeItemData,
    ) => {
        if (partnerInitiative) {
            dispatch(
                setSelectedPartnerInitiative(partnerInitiative.partner_initiative),
            );
            await dispatch(getPartnerInitiativesList());
        }
    };

    const handleItemUpdate = async (data: PartnerInitiativeItemData) => {
        dispatch(setSelectedPartnerInitiative(null));
        const dispatchPromise = dispatch<any>(updatePartnerInitiative(data));
        await handleBusinessDataNotification({
            businessDataType: PARTNER_INITIATIVE_NAME_KEY,
            businessDataDisplayNameLower: PARTNER_INITIATIVE_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.partner_initiative,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
                                        partner_initiative,
                                        active,
                                    }: PartnerInitiativeItemData) => {
        const dispatchPromise = dispatch<any>(
            addPartnerInitiative(active, partner_initiative),
        );
        await handleBusinessDataNotification({
            businessDataType: PARTNER_INITIATIVE_NAME_KEY,
            businessDataDisplayNameLower: PARTNER_INITIATIVE_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: partner_initiative,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementPartnerInitiatives">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={
                    PARTNER_INITIATIVE_TYPES_DISPLAY_SINGULAR
                }
                itemNameKey={PARTNER_INITIATIVE_NAME_KEY}
                title={PARTNER_INITIATIVE_TYPES_TABLE_TITLE}
                useItemList={usePartnerInitiatives}
            />
        </div>
    );
};

export default AdminPartnerInitiatives;
