/**
 * Method to convert key value pairs into a query string
 * @param {Object} params - key value pairs to be converted into a query string
 * @return: A query string with URI encode values
 */
const createQueryString = (params: { [key: string]: any }) => {
    const encodeURIComponentForObject = (val: any) =>
        encodeURIComponent(JSON.stringify(val));

    return `?${Object.entries(params)
        .reduce((acc: Array<string>, [key, val]) => {
            if (val !== null) {
                acc.push(
                    key +
                        '=' +
                        (typeof val === 'object'
                            ? encodeURIComponentForObject(val)
                            : encodeURIComponent(val)),
                );
            }

            return acc;
        }, [])
        .join('&')}`;
};

export default createQueryString;
