import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getCourseCategoryList,
    selectIsLoading,
    selectIsLoaded,
    selectAllCourseCategories,
    selectError,
    updateCourseCategory,
    selectSelectedCourseCategory,
    setSelectedCourseCategory,
    addCourseCategory,
    selectCount,
    resetCourseCategoriesSlice,
} from '../../../common/store/slices/courseCategoriesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { CourseCategoryItemData } from '../../../common/interfaces/businessDataItem/courseCategoryItem';

const COURSE_CATEGORIES_TABLE_TITLE = 'Course Categories';
const COURSE_CATEGORIES_DISPLAY_SINGULAR = 'Course category';
export const COURSE_CATEGORIES_NAME_KEY = 'course_category';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (courseCategory: CourseCategoryItemData) =>
            formatStatus(courseCategory.active.toString()),
    },
];

/**
 * Editable form fields for CourseCategory
 * The keys must map to CourseCategoryItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<CourseCategoryItemData> = {
    
    course_category: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${COURSE_CATEGORIES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<CourseCategoryItemData> => {
            return { course_category: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<CourseCategoryItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<CourseCategoryItemData> = {
    ...createFormSchema,
    course_category: {
        ...createFormSchema.course_category,
        disabled: true,
    },
};

const AdminCourseCategories = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useCourseCategories(): AdminBusinessDataSelectors<
        CourseCategoryItemData
    > {
        const dispatch = useDispatch();

        const items: CourseCategoryItemData[] = useSelector(
            selectAllCourseCategories,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: CourseCategoryItemData | null = useSelector(
            selectSelectedCourseCategory,
        );
        useEffect(() => {
            dispatch(getCourseCategoryList());
            dispatch(setSelectedCourseCategory(null));

            return () => {
                dispatch(resetCourseCategoriesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (courseCategory: CourseCategoryItemData) => {
        if (courseCategory) {
            dispatch(setSelectedCourseCategory(courseCategory.course_category));
            await dispatch(getCourseCategoryList());
        }
    };

    const handleItemUpdate = async (data: CourseCategoryItemData) => {
        dispatch(setSelectedCourseCategory(null));
        const dispatchPromise = dispatch<any>(updateCourseCategory(data));
        await handleBusinessDataNotification({
            businessDataType: COURSE_CATEGORIES_NAME_KEY,
            businessDataDisplayNameLower: COURSE_CATEGORIES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.course_category,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        course_category,
        active,
    }: CourseCategoryItemData) => {
        const dispatchPromise = dispatch<any>(
            addCourseCategory(active, course_category),
        );
        await handleBusinessDataNotification({
            businessDataType: COURSE_CATEGORIES_NAME_KEY,
            businessDataDisplayNameLower: COURSE_CATEGORIES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: course_category,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementCourseCategories">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={COURSE_CATEGORIES_DISPLAY_SINGULAR}
                itemNameKey={COURSE_CATEGORIES_NAME_KEY}
                title={COURSE_CATEGORIES_TABLE_TITLE}
                useItemList={useCourseCategories}
            />
        </div>
    );
};

export default AdminCourseCategories;
