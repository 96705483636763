import 'regenerator-runtime';
import { Cell, Fill, Row, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { emailRegEx } from './formValidation';
import { ACTIVITY_PATH } from '../../smt/constants/path';

export const FILE_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const FILE_EXTENSION = '.xlsx';

export const getFormattedDateTime = (date: Date, time?: Date): string => {
    let offset = new Date(0).getTimezoneOffset(); // timezone offset in minutes
    date.setMinutes(date.getMinutes() + offset); // add offset back so formatted date looks the same as in the Excel
    if (time !== undefined) {
        time.setMinutes(time.getMinutes() + offset); // add offset back to formatted time
        date.setHours(time.getHours());
        date.setMinutes(time.getMinutes());
        return date.toLocaleString().replace(',', ''); // convert back to string removing comma between date and time
    }
    return date.toLocaleDateString();
};

interface BlockedTimeDict {
    row_number: number;
    start_datetime: string;
    end_datetime: string;
    blocked_time_name: string;
    blocked_time_notes: string;
}
interface InstructorBlockedTimesDict {
    instructor_email: string;
    blocked_times: BlockedTimeDict[];
}

const headers = [
    'Instructor Email',
    'Start Date',
    'Start Time',
    'End Date',
    'End Time',
    'Blocked Time Name',
];

const noteHeaders = [
    'Commercial Class if applicable',
    'Partner Class if applicable',
    'Other Class if applicable',
    'Time Block Notes (if applicable)',
    "*Manager's Name",
    'Requestor Name if not Manager (optional)',
    '*Uploaded to GRIMSBY by RM (alias)',
    '*Upload to GRIMSBY Date by RM (mm/dd/yyyy)',
];

const IGNORED_COLUMN = 'Ignored by GRIMSBY';

const isDate = (date): boolean => {
    return date instanceof Date && typeof date.setMinutes === 'function';
};

const blueFill: Fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'ADD8E6' },
};

const setCellErrorStyle = (cell: Cell) => {
    cell.style = {
        ...cell.style,
        font: { ...cell.font, color: { argb: 'FFFF0000' } },
        border: {
            ...cell.border,
            top: { style: 'medium', color: { argb: 'F08080' } },
            left: {
                style: 'medium',
                color: { argb: 'F08080' },
            },
            bottom: {
                style: 'medium',
                color: { argb: 'F08080' },
            },
            right: {
                style: 'medium',
                color: { argb: 'F08080' },
            },
        },
        alignment: {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
        },
    };
};

const setHeaderStyle = (cell: Cell) => {
    cell.font = {
        name: 'Calibri (Body)',
        family: 2,
        bold: true,
        size: 11,
    };
    cell.border = {
        top: { style: 'thin', color: { argb: 'd3d3d3' } },
        left: { style: 'thin', color: { argb: 'd3d3d3' } },
        bottom: { style: 'thin', color: { argb: 'd3d3d3' } },
        right: { style: 'thin', color: { argb: 'd3d3d3' } },
    };
    cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
};

const setRowErrorStyle = (row: Row) => {
    row.eachCell(function (cell, colNumber) {
        cell.style = {
            ...cell.style,
            fill: blueFill,
        };
    });
};

export class BlockedTimesExcelUtil {
    public readonly workbook: Workbook;
    public readonly errors: string[];
    public readonly headerValidations: boolean[];
    public readonly instructorBlockedTimes: {
        instructors_blocked_times: InstructorBlockedTimesDict[];
        start_date: string;
        end_date: string;
    };
    public formattingErrors: Map<number, Map<number, string>>;
    public skippedRows: number[];
    public rowCount: number;
    private worksheet: Worksheet;
    private instructorBlockedTimeTypeList: Set<string>;

    constructor(workbook: Workbook, instructorBlockedTimeTypeList: string[]) {
        this.workbook = workbook;
        this.headerValidations = [];
        this.errors = [];
        this.formattingErrors = new Map<number, Map<number, string>>();
        this.skippedRows = [];
        this.instructorBlockedTimes = {
            instructors_blocked_times: [],
            start_date: '',
            end_date: '',
        };
        this.instructorBlockedTimeTypeList = new Set(
            instructorBlockedTimeTypeList,
        );
    }

    public async writeWorkbook() {
        const buffer = await this.workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: FILE_TYPE });
        saveAs(blob, `blocked-times-import-template${FILE_EXTENSION}`);
    }

    public async saveAsUrl() {
        const buffer = await this.workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: FILE_TYPE });
        // create file url
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
            'download',
            `errors-blocked-times${FILE_EXTENSION}`,
        );
        fileLink.setAttribute('type', FILE_TYPE);
        fileLink.innerText = 'Excel file';
        fileLink.id = 'error-file-download';
        const tempAnchor = document.getElementById('error-file-download');
        tempAnchor!.parentNode!.replaceChild(fileLink, tempAnchor as Node);
    }

    public generateConflictReport(conflictingBlockedTimes) {
        let conflicts = new Array(this.rowCount + 1).fill('');
        conflicts[0] = 'Conflict';
        let conflictStarts = new Array(this.rowCount + 1).fill('');
        conflictStarts[0] =
            "Conflict Start DateTime (in instructor's timezone)";
        let conflictEnds = new Array(this.rowCount + 1).fill('');
        conflictEnds[0] = "Conflict End DateTime (in instructor's timezone)";
        let colCount = this.worksheet.columnCount;

        if (colCount > 20) {
            // remove existing errors
            this.worksheet.spliceColumns(colCount, 3);
            colCount = colCount - 3; // reset couner for re-upload
        }

        this.worksheet.spliceColumns(
            colCount + 1,
            0,
            conflicts,
            conflictStarts,
            conflictEnds,
        );
        // fix merged cells problem while splicing the last column
        this.worksheet.spliceColumns(colCount + 4, 1);

        conflictingBlockedTimes.forEach((item, i) => {
            const rowNumber = item['row_number'];
            let conflict = 'Blocked time';
            if (item['record_type'] === 'ACTIVITY') {
                conflict = `${window.location.origin}${ACTIVITY_PATH.BASE}/${item['conflict_pk']}`;
            }
            if (item['conflict_message'] !== undefined) {
                conflict = item['conflict_message'];
            }
            // add info about conflict in the last 3 rows
            let cell = this.worksheet.getRow(rowNumber).getCell(colCount + 1);
            cell.value = conflict;
            setCellErrorStyle(cell);
            cell = this.worksheet.getRow(rowNumber).getCell(colCount + 2);
            cell.value = item['conflict_start_datetime'];
            setCellErrorStyle(cell);
            cell = this.worksheet.getRow(rowNumber).getCell(colCount + 3);
            cell.value = item['conflict_end_datetime'];
            setCellErrorStyle(cell);
            // highlight the whole row
            setRowErrorStyle(this.worksheet.getRow(rowNumber));
        });

        // add borders to the last 3 columns
        this.worksheet.getRow(1).eachCell(function (cell, colNumber) {
            if (colNumber > colCount) {
                setHeaderStyle(cell);
            }
        });

        return this;
    }

    private getEmailColIndex(): number {
        this.worksheet.getRow(1).eachCell(function (cell, colNumber) {
            if ((cell.value as string).indexOf('Instructor Email') >= 0) {
                return colNumber;
            }
        });
        return 2;
    }

    private markErrorCell(this, row: number, cell: number, note: string) {
        if (!this.formattingErrors.has(row)) {
            this.formattingErrors.set(row, new Map());
        }
        this.formattingErrors.get(row).set(cell, note);
    }

    public generateErrorReport(not_found_instructors?: Array<string>) {
        let emailColIndex = this.getEmailColIndex();

        if (not_found_instructors) {
            const not_found = new Set(not_found_instructors);
            this.worksheet.eachRow(function (row, rowNumber) {
                let cell = row.getCell(emailColIndex);
                const email = cell.value as string;
                if (not_found.has(email)) {
                    cell.note = 'Instructor not found';
                    setCellErrorStyle(cell);
                }
                setRowErrorStyle(row);
            });
        } else {
            this.formattingErrors.forEach((value, rowIndex) => {
                value.forEach((note, cellIndex) => {
                    const cell = this.worksheet
                        .getRow(rowIndex)
                        .getCell(cellIndex);
                    cell.note = note;
                    setCellErrorStyle(cell);
                });
                // highlight row
                setRowErrorStyle(this.worksheet.getRow(rowIndex));
            });
        }

        return this;
    }

    public async readWorkbook(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = () => {
                const buffer = reader.result;
                //@ts-ignore
                this.workbook.xlsx.load(buffer).then((workbook) => {
                    this.worksheet = workbook.getWorksheet('Blocked Times');
                    this.rowCount = this.worksheet.rowCount;
                    const headerRow = this.worksheet.getRow(1);
                    this.validateHeaders(headerRow);
                    // Check headers validated
                    if (this.headerValidations.every(Boolean)) {
                        this.populateInstructorBlockedTimesDict();
                    } else {
                        this.errors.push('header-validation');
                    }
                    resolve(true);
                });
            };
        });
    }

    public populateInstructorBlockedTimesDict(this) {
        let firstRow = this.worksheet.getRow(1);
        if (!firstRow.cellCount) return;
        let keys = firstRow.values;
        let start = undefined;
        let end = undefined;
        // instructor's index
        let instructorsMap: Map<string, number> = new Map();
        let counter = 0;
        this.worksheet.eachRow((row, rowIndex) => {
            if (rowIndex !== 1 && rowIndex !== 2) {
                let instructor_email = '';
                let start_date = new Date(0);
                let end_date = new Date(0);
                let start_time = new Date(0);
                let end_time = new Date(0);
                let blocked_time_name = '';
                let notes = [];
                let t = undefined;
                let filled_cells = 0;
                row.eachCell((cell, cellIndex) => {
                    if (
                        cell.value !== null &&
                        keys[cellIndex] !== IGNORED_COLUMN &&
                        !!cell.text
                    ) {
                        switch (keys[cellIndex]) {
                            case 'Instructor Email': {
                                instructor_email = cell.value as string;
                                if (emailRegEx.test(instructor_email)) {
                                    filled_cells++;
                                } else {
                                    this.markErrorCell(
                                        rowIndex,
                                        cellIndex,
                                        'Incorrect email',
                                    );
                                }
                                break;
                            }
                            case 'Start Date': {
                                start_date = cell.value as Date;
                                if (isDate(start_date)) {
                                    if (
                                        start === undefined ||
                                        start_date < start
                                    ) {
                                        start = start_date;
                                    }
                                    filled_cells++;
                                } else {
                                    this.markErrorCell(
                                        rowIndex,
                                        cellIndex,
                                        'Incorrect Date format',
                                    );
                                }
                                break;
                            }
                            case 'Start Time': {
                                start_time = cell.value as Date;
                                if (isDate(start_time)) {
                                    filled_cells++;
                                } else {
                                    this.markErrorCell(
                                        rowIndex,
                                        cellIndex,
                                        'Incorrect Time format',
                                    );
                                }
                                break;
                            }
                            case 'End Date': {
                                end_date = cell.value as Date;
                                if (isDate(end_date)) {
                                    if (end === undefined || end_date > end) {
                                        end = end_date;
                                    }
                                    filled_cells++;
                                } else {
                                    this.markErrorCell(
                                        rowIndex,
                                        cellIndex,
                                        'Incorrect Date format',
                                    );
                                }
                                break;
                            }
                            case 'End Time': {
                                end_time = cell.value as Date;
                                if (isDate(end_time)) {
                                    filled_cells++;
                                } else {
                                    this.markErrorCell(
                                        rowIndex,
                                        cellIndex,
                                        'Incorrect Time format',
                                    );
                                }
                                break;
                            }
                            case 'Blocked Time Name': {
                                blocked_time_name = cell.text;
                                if (
                                    this.instructorBlockedTimeTypeList.has(
                                        blocked_time_name,
                                    )
                                ) {
                                    filled_cells++;
                                } else {
                                    this.markErrorCell(
                                        rowIndex,
                                        cellIndex,
                                        'Incorrect Blocked Time Name',
                                    );
                                }
                                break;
                            }
                            default: {
                                if (
                                    keys[cellIndex].indexOf(
                                        'Upload to GRIMSBY Date by RM',
                                    ) >= 0
                                ) {
                                    // convert Date to string without time
                                    notes.push(
                                        getFormattedDateTime(
                                            new Date(cell.value),
                                        ),
                                    );
                                } else if (
                                    noteHeaders.indexOf(keys[cellIndex]) >= 0 &&
                                    cell.text !== 'Not Applicable'
                                ) {
                                    // save notes
                                    notes.push(cell.text);
                                }
                                break;
                            }
                        }
                    }
                });
                if (filled_cells === 6) {
                    const blockedTime = {
                        row_number: rowIndex,
                        start_datetime: getFormattedDateTime(
                            new Date(start_date),
                            new Date(start_time),
                        ),
                        end_datetime: getFormattedDateTime(
                            new Date(end_date),
                            new Date(end_time),
                        ),
                        blocked_time_name: blocked_time_name,
                        blocked_time_notes: notes.join('. '),
                    };
                    if (
                        instructorsMap[instructor_email] === null ||
                        instructorsMap[instructor_email] === undefined
                    ) {
                        // initialize
                        instructorsMap[instructor_email] = counter++;
                        this.instructorBlockedTimes.instructors_blocked_times.push(
                            {
                                instructor_email: instructor_email,
                                blocked_times: new Array<BlockedTimeDict>(),
                            },
                        );
                    }
                    this.instructorBlockedTimes.instructors_blocked_times[
                        instructorsMap[instructor_email]
                    ].blocked_times.push(blockedTime);
                } else {
                    this.skippedRows.push(rowIndex);
                }
            }
        });
        this.instructorBlockedTimes.start_date = getFormattedDateTime(
            new Date(start),
        );
        this.instructorBlockedTimes.end_date = getFormattedDateTime(
            new Date(end),
        );
        return this;
    }

    private validateHeaders(row) {
        const headerRow = row.values.filter((x) => x !== undefined);
        var file_headers = [];
        for (let i = 0; i < Number(headerRow.length); i++) {
            const cellValue = headerRow[i];
            if (cellValue) {
                file_headers.push(cellValue);
            }
        }
        // check if we have all required columns
        for (let i = 0; i < Number(headers.length); i++) {
            this.headerValidations.push(file_headers.indexOf(headers[i]) >= 0);
        }
    }
}
