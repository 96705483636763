import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';
import { CourseItemData } from '../../../common/interfaces/businessDataItem/courseItem';
import { DeliveryCountryItemData } from '../../../common/interfaces/businessDataItem/deliveryCountryItem';
import { DeliveryLanguageItemData } from '../../../common/interfaces/businessDataItem/deliveryLanguageItem';
import { GeographyItemData } from '../../../common/interfaces/businessDataItem/geographyItem';
import { InstructorCourseStatusItemData } from '../../../common/interfaces/businessDataItem/instructorCourseStatusItem';
import { InstructorTypeItemData } from '../../../common/interfaces/businessDataItem/instructorTypeItem';
import { LocationItemData } from '../../../common/interfaces/businessDataItem/locationItem';
import { ProgramTypeItemData } from '../../../common/interfaces/businessDataItem/programTypeItem';
import { RegionItemData } from '../../../common/interfaces/businessDataItem/regionItem';
import { InstructorPropertyFilterKeys } from '../../enums/propertyFilterKeys';
import { ScheduleManagementAPIQueryParams } from '../../interfaces/queryParams';

export type DeliveryFacilityType =
    | 'AWS Office'
    | 'Vendor'
    | 'Customer'
    | 'Sponsored Venue';

export const deliveryFacilityTypeList: Array<DeliveryFacilityType> = [
    'AWS Office',
    'Vendor',
    'Customer',
    'Sponsored Venue',
];

export enum InstructorPropertyFilterLabels {
    Availability = 'Availability',
    CourseName = 'Course Name',
    CourseStatus = 'Course Status',
    DeliveryCountry = 'Delivery Country',
    DeliveryGeo = 'Delivery Geo',
    DeliveryLanguage = 'Delivery Language',
    DeliveryRegion = 'Delivery Region',
    DeliveryTimezone = 'Delivery Time zone',
    Freelancer = 'Freelancer',
    InstructorType = 'Instructor Type',
    Manager = 'Manager',
    ProgramType = 'Program',
    WillingToTravel = 'Willing To Travel',
    InstructorStatus = 'Instructor Status',
}

export const instructorFilteringPropertyLabelLookup = {
    [InstructorPropertyFilterKeys.Availability]:
        InstructorPropertyFilterLabels.Availability,
    [InstructorPropertyFilterKeys.DeliveryGeo]:
        InstructorPropertyFilterLabels.DeliveryGeo,
    [InstructorPropertyFilterKeys.DeliveryRegion]:
        InstructorPropertyFilterLabels.DeliveryRegion,
    [InstructorPropertyFilterKeys.InstructorType]:
        InstructorPropertyFilterLabels.InstructorType,
    [InstructorPropertyFilterKeys.ProgramType]:
        InstructorPropertyFilterLabels.ProgramType,
    [InstructorPropertyFilterKeys.DeliveryLanguage]:
        InstructorPropertyFilterLabels.DeliveryLanguage,
    [InstructorPropertyFilterKeys.DeliveryCountry]:
        InstructorPropertyFilterLabels.DeliveryCountry,
    [InstructorPropertyFilterKeys.CourseName]:
        InstructorPropertyFilterLabels.CourseName,
    [InstructorPropertyFilterKeys.CourseStatus]:
        InstructorPropertyFilterLabels.CourseStatus,
    [InstructorPropertyFilterKeys.DeliveryTimezone]:
        InstructorPropertyFilterLabels.DeliveryTimezone,
    [InstructorPropertyFilterKeys.Freelancer]:
        InstructorPropertyFilterLabels.Freelancer,
    [InstructorPropertyFilterKeys.WillingToTravel]:
        InstructorPropertyFilterLabels.WillingToTravel,
    [InstructorPropertyFilterKeys.Manager]:
        InstructorPropertyFilterLabels.Manager,
    [InstructorPropertyFilterKeys.InstructorStatus]:
        InstructorPropertyFilterLabels.InstructorStatus,
};

export const instructorFilteringProperties: ReadonlyArray<PropertyFilterProps.FilteringProperty> =
    (() => {
        const properties: Array<PropertyFilterProps.FilteringProperty> = [];
        for (const key of Object.values(InstructorPropertyFilterKeys)) {
            properties.push({
                key,
                propertyLabel: instructorFilteringPropertyLabelLookup[key],
                groupValuesLabel: instructorFilteringPropertyLabelLookup[key],
            });
        }

        return properties;
    })();

export const getInstructorFilteringProperties = (
    omitProperties?: Array<string>,
) => {
    const properties: Array<PropertyFilterProps.FilteringProperty> = [];
    for (const key of Object.values(InstructorPropertyFilterKeys)) {
        if (!omitProperties || !omitProperties.includes(key)) {
            properties.push({
                key,
                propertyLabel: instructorFilteringPropertyLabelLookup[key],
                groupValuesLabel: instructorFilteringPropertyLabelLookup[key],
            });
        }
    }

    return properties;
};

export const instructorFilterOptionLookup: any = {
    [InstructorPropertyFilterKeys.Freelancer]: {
        'Show only freelancers': 'Yes',
        "Don't include freelancers": 'No',
    },
    [InstructorPropertyFilterKeys.Availability]: {
        Available: 'available',
    },
    [InstructorPropertyFilterKeys.InstructorStatus]: {
        Authorized: 'Authorized',
        Pending: 'Pending',
    },
};

export const getFiltersFromQuery = (query: PropertyFilterProps.Query) =>
    query.tokens.reduce((acc, token) => {
        if ('propertyKey' in token) {
            const key = token.propertyKey as InstructorPropertyFilterKeys;
            if (key === InstructorPropertyFilterKeys.Freelancer) {
                acc[key] =
                    instructorFilterOptionLookup[
                        InstructorPropertyFilterKeys.Freelancer
                    ][token.value];
            } else if (key === InstructorPropertyFilterKeys.Availability) {
                acc[key] =
                    instructorFilterOptionLookup[
                        InstructorPropertyFilterKeys.Availability
                    ][token.value];
            } else if (key === InstructorPropertyFilterKeys.WillingToTravel) {
                acc[key] = token.value;
            } else {
                acc[key] = acc[key] ? acc[key] : [];
                acc[key]?.push(token.value);
            }
        } else {
            acc.search_text = `${acc.search_text || ''}${
                acc.search_text ? ',' : ''
            }${token.value}`;
        }
        return acc;
    }, {} as Omit<ScheduleManagementAPIQueryParams.GetRecommendedInstructors, 'id' | 'delivery_sessions'>);

type InstructorFilteringOptionsProps = {
    courseList: CourseItemData[];
    deliveryCountryList: DeliveryCountryItemData[];
    locationList: LocationItemData[];
    deliveryLanguageList: DeliveryLanguageItemData[];
    instructorTypeList: InstructorTypeItemData[];
    programTypeList: ProgramTypeItemData[];
    geographyList: GeographyItemData[];
    regionList: RegionItemData[];
    instructorCourseStatusList: InstructorCourseStatusItemData[];
    availability?: [];
    freelancer?: [];
    willing_to_travel?: [];
    omitFilters?: Array<string>;
    timezonesList?: Array<string>;
};

export const filteringOptions = ({
    courseList,
    deliveryLanguageList,
    deliveryCountryList,
    instructorTypeList,
    programTypeList,
    geographyList,
    regionList,
    instructorCourseStatusList,
    omitFilters,
    timezonesList,
}: InstructorFilteringOptionsProps) => {
    return (() => {
        let businessDataLookup: {
            [key in InstructorPropertyFilterKeys]: Array<string>;
        } = {
            [InstructorPropertyFilterKeys.InstructorType]:
                instructorTypeList.map(
                    (instructorType) => instructorType.instructor_type,
                ),
            [InstructorPropertyFilterKeys.ProgramType]: programTypeList.map(
                (program) => program.program_type,
            ),
            [InstructorPropertyFilterKeys.DeliveryLanguage]:
                deliveryLanguageList.map(
                    (deliveryLanguage) => deliveryLanguage.delivery_language,
                ),
            [InstructorPropertyFilterKeys.DeliveryGeo]: geographyList.map(
                (geogragphy) => geogragphy.geo,
            ),
            [InstructorPropertyFilterKeys.DeliveryRegion]: regionList.map(
                (region) => region.region,
            ),
            [InstructorPropertyFilterKeys.DeliveryCountry]:
                deliveryCountryList.map(
                    (deliveryCountry) => deliveryCountry.delivery_country,
                ),
            [InstructorPropertyFilterKeys.CourseName]: courseList.map(
                (course) => course.course,
            ),
            [InstructorPropertyFilterKeys.CourseStatus]:
                instructorCourseStatusList.map(
                    (courseStatus) => courseStatus.instructor_course_status,
                ),
            [InstructorPropertyFilterKeys.DeliveryTimezone]:
                timezonesList ?? [],
            [InstructorPropertyFilterKeys.Availability]: Object.keys(
                instructorFilterOptionLookup[
                    InstructorPropertyFilterKeys.Availability
                ],
            ),
            [InstructorPropertyFilterKeys.Freelancer]: Object.keys(
                instructorFilterOptionLookup[
                    InstructorPropertyFilterKeys.Freelancer
                ],
            ),
            [InstructorPropertyFilterKeys.WillingToTravel]: ['Yes', 'No'],
            [InstructorPropertyFilterKeys.Manager]: [],
            [InstructorPropertyFilterKeys.InstructorStatus]: Object.keys(
                instructorFilterOptionLookup[
                    InstructorPropertyFilterKeys.InstructorStatus
                ],
            ),
        };
        let options: Array<PropertyFilterProps.FilteringOption> = [];

        for (const [key, stringList] of Object.entries(businessDataLookup)) {
            if (!omitFilters || !omitFilters.includes(key)) {
                options = [
                    ...options,
                    ...stringList.map((value) => ({
                        propertyKey: key,
                        value,
                    })),
                ];
            }
        }

        return options;
    })();
};
