export enum PropertyFilterKeys {
    AdditionalOwners = 'additional_owner_name',
    Audience = 'activity_audience',
    BillingStatus = 'billing_status',
    Status = 'activity_status',
    Type = 'activity_type',
    UnassignedInstructorRole = 'unassigned_instructor_role',
    AssignedInstructorRole = 'assigned_instructor_role',
    Geography = 'geo',
    Region = 'delivery_region',
    City = 'delivery_city',
    Country = 'delivery_country',
    Atp = 'sponsoring_company_name',
    DeliveryLanguage = 'delivery_language',
    Course = 'course_name',
    CourseStatus = 'course_status',
    CustomerName = 'customer_name',
    Certifications = 'certifications',
    Freelancer = 'is_freelancer',
    Program = 'program',
    InstructorName = 'instructor_name',
    InstructorType = 'instructor_type',
    LMSLocatorID = 'lms_id',
    Modality = 'activity_modality',
    OperationsOwner = 'operations_owner',
    Scheduler = 'scheduler',
    CustomerSuccessManager = 'customer_support_manager',
    Providers = 'provider',
    SalesGeo = 'sales_geo',
    SalesRegion = 'sales_region',
    SFDCOportunityID = 'sfdc_opportunity_id',
    SIMID = 'class_request_sim',
    TOFStatus = 'tof_status',
    AWS_MANAGER = 'amazon_manager_name',
    Requestor = 'requestor',
    // PartnerInitiative = 'partner_initiative' need to add this to backend to support filtering
}

export enum PropertyFilterLabels {
    AdditionalOwners = 'additional_owners',
    Audience = 'Audience',
    Status = 'Status',
    Type = 'Type',
    UnassignedInstructorRole = 'Unassigned Instructor Role',
    AssignedInstructorRole = 'Assigned Instructor Role',
    Geography = 'Geo',
    Region = 'Region',
    City = 'City',
    Country = 'Country',
    Providers = 'Provider',
    DeliveryCountries = 'Delivery countries',
    DeliveryLanguage = 'Delivery language',
    Course = 'Course',
    CustomerName = 'Customer name',
    InstructorName = 'Instructor name',
    LMSLocatorID = 'LMS locator ID',
    Program = 'Program',
    Modality = 'Modality',
    OperationsOwner = 'Operations owner',
    SalesGeo = 'Sales geo',
    SalesRegion = 'Sales region',
    SFDCOportunityID = 'SFDC opportunity ID',
    SIMID = 'SIM ID',
    BillingStatus = 'Billing status',
    TOFStatus = 'TOF status',
    Requestor = 'Requestor',
}

export enum InstructorPropertyFilterKeys {
    Availability = 'availability',
    CourseName = 'course_name',
    CourseStatus = 'course_status',
    DeliveryCountry = 'delivery_country',
    DeliveryGeo = 'delivery_geo',
    DeliveryLanguage = 'delivery_language',
    DeliveryRegion = 'delivery_region',
    DeliveryTimezone = 'delivery_timezone',
    Freelancer = 'is_freelancer',
    InstructorType = 'instructor_type',
    Manager = 'amazon_manager_name',
    ProgramType = 'program',
    WillingToTravel = 'willing_to_travel',
    InstructorStatus = 'instructor_status',
}
