import React, { useCallback, useState, useEffect } from 'react';
import { ActivityData, Invoice } from '../../../../interfaces/activity';
import {
    getSelectedActivity,
    selectIsLoading,
    selectSelectedActivity,
    updateSelectedActivity,
} from '../../../../store/slices/selectedActivitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';

import { InvoiceFormSection } from './InvoiceFormSection';
import useFormValidation from '../../../../../common/utils/formValidation';
import {
    saveInvoiceValidationConfig,
    saveInvoiceValidationNonDraftConfig,
} from '../../../../services/activity-service';

const InvoiceCreateForm = () => {
    const isLoading = useSelector(selectIsLoading);
    const match = useRouteMatch<{ id: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const activity: ActivityData | undefined = useSelector(
        selectSelectedActivity,
    )?.activity;
    const { addNotification, removeAllNotifications } = useNotifications();
    const initialFormState = {
        billing_status: 'Draft',
        currency: 'USD',
        do_not_group: false,
        payment_terms: '30 NET',
        is_reseller: false,
    } as Invoice;

    const [formValues, setFormValues] = useState<Invoice>(initialFormState);

    const { errors, validateForm } = useFormValidation<Invoice>();

    const handleFieldEvent = useCallback((changes: Partial<Invoice>) => {
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    }, []);

    useEffect(() => {
        dispatch(getSelectedActivity(match.params.id));
    }, [dispatch, match.params.id]);

    useEffect(() => {
        removeAllNotifications();
    }, [removeAllNotifications]);

    const handleCreateInvoice = async () => {
        if (!!activity) {
            const invalid = validateForm(
                formValues,
                formValues.billing_status === 'Draft'
                    ? saveInvoiceValidationConfig
                    : saveInvoiceValidationNonDraftConfig(formValues.is_reseller),
            );

            if (!invalid) {
                let updatedInvoices: Array<Invoice> = [];
                let totalAmount = 0;

                if (formValues.line_items) {
                    for (let lineItem of formValues.line_items) {
                        totalAmount += lineItem.item_amount;
                    }
                }

                const newInvoice: Invoice = {
                    ...formValues,
                    ...{
                        customer_name: formValues.customer_name.replace(` (${formValues.sfdc_opportunity_id})`,''),
                        legal_entity_name: formValues.customer_name.replace(` (${formValues.sfdc_opportunity_id})`,''),
                        billed_amount: totalAmount,
                    },
                };

                if (!activity.billing_invoices) {
                    updatedInvoices = [newInvoice];
                } else {
                    updatedInvoices = [
                        ...activity.billing_invoices,
                        ...[newInvoice],
                    ];
                }

                const isSuccessful = await dispatch<any>(
                    updateSelectedActivity(match.params.id, {
                        ...activity,
                        billing_invoices: updatedInvoices,
                    }),
                );

                addNotification({
                    id: `edit-activity-invoice-${Date.now()}`,
                    ...(isSuccessful
                        ? {
                              type: 'success',
                              content: 'The invoice has been saved.',
                          }
                        : {
                              type: 'error',
                              content:
                                  'An error occurred while saving the invoice.',
                          }),
                });

                if (isSuccessful) {
                    history.push({
                        pathname: `/activities/${match.params.id}`,
                        search: 'tabId=invoices',
                    });
                }
            }
        }
        return;
    };

    return (
        <section data-testid="InvoiceCreateForm">
            <Form
                header="Add invoice"
                actions={
                    <div className="awsui-util-action-stripe awsui-util-mb-m">
                        <div className="awsui-util-action-stripe-group">
                            <Button
                                variant="link"
                                className="admin-invoice-cancel"
                                data-testid="InvoiceCreateFormCancel"
                                onClick={() => {
                                    history.push({
                                        pathname: `/activities/${match.params.id}`,
                                        search: 'tabId=invoices',
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="admin-invoice-save"
                                data-testid="InvoiceCreateFormAddButton"
                                loading={isLoading}
                                onClick={() => {
                                    handleCreateInvoice();
                                }}
                            >
                                {`${isLoading ? 'Saving' : 'Save'}`}
                            </Button>
                        </div>
                    </div>
                }
            >
                <InvoiceFormSection
                    formValues={formValues}
                    errors={errors}
                    handleFieldEvent={handleFieldEvent}
                    activity={activity}
                />
            </Form>
        </section>
    );
};

export default InvoiceCreateForm;
