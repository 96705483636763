import React from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    ColumnLayout,
    FormField,
    Select,
    Input,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';

interface ActivityCreateAutoAssignInstructorOptOutModalProps {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    setOptOutReason: any;
    optOutReason: OptionDefinition | null;
    setOptOutReasonExplanation: any;
    optOutReasonExplanation: string;
}

const CONFIRM_OPT_OUT_DESCRIPTION_TEXT =
    'Please provide a reason for opting out of instructor auto-assignment.';

const isOptOutReasonExplanationInvalid = (optOutReason: string | null, optOutReasonExplanation: string) => {
    const optOutReasonExplanationLength = optOutReasonExplanation.trim().length
    return optOutReason === "Other" && (optOutReasonExplanationLength < 1 || optOutReasonExplanationLength > 200)
};

const ActivityCreateAutoAssignInstructorOptOutModalProps = ({
    onCancel,
    onConfirm,
    visible,
    setOptOutReason,
    optOutReason,
    setOptOutReasonExplanation,
    optOutReasonExplanation,
}: ActivityCreateAutoAssignInstructorOptOutModalProps) => {
    const [optOutReasonError, setOptOutReasonError] = React.useState<boolean>(false);
    const [optOutReasonExplanationError, setOptOutReasonExplanationError] = React.useState<boolean>(false);
    const optOutReasons = [
        {
            label: 'Dedicated Virtual Trainer Program',
            value: 'Dedicated Virtual Trainer Program',
        },
        {
            label: 'Non-Standard Delivery - Class Timing',
            value: 'Non-Standard Delivery - Class Timing',
        },
        {
            label: 'Non-Standard Delivery - Custom Content',
            value: 'Non-Standard Delivery - Custom Content',
        },
        {
            label: 'Tech-U',
            value: 'Tech-U',
        },
        {
            label: 'Padawan',
            value: 'Padawan',
        },
        {
            label: 'TDM Requested Specific Trainer',
            value: 'TDM Requested Specific Trainer',
        },
        {
            label: 'Other',
            value: 'Other',
        },
    ];

    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmOptOutModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmCreateActivityCancel`}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmCreateActivityConfirm`}
                            onClick={() => {
                                const optOutReasonError = !optOutReason;
                                const optOutReasonExplanationError = isOptOutReasonExplanationInvalid(optOutReason?.label, optOutReasonExplanation);
                                if (optOutReasonError) {
                                    setOptOutReasonError(true);
                                }
                                if(optOutReasonExplanationError){
                                    setOptOutReasonExplanationError(true);
                                }
                                if(!optOutReasonError && !optOutReasonExplanationError) {
                                    onConfirm();
                                }
                            }}
                        >
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Confirm opt out auto assign instructor`}
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        label="Opt out reason"
                        description={CONFIRM_OPT_OUT_DESCRIPTION_TEXT}
                        className="optout-reason-form-field"
                        errorText={
                            optOutReasonError
                                ? 'You must select a reason for opting out of auto-assigning an instructor.'
                                : null
                        }
                    >
                        <Select
                            placeholder={`Select an opt out reason`}
                            options={optOutReasons}
                            selectedOption={optOutReason}
                            onChange={(e) => {
                                setOptOutReasonError(false);
                                setOptOutReason(e?.detail?.selectedOption);
                            }}
                            loadingText="Loading opt out reasons"
                            errorText="An error occurred while loading opt out reasons"
                            recoveryText="Retry"
                            data-testid={`OptOutAutoAssignInstructorReason`}
                        />
                    </FormField>
                    <>
                        {optOutReason?.label === "Other" &&
                            <FormField
                                label="Provide more details"
                                errorText={optOutReasonExplanationError ? 'Required.' : null}
                            >
                                <Input
                                    inputMode="text"
                                    value={optOutReasonExplanation}
                                    onChange={(e) => {
                                        setOptOutReasonExplanationError(false);
                                        setOptOutReasonExplanation(e?.detail?.value);
                                    }}
                                    data-testid={`OptOutAutoAssignInstructorReasonExplanation`}
                                />
                            </FormField>
                        }
                    </>
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default ActivityCreateAutoAssignInstructorOptOutModalProps;
