import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Autosuggest,
    Button,
    CustomDetailEvent,
    Form,
    FormField,
    Input,
    Modal,
    Multiselect,
    RadioGroup,
    Select,
    Spinner,
} from '@amzn/awsui-components-react';
import { InitialFormState } from './AdminBusinessData';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
    AdminBusinessDataFormSchemaAutosuggest,
    AdminBusinessDataFormSchemaItem,
    AdminBusinessDataFormSchemaMultiselect,
    AdminBusinessDataFormSchemaNumber,
    AdminBusinessDataFormSchemaSelect,
} from '../../interfaces/adminBusinessDataFormSchema';

export interface FormModalProps<BusinessDataType> {
    formElement?: JSX.Element;
    formSchema?: AdminBusinessDataFormSchema<BusinessDataType>;
    initialFormState: BusinessDataType;
    itemDisplayNameSingular: string;
    itemNameKey: string;
    isModalOpen: boolean;
    loading: boolean;
    mode: 'Create' | 'Update';
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    saveItem: (data: BusinessDataType) => Promise<void>;
    customValidator?: (data: BusinessDataType) => Promise<void | any>;
}

export interface ErrorState {
    [key: string]: string;
}

const statusRadioItems: Array<RadioGroup.RadioButtonDefinition> = [
    {
        value: 'true',
        label: 'Active',
    },
    {
        value: 'false',
        label: 'Inactive',
    },
];

export const YesNoRadioItems: Array<RadioGroup.RadioButtonDefinition> = [
    {
        value: 'true',
        label: 'Yes',
    },
    {
        value: 'false',
        label: 'No',
    },
];


const DEFAULT_DECIMAL_PRECISION = 2;

const FormModal = <BusinessDataType extends InitialFormState>({
    formElement,
    formSchema,
    initialFormState,
    itemDisplayNameSingular,
    itemNameKey,
    isModalOpen,
    loading,
    mode,
    saveItem,
    setIsModalOpen,
    customValidator,
}: PropsWithChildren<FormModalProps<BusinessDataType>>) => {
    const [formItem, setFormItem] = useState(
        initialFormState as BusinessDataType,
    );
    const [isInvalid, setIsInvalid] = useState(false);
    const [errors, setErrors] = useState({} as ErrorState);
    const validateForm = useCallback(
        (formItem: Partial<BusinessDataType>) => {
            if (!formSchema) {
                return false;
            }
            const errors: ErrorState = {};
            let isInvalid = false;
            Object.entries(formSchema).forEach(([key, value]) => {
                // don't run validation on 'active' radio button because
                // it will always be submitted with a value of either true or false
                // and we don't need to set error on `false` value
                if (
                    value.required &&
                    key !== 'active' &&
                    (formItem[key]?.length === 0 || !formItem[key])
                ) {
                    if (!isInvalid) {
                        isInvalid = true;
                    }
                    errors[key] = 'Required.';
                }

                // validation for number type with step
                if (
                    value.type === AdminBusinessDataFormInputType.Number &&
                    !!formItem[key]
                ) {
                    let invalidNumber = false;
                    const numValue = parseFloat(formItem[key] ?? '');
                    let errorStr = '';

                    if (!isNaN(numValue)) {
                        const numWithPrecision = value.precision
                            ? parseFloat(numValue.toFixed(value.precision))
                            : parseFloat(
                                  numValue.toFixed(DEFAULT_DECIMAL_PRECISION),
                              );

                        const isLessThanMin =
                            typeof value.min !== 'undefined'
                                ? numWithPrecision < value.min
                                : false;

                        const isInvalidStep =
                            typeof value.step !== 'undefined'
                                ? numWithPrecision % value.step !== 0
                                : false;

                        invalidNumber = isLessThanMin || isInvalidStep;

                        errorStr = isLessThanMin
                            ? `Should be greater than ${value.min}`
                            : `Increment only by ${value.step}`;
                    }

                    if (invalidNumber) {
                        if (!isInvalid) {
                            isInvalid = true;
                        }

                        errors[key] = errorStr;
                    }
                }
            });
            setErrors(errors);
            return isInvalid;
        },
        [formSchema],
    );

    useEffect(() => {
        setFormItem(initialFormState);
        const errors: ErrorState = {};
        //clear errors before opening up modal
        setErrors(errors);
        setIsInvalid(false);
    }, [initialFormState, setFormItem]);

    useEffect(() => {
        if (isInvalid) {
            setIsInvalid(validateForm(formItem));
        }
    }, [formItem, isInvalid, validateForm, setIsInvalid]);

    const handleFieldEvent = (changes: Partial<BusinessDataType>) => {
        setFormItem((values) => ({ ...values, ...changes }));
    };

    const getFormElement = (
        elementName: string,
        elementSchema:
            | AdminBusinessDataFormSchemaItem<BusinessDataType>
            | AdminBusinessDataFormSchemaMultiselect<BusinessDataType>
            | AdminBusinessDataFormSchemaSelect<BusinessDataType>
            | AdminBusinessDataFormSchemaNumber<BusinessDataType>
            | AdminBusinessDataFormSchemaAutosuggest<BusinessDataType>,
    ) => {
        const label = (
            <span>
                {elementSchema.label}
                {!elementSchema.required && <i> - optional</i>}
            </span>
        );
        let formInput;
        switch (elementSchema.type) {
            case AdminBusinessDataFormInputType.StatusRadio:
                formInput = (
                    <RadioGroup
                        value={`${formItem[elementName]}`}
                        items={statusRadioItems}
                        onChange={(
                            e: CustomDetailEvent<RadioGroup.ChangeDetail>,
                        ) => {
                            const val = elementSchema.formDataTransform(
                                e.detail.value,
                            );
                            handleFieldEvent(val);
                        }}
                        data-testid={`AdminBusinessData${mode}-${itemNameKey}-${elementName}-RadioGrp`}
                        ariaRequired={true}
                    />
                );
                break;
            case AdminBusinessDataFormInputType.YesNoRadio:
                formInput = (
                    <RadioGroup
                        value={`${formItem[elementName]}`}
                        items={YesNoRadioItems}
                        onChange={(
                            e: CustomDetailEvent<RadioGroup.ChangeDetail>,
                        ) => {
                            const val = elementSchema.formDataTransform(
                                e.detail.value,
                            );
                            handleFieldEvent(val);
                        }}
                        data-testid={`AdminBusinessData${mode}-${itemNameKey}-${elementName}-YesNoRadioGrp`}
                        ariaRequired={true}
                    />
                );
                break;
            case AdminBusinessDataFormInputType.Multiselect:
                formInput = (
                    <Multiselect
                        placeholder={elementSchema.placeholder}
                        filteringType="auto"
                        options={elementSchema.options}
                        selectedOptions={formItem[elementName].map(
                            (selection: string) =>
                                elementSchema.lookup[selection],
                        )}
                        data-testid={`AdminBusinessData${mode}-${itemNameKey}-${elementName}-MultiSelect`}
                        onChange={(
                            e: CustomDetailEvent<
                                Select.MultiselectChangeDetail
                            >,
                        ) => {
                            handleFieldEvent(
                                elementSchema.formDataTransform(
                                    e.detail.selectedOptions,
                                ),
                            );
                        }}
                        ariaRequired={elementSchema.required}
                        keepOpen={false}
                        checkboxes={true}
                        invalid={!!errors[elementName]}
                        disabled={elementSchema.disabled}
                    />
                );
                break;
            case AdminBusinessDataFormInputType.Select:
                formInput = (
                    <Select
                        placeholder={elementSchema.placeholder}
                        filteringType={
                            elementSchema.filteringType === 'manual'
                                ? 'manual'
                                : 'auto'
                        }
                        options={elementSchema.options}
                        selectedOption={
                            elementSchema.lookup[formItem[elementName]]
                        }
                        data-testid={`AdminBusinessData${mode}-${itemNameKey}-${elementName}-Select`}
                        onChange={(
                            e: CustomDetailEvent<Select.ChangeDetail>,
                        ) => {
                            handleFieldEvent(
                                elementSchema.formDataTransform(
                                    e.detail.selectedOption,
                                ),
                            );
                        }}
                        ariaRequired={elementSchema.required}
                        invalid={!!errors[elementName]}
                        empty={'No results...'}
                        disabled={elementSchema.disabled}
                        onDelayedFilteringChange={
                            elementSchema.onDelayedFilteringChange
                                ? elementSchema.onDelayedFilteringChange
                                : () => {}
                        }
                    />
                );
                break;
            case AdminBusinessDataFormInputType.Autosuggest:
                formInput = (
                    <Autosuggest
                        placeholder={elementSchema.placeholder}
                        filteringType="auto"
                        options={elementSchema.options}
                        data-testid={`AdminBusinessData${mode}-${itemNameKey}-${elementName}-Autosuggest`}
                        onChange={(
                            e: CustomDetailEvent<Autosuggest.ChangeDetail>,
                        ) => {
                            handleFieldEvent(
                                elementSchema.formDataTransform(
                                    e.detail.selectedOption ?? e.detail.value,
                                ),
                            );
                        }}
                        empty={`No results...`}
                        value={`${formItem[elementName]}`}
                        ariaRequired={elementSchema.required}
                        invalid={!!errors[elementName]}
                        disabled={elementSchema.disabled}
                    />
                );
                break;
            default:
                formInput = (
                    <Input
                        name={elementName}
                        value={
                            formItem[elementName]
                                ? `${formItem[elementName]}`
                                : null
                        }
                        onChange={(
                            e: CustomDetailEvent<Input.ChangeDetail>,
                        ) => {
                            handleFieldEvent(
                                elementSchema.formDataTransform(e.detail.value),
                            );
                        }}
                        data-testid={`AdminBusinessData${mode}-${itemNameKey}-${elementName}-Input`}
                        type={elementSchema.type}
                        ariaRequired={elementSchema.required}
                        disabled={elementSchema.disabled}
                    />
                );
        }

        return (
            <div className="awsui-row awsui-util-spacing-v-s" key={elementName}>
                <div className="col-12">
                    <FormField
                        data-testid={`AdminBusinessData${mode}-${itemNameKey}-${elementName}-FormField`}
                        label={label}
                        stretch={true}
                        errorText={errors[elementName]}
                    >
                        {formInput}
                    </FormField>
                </div>
            </div>
        );
    };

    return (
        // aria-hidden/hidden are required for testing-library to not see the modal as visible
        <div aria-hidden={!isModalOpen} hidden={!isModalOpen}>
            <Modal
                visible={isModalOpen}
                header={`${mode} ${itemDisplayNameSingular}`}
                closeLabel={`Close Business Data ${itemDisplayNameSingular} ${mode} Modal`}
                onDismiss={() => {
                    // TO DO: add handler to set selected item to null on dismiss/cancel
                    setIsModalOpen(false);
                }}
                data-testid={`AdminBusinessData${mode}-${itemNameKey}`}
                expandToFit={true}
            >
                {loading ? (
                    <Spinner
                        data-testid={`AdminBusinessData${mode}-${itemNameKey}-Spinner`}
                    />
                ) : (
                    <>
                        {!!formElement ? (
                            formElement
                        ) : (
                            <Form
                                actions={
                                    <div className="awsui-util-pt-xl">
                                        <Button
                                            variant="link"
                                            data-testid={`AdminBusinessData${mode}-${itemNameKey}-CancelBtn`}
                                            onClick={() => {
                                                // TO DO: add handler to set selected item to null on dismiss/cancel
                                                setIsModalOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="primary"
                                            data-testid={`AdminBusinessData${mode}-${itemNameKey}-SubmitBtn`}
                                            formAction="submit"
                                            onClick={async () => {
                                                const isInvalid = validateForm(
                                                    formItem,
                                                );
                                                if (customValidator) {
                                                    const customErrors = await customValidator(
                                                        formItem,
                                                    );

                                                    if (customErrors) {
                                                        setErrors(customErrors);
                                                        return;
                                                    }
                                                }
                                                if (!isInvalid) {
                                                    setIsModalOpen(false);
                                                    await saveItem(formItem);
                                                }
                                                setIsInvalid(isInvalid);
                                                return;
                                            }}
                                        >
                                            {mode === 'Update' ? 'Save' : 'Add'}
                                        </Button>
                                    </div>
                                }
                            >
                                <div className="awsui-grid">
                                    {formItem &&
                                        isModalOpen &&
                                        formSchema &&
                                        Object.keys(formSchema).map((key) =>
                                            getFormElement(
                                                key,
                                                formSchema[key],
                                            ),
                                        )}
                                </div>
                            </Form>
                        )}
                    </>
                )}
            </Modal>
        </div>
    );
};

export default FormModal;
