import { Multiselect, Select, Autosuggest } from '@amzn/awsui-components-react';
import {
    generateSelectedOptions,
    getOptionsAndLookupForSelectInput,
    getOptionsAndLookupForAutosuggestInput,
} from '../FormSections/FormSections.common';

export interface CourseAttributeEditorItem {
    nameValue: string | null;
    pk: string | null;
    statusOption: Select.Option | null;
    regionalLeadValue: boolean | null;
    geoLeadValue: boolean | null;
    coTeach: number | null;
    shadow: number | null;
}

export interface CourseAttributeEditorItemError {
    name: string | null;
    status: string | null;
}

export interface BusinessDataReactState {
    status: Multiselect.StatusType;
    selectLookup?: {
        [key: string]: Select.Option;
    };
    selectOptions?: Array<Select.Option>;
    selectedOptions?: Array<Select.Option>;
    selectedOptionsInitialized?: boolean;
    nameSet?: Set<string>;
    autosuggestLookup?: {
        [key: string]: Autosuggest.Option;
    };
    autosuggestOptions?: Array<Autosuggest.Option>;
}

export enum ErrorMessage {
    EmptyName = 'Empty course name',
    EmptyStatus = 'Empty course status',
    InvalidName = 'Course name is not valid. Please select one from the list',
    InvalidStatus = 'Course status is not valid. Please select one from the list',
    NameDataNotReady = 'Course name data is not loaded. Please retry to fetch the data',
    StatusDataNotReady = 'Course status data is not loaded. Please retry to fetch the data',
}

export const preprocessForBusinessData = <T>(
    instructorRecord: Array<string> | null | undefined,
    businessDataLoaded: boolean,
    businessDataError: any,
    businessDataList: Array<T>,
    state: BusinessDataReactState,
    handleStateUpdate: (changes: Partial<BusinessDataReactState>) => void,
    getOption: (value: T) => object,
) => {
    if (businessDataLoaded) {
        // if fetching business data has error, mark the status as "error"
        if (businessDataError) {
            if (state.status !== 'error') {
                handleStateUpdate({ status: 'error' });
            }
        }
        // if no error and no initialization, initialize all its corresponding data
        else {
            if (state.status !== 'finished') {
                let newState: Partial<BusinessDataReactState> = {
                    status: 'finished' as Multiselect.StatusType,
                };

                if (
                    state.selectLookup !== undefined &&
                    state.selectOptions !== undefined
                ) {
                    const {
                        valueLookup: selectLookup,
                        valueOptions: selectOptions,
                    } = getOptionsAndLookupForSelectInput<T>(
                        businessDataList,
                        getOption as (value: T) => {
                            label: string;
                            id: string;
                        },
                    );

                    newState.selectLookup = selectLookup;
                    newState.selectOptions = selectOptions;

                    // in case the instructor profile data comes first, initialize selected options
                    if (
                        instructorRecord &&
                        state.selectedOptions !== undefined &&
                        state.selectedOptionsInitialized !== undefined
                    ) {
                        newState.selectedOptionsInitialized = true;
                        newState.selectedOptions = generateSelectedOptions(
                            instructorRecord,
                            selectLookup,
                        );
                    }
                } else if (
                    state.autosuggestLookup !== undefined &&
                    state.autosuggestOptions !== undefined
                ) {
                    const {
                        valueLookup: autosuggestLookup,
                        valueOptions: autosuggestOptions,
                    } = getOptionsAndLookupForAutosuggestInput(
                        businessDataList,
                        getOption as (value: T) => { value: string },
                    );

                    newState.autosuggestLookup = autosuggestLookup;
                    newState.autosuggestOptions = autosuggestOptions;
                    newState.nameSet = new Set(
                        autosuggestOptions.map((option) => option.value),
                    );
                }

                handleStateUpdate(newState);
            }
        }
    } else {
        if (state.status !== 'loading') {
            handleStateUpdate({ status: 'loading' });
        }
    }
};
