import React from 'react';
import {
    Box,
    ColumnLayout,
    Link,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { EMPTY_STRING } from '../../../../../common/constants/grimsby';
import { formatString } from '../../../../../common/utils/formatString';
import { formatStringArray } from '../../../../../common/utils/formatStringArray';
import getPhoneToolUrl from '../../../../../common/utils/getPhoneToolUrl';
import { RecommendedInstructorProfileData } from '../../../../interfaces/recommendedInstructorProfile';
import { formatYesNoBoolean } from '../../../../../imt/components/Instructor/Common/Common';

const ResourceLabelPopover = (profile: RecommendedInstructorProfileData) => {
    const managerEmail = formatString(profile.amazon_manager_email);
    const managerAlias = managerEmail.split('@')[0];
    return (
        <>
            <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="l">
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Approved courses
                        </Box>
                        <div>
                            {!!profile?.courses?.length
                                ? profile.courses
                                      .filter(
                                          (value) =>
                                              value.status === 'Approved',
                                      )
                                      .map((course, index) => (
                                          <Box
                                              key={index}
                                              margin={{ bottom: 'xxs' }}
                                          >
                                              {course.name}
                                          </Box>
                                      ))
                                : EMPTY_STRING}
                        </div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Certifications
                        </Box>
                        <div>
                            {!!profile?.certifications?.length
                                ? profile.certifications.map(
                                      (certifications, index) => (
                                          <Box
                                              key={index}
                                              margin={{ bottom: 'xxs' }}
                                          >
                                              {certifications.name}
                                          </Box>
                                      ),
                                  )
                                : EMPTY_STRING}
                        </div>
                    </div>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Location
                        </Box>
                        <div>
                            {formatStringArray([
                                profile.city,
                                profile.state_province,
                            ])}
                        </div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Time Zone
                        </Box>
                        <div>{profile.city_timezone}</div>
                    </div>
                    {profile?.amazon_manager_name && (
      			        <div>
  	    		            <Box margin={{ bottom: 'xxxs' }} color="text-label">
  		    	                Manager
  			                </Box>
  			                <Link
  			                    data-testid={'ManagerLink'}
  			                    external
      			                externalIconAriaLabel="Opens in a new tab"
  	    		                href={getPhoneToolUrl(managerAlias)}
                                target="_blank"
  			                >
  			                    {profile.amazon_manager_name}
  			                </Link>
      			        </div>
  			        )}
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Willing to travel
                        </Box>
                        <div>{formatYesNoBoolean(profile.prefer_travel)}</div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Delivery Countries
                        </Box>
                        <div>
                            {formatStringArray(profile.delivery_countries)}
                        </div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Languages spoken
                        </Box>
                        <div>
                            {formatStringArray(profile.delivery_languages)}
                        </div>
                    </div>
                    <div>
                        <Box margin={{ bottom: 'xxxs' }} color="text-label">
                            Other skills
                        </Box>
                        <div>                            
                            {(() => {
                                const skills = [
                                    profile.champion ? 'Champion' : null,
                                    profile.mentor ? 'Mentor' : null,
                                    profile.vilt? 'vILT approved': null,
                                    profile.pr_certified? 'PR certified': null,
                                    profile.speaker_certified? 'Speaker certified': null,
                                    profile.domain_skills && profile.domain_skills.length>0? `Domain skills: ${profile.domain_skills.join(',')}`: null,
                                ].filter(Boolean);

                                return !!skills.length
                                    ? skills.map((skill, index) => (
                                          <div key={index}>{skill}</div>
                                      ))
                                    : EMPTY_STRING;
                            })()}
                        </div>
                    </div>
                </SpaceBetween>
            </ColumnLayout>
        </>
    );
};

export default ResourceLabelPopover;
