import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Form } from '@amzn/awsui-components-react';

import {
    setRequireUpdate,
    updateSelectedInstructor,
} from '../../../store/slices/selectedInstructorSlice';
import {
    InstructorProfileData,
    Provider,
} from '../../../interfaces/instructorProfile';
import InstructorDetailsFormSection from '../FormSections/InstructorDetailsFormSection';
import { FORM_ERROR_SELECTOR } from '../FormSections/FormSections.common';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import handleInstructorDetailNotification from '../../../../common/utils/handleInstructorDetailNotification';
import {
    getInstructorProviderValues,
    getIsExternalInstructor,
    InstructorControlArrayFormValues,
    InstructorFormSectionProps,
    InstructorTypeValues,
    ProviderAttributeEditorItem,
    providerValidationConfig,
} from '../Common/Common';
import CancelModal, {
    CancelModalProps,
} from '../../../../common/components/CancelModal/CancelModal';
import { FormSectionMode } from '../../../../common/constants/forms';
import { INSTRUCTOR_VALIDATION_FIELDS } from '../Common/Validations';

const mode = FormSectionMode.Edit;

const EditInstructorDetailsForm = ({
    initialFormState,
}: {
    initialFormState: InstructorProfileData;
}) => {
    const match = useRouteMatch<{ id: string }>();
    const history = useHistory();
    const { addNotification } = useNotifications();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState(initialFormState);
    const {
        isInvalid,
        isControlArrayInvalid,
        errors,
        controlArrayErrors,
        validateForm,
        validateFormControlArray,
    } = useFormValidation<
        Partial<InstructorProfileData>,
        InstructorControlArrayFormValues
    >();
    const { sponsoringCompany, providers } = getInstructorProviderValues(
        formValues.providers || [],
    );
    const [providerAttributeEditorItems, setProviderAttributeEditorItems] =
        useState(
            providers.map((provider) => ({
                awsClassroom: provider.aws_classrooms_email,
                providerName: provider.provider_name,
                pk: provider.pk,
            })) as Array<ProviderAttributeEditorItem>,
        );
    const [sponsoringCompanyItem, setSponsoringCompanyItem] = useState({
        awsClassroom: sponsoringCompany?.aws_classrooms_email,
        providerName: sponsoringCompany?.provider_name,
        pk: sponsoringCompany?.pk,
    } as ProviderAttributeEditorItem);
    const [submitting, setSubmitting] = useState(false);
    const [cancelModalVisible, setCancelModalVisible] = useState(false);

    const handleFieldEvent = (changes: Partial<InstructorProfileData>) =>
        setFormValues((values: InstructorProfileData) => ({
            ...values,
            ...changes,
        }));

    const handleProviderItemEvent = (
        providerItems: Array<ProviderAttributeEditorItem>,
    ) => {
        setProviderAttributeEditorItems(providerItems);
    };

    const handleSponsoringCompanyItemEvent = (
        sponsoringCompanyItem: Partial<ProviderAttributeEditorItem>,
    ) => {
        setSponsoringCompanyItem((previousValue) => ({
            ...previousValue,
            ...sponsoringCompanyItem,
        }));
    };

    const handleScrollToError = () => {
        // this may require attention later for consistent experience in all browsers

        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);
        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const handleSubmitEvent = async () => {
        setSubmitting(true);
        const invalid = validateForm(formValues, getValidationConfig());
        const isExternal = getIsExternalInstructor(
            formValues.instructor_type as InstructorTypeValues,
        );
        const controlArrayInvalid = isExternal
            ? validateFormControlArray(
                  {
                      providerAttributeEditorItems,
                      sponsoringCompanyItems: [sponsoringCompanyItem],
                  },
                  providerValidationConfig,
              )
            : false;

        const isInvalid = invalid || controlArrayInvalid;

        if (isInvalid) {
            setSubmitting(false);
            handleScrollToError();
        } else {
            const instructorData: InstructorProfileData = {
                ...formValues,
                ...(isExternal
                    ? {
                          providers: [
                              ...providerAttributeEditorItems.map(
                                  (providerItem) =>
                                      ({
                                          aws_classrooms_email:
                                              providerItem.awsClassroom,
                                          provider_name:
                                              providerItem.providerName,
                                          is_sponsor_company: false,
                                          pk: providerItem.pk,
                                      } as Provider),
                              ),
                              {
                                  aws_classrooms_email:
                                      sponsoringCompanyItem.awsClassroom,
                                  provider_name:
                                      sponsoringCompanyItem.providerName,
                                  is_sponsor_company: true,
                                  pk: sponsoringCompanyItem.pk,
                              } as Provider,
                          ],
                      }
                    : {}),
            };
            const isSuccessful = await handleInstructorDetailNotification(
                dispatch<any>(
                    updateSelectedInstructor(match.params.id, instructorData),
                ),
                addNotification,
                'details',
            );

            if (!isSuccessful) {
                setSubmitting(false);
            } else {
                dispatch(setRequireUpdate(true));
                navigateToDetailPage();
            }
        }
    };

    const navigateToDetailPage = () => {
        history.push({
            pathname: `/instructors/${match.params.id}`,
        });
    };

    const cancelModalProps: CancelModalProps = {
        cancelModalVisible,
        setCancelModalVisible,
        submitting,
        onCancelConfirm: navigateToDetailPage,
        testPrefix: 'EditInstructorDetails',
    };

    const basicInfoProps: InstructorFormSectionProps<InstructorProfileData> = {
        formValues,
        errors,
        handleFieldEvent,
        mode,
        handleProviderItemEvent,
        handleSponsoringCompanyItemEvent,
        providerAttributeEditorItems,
        sponsoringCompanyItem,
        controlArrayErrors,
    };

    const getValidationConfig = useCallback((): {
        [key in ValidationType]?: Array<keyof InstructorProfileData>;
    } => {
        if (
            getIsExternalInstructor(
                formValues.instructor_type as InstructorTypeValues,
            )
        ) {
            let required = [
                ...INSTRUCTOR_VALIDATION_FIELDS.EXTERNAL_REQUIRED,
                ...(formValues.is_freelancer
                    ? INSTRUCTOR_VALIDATION_FIELDS.EXTERNAL_FREELANCER_REQUIRED
                    : []),
            ];
            let email = [
                ...INSTRUCTOR_VALIDATION_FIELDS.EXTERNAL_EMAIL,
                ...(formValues.is_freelancer
                    ? INSTRUCTOR_VALIDATION_FIELDS.EXTERNAL_FREELANCER_EMAIL
                    : []),
            ];

            return {
                required,
                email: email,
            };
        } else {
            return {
                required: INSTRUCTOR_VALIDATION_FIELDS.INTERNAL_REQUIRED,
                email: INSTRUCTOR_VALIDATION_FIELDS.INTERNAL_EMAIL,
            };
        }
    }, [formValues.is_freelancer, formValues.instructor_type]);

    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, getValidationConfig());
        }

        if (isControlArrayInvalid) {
            validateFormControlArray(
                {
                    providerAttributeEditorItems,
                    sponsoringCompanyItems: [sponsoringCompanyItem],
                },
                providerValidationConfig,
            );
        }
    }, [
        dispatch,
        formValues,
        getValidationConfig,
        isControlArrayInvalid,
        isInvalid,
        providerAttributeEditorItems,
        sponsoringCompanyItem,
        validateForm,
        validateFormControlArray,
    ]);

    return (
        <>
            <Form
                data-testid="InstructorEditDetailsForm"
                actions={
                    <div className="awsui-util-pt-xl">
                        <Button
                            variant="link"
                            data-testid={`InstructorEditDetails-CancelBtn`}
                            disabled={submitting}
                            onClick={() => {
                                setCancelModalVisible(true);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`InstructorEditDetails-SubmitBtn`}
                            formAction="submit"
                            disabled={submitting}
                            onClick={handleSubmitEvent}
                        >
                            {submitting ? 'Saving' : 'Save'}
                        </Button>
                    </div>
                }
            >
                <InstructorDetailsFormSection {...basicInfoProps} />
            </Form>
            <CancelModal {...cancelModalProps} />
        </>
    );
};

export default EditInstructorDetailsForm;
