import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getMilestoneTypesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllMilestoneTypes,
    selectError,
    updateMilestoneType,
    selectSelectedMilestoneType,
    setSelectedMilestoneType,
    addMilestoneType,
    selectCount,
    resetMilestoneTypesSlice,
} from '../../../common/store/slices/milestoneTypesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { MilestoneTypeItemData } from '../../../common/interfaces/businessDataItem/milestoneTypeItem';

const MILESTONE_TYPES_TABLE_TITLE = 'Milestone types';
const MILESTONE_TYPES_DISPLAY_SINGULAR = 'Milestone type';
export const MILESTONE_TYPES_NAME_KEY = 'milestone_type';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (milestoneType: MilestoneTypeItemData) =>
            formatStatus(milestoneType.active.toString()),
    },
];

/**
 * Editable form fields for MilestoneType
 * The keys must map to MilestoneTypeItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<MilestoneTypeItemData> = {
    // the keys must match MilestoneDataType properties
    milestone_type: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${MILESTONE_TYPES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<MilestoneTypeItemData> => {
            return { milestone_type: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<MilestoneTypeItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<MilestoneTypeItemData> = {
    ...createFormSchema,
    milestone_type: {
        ...createFormSchema.milestone_type,
        disabled: true,
    },
};

const AdminMilestoneTypes = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useMilestoneTypes(): AdminBusinessDataSelectors<
        MilestoneTypeItemData
    > {
        const dispatch = useDispatch();

        const items: MilestoneTypeItemData[] = useSelector(
            selectAllMilestoneTypes,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: MilestoneTypeItemData | null = useSelector(
            selectSelectedMilestoneType,
        );
        useEffect(() => {
            dispatch(getMilestoneTypesList());
            dispatch(setSelectedMilestoneType(null));

            return () => {
                dispatch(resetMilestoneTypesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (milestoneType: MilestoneTypeItemData) => {
        if (milestoneType) {
            dispatch(setSelectedMilestoneType(milestoneType.milestone_type));
            await dispatch(getMilestoneTypesList());
        }
    };

    const handleItemUpdate = async (data: MilestoneTypeItemData) => {
        dispatch(setSelectedMilestoneType(null));
        const dispatchPromise = dispatch<any>(updateMilestoneType(data));
        await handleBusinessDataNotification({
            businessDataType: MILESTONE_TYPES_NAME_KEY,
            businessDataDisplayNameLower: MILESTONE_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.milestone_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        milestone_type,
        active,
    }: MilestoneTypeItemData) => {
        const dispatchPromise = dispatch<any>(
            addMilestoneType(active, milestone_type),
        );
        await handleBusinessDataNotification({
            businessDataType: MILESTONE_TYPES_NAME_KEY,
            businessDataDisplayNameLower: MILESTONE_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: milestone_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementMilestoneTypes">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={MILESTONE_TYPES_DISPLAY_SINGULAR}
                itemNameKey={MILESTONE_TYPES_NAME_KEY}
                title={MILESTONE_TYPES_TABLE_TITLE}
                useItemList={useMilestoneTypes}
            />
        </div>
    );
};

export default AdminMilestoneTypes;
