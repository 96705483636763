/**
 * Role types
 */
export enum Roles {
    READ_ONLY = 'InstructorManagementReadOnly',
    INSTRUCTOR_ADMIN = 'InstructorManagementAdministrator',
    SYSTEM_ADMIN = 'SystemAdministrator',
    SCHEDULE_ADMIN = 'ScheduleManagementAdministrator',
    SCHEDULE_READ_ONLY = 'ScheduleManagementReadOnly',
}

/**
 * Actions
 */
export enum Actions {
    INSTRUCTOR_VIEW = 'instructor:view',
    INSTRUCTOR_MODIFY = 'instructor:modify',
    AUDIT_VIEW = 'audit:view',
    ACTIVITY_AUDIT_VIEW = 'activity:audit:view',
    ACTIVITY_NOTES_VIEW = 'activity:notes:view',
    ACTIVITY_HISTORY_VIEW = 'activity:history:view',
    ACTIVITY_CUSTOMERS_VIEW = 'activity:customers:view',
    ACTIVITY_INVOICES_VIEW = 'activity:invoices:view',
    SYSTEM_ADMINISTRATION = 'system:administration',
    INSTRUCTOR_CREATE = 'instructor:create',
    ACTIVITY_CREATE = 'activity:create',
    ACTIVITY_VIEW = 'activity:view',
    ACTIVITY_MODIFY = 'activity:modify',
    RESERVE_TIME_ADMIN = 'reserveTime:admin',
    EXPORT_INVOICE = 'invoice:export',
}

/**
 * Policies
 */
const policies = {
    [Roles.READ_ONLY]: [Actions.INSTRUCTOR_VIEW],
    [Roles.INSTRUCTOR_ADMIN]: [
        Actions.INSTRUCTOR_VIEW,
        Actions.INSTRUCTOR_MODIFY,
        Actions.AUDIT_VIEW,
        Actions.INSTRUCTOR_CREATE,
    ],
    [Roles.SYSTEM_ADMIN]: [
        Actions.INSTRUCTOR_VIEW,
        Actions.INSTRUCTOR_MODIFY,
        Actions.AUDIT_VIEW,
        Actions.ACTIVITY_AUDIT_VIEW,
        Actions.SYSTEM_ADMINISTRATION,
        Actions.INSTRUCTOR_CREATE,
        Actions.ACTIVITY_CREATE,
        Actions.ACTIVITY_VIEW,
        Actions.ACTIVITY_MODIFY,
        Actions.ACTIVITY_NOTES_VIEW,
        Actions.ACTIVITY_CUSTOMERS_VIEW,
        Actions.ACTIVITY_HISTORY_VIEW,
        Actions.ACTIVITY_INVOICES_VIEW,
        Actions.RESERVE_TIME_ADMIN,
        Actions.EXPORT_INVOICE,
    ],
    [Roles.SCHEDULE_ADMIN]: [
        Actions.ACTIVITY_CREATE,
        Actions.ACTIVITY_VIEW,
        Actions.ACTIVITY_MODIFY,
        Actions.ACTIVITY_AUDIT_VIEW,
        Actions.ACTIVITY_NOTES_VIEW,
        Actions.ACTIVITY_CUSTOMERS_VIEW,
        Actions.ACTIVITY_HISTORY_VIEW,
        Actions.ACTIVITY_INVOICES_VIEW,
        Actions.RESERVE_TIME_ADMIN,
        Actions.EXPORT_INVOICE,
    ],
    [Roles.SCHEDULE_READ_ONLY]: [
        Actions.ACTIVITY_VIEW,
        Actions.ACTIVITY_NOTES_VIEW,
        Actions.ACTIVITY_CUSTOMERS_VIEW,
        Actions.ACTIVITY_INVOICES_VIEW,
        Actions.ACTIVITY_HISTORY_VIEW,
    ],
};

export default policies;
