import {
    Badge,
    Box,
    Button,
    Link,
    Modal,
    RadioGroup,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import React from 'react';
import { INSTRUCTOR_PATH } from '../../../../../imt/constants/path';
import { RecommendedInstructorProfileData } from '../../../../interfaces/recommendedInstructorProfile';
import ResourceLabelPopover from '../Popovers/ResourcePopover';
import './Badge.scss';
import './ResourceLabels.scss';

type ResourceLabelComponentProps = {
    resourceSource: RecommendedInstructorProfileData;
    resourceData: any;
    selectedInstructors: Set<RecommendedInstructorProfileData>;
    handleSelectedInstructorsChange: (
        instructors: Set<RecommendedInstructorProfileData>,
        instructorPk: string,
    ) => void;
};

const RadioResourceLabel = ({
    selectedInstructors,
    resourceData,
    resourceSource,
    handleSelectedInstructorsChange,
}: ResourceLabelComponentProps) => {
    const [showQuickview, setShowQuickview] = React.useState(false);

    return (
        <SpaceBetween direction="horizontal" size="l">
            <RadioGroup
                data-testid={`AssignInstructorResourceViewInstructorRadioGroup${resourceData.resource.id}`}
                onChange={() => {
                    selectedInstructors.clear();
                    selectedInstructors.add(resourceSource);
                    handleSelectedInstructorsChange(
                        selectedInstructors,
                        resourceData.resource.id,
                    );
                }}
                value={
                    selectedInstructors.has(resourceSource)
                        ? resourceSource.pk
                        : null
                }
                items={[
                    {
                        value: resourceSource.pk as string,
                        label: null,
                    },
                ]}
            />
            <div>
                <Modal
                    visible={showQuickview}
                    header={resourceSource.full_name}
                    size="large"
                    onDismiss={() => {
                        setShowQuickview(false);
                    }}
                    footer={
                        <div className="resource-quickview-footer">
                            <div className="full-details">
                                <Link
                                    data-testid={
                                        'AssignInstructorResourceViewInstructorLink'
                                    }
                                    external
                                    externalIconAriaLabel="Opens in a new tab"
                                    href={`${window.location.origin}${INSTRUCTOR_PATH.LIST}/${resourceSource.pk}`}
                                >
                                    Full instructor details
                                </Link>
                            </div>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            setShowQuickview(false);
                                        }}
                                    >
                                        Close
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                >
                    <ResourceLabelPopover {...resourceSource} />
                </Modal>
                <span
                    onClick={() => {
                        setShowQuickview(true);
                    }}
                >
                    <span className="resource-title">{resourceData.resource.title}</span>
                    {resourceSource.is_freelancer && (
                        <Badge className="fl-badge" color="blue">
                            FL
                        </Badge>
                    )}
                </span>
            </div>
        </SpaceBetween>
    );
};

export default RadioResourceLabel;
