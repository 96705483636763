import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getActivityStatusesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllActivityStatuses,
    selectError,
    updateActivityStatus,
    selectSelectedActivityStatus,
    setSelectedActivityStatus,
    addActivityStatus,
    selectCount,
    resetActivityStatusesSlice,
} from '../../../common/store/slices/activityStatusesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { ActivityStatusItemData } from '../../../common/interfaces/businessDataItem/activityStatusItem';

const ACTIVITY_STATUSES_TABLE_TITLE = 'Activity statuses';
const ACTIVITY_STATUSES_DISPLAY_SINGULAR = 'Activity status';
export const ACTIVITY_STATUS_NAME_KEY = 'activity_status';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (activityStatus: ActivityStatusItemData) =>
            formatStatus(activityStatus.active.toString()),
    },
];

/**
 * Editable form fields for ActivityStatus
 * The keys must map to ActivityStatusItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<ActivityStatusItemData> = {
    // the keys must match ActivityStatusItem properties
    activity_status: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${ACTIVITY_STATUSES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<ActivityStatusItemData> => {
            return { activity_status: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<ActivityStatusItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<ActivityStatusItemData> = {
    ...createFormSchema,
    activity_status: {
        ...createFormSchema.activity_status,
        disabled: true,
    },
};

const AdminActivityStatuses = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useActivityStatuses(): AdminBusinessDataSelectors<
        ActivityStatusItemData
    > {
        const dispatch = useDispatch();

        const items: ActivityStatusItemData[] = useSelector(
            selectAllActivityStatuses,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: ActivityStatusItemData | null = useSelector(
            selectSelectedActivityStatus,
        );
        useEffect(() => {
            dispatch(getActivityStatusesList());
            dispatch(setSelectedActivityStatus(null));

            return () => {
                dispatch(resetActivityStatusesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (activityStatus: ActivityStatusItemData) => {
        if (activityStatus) {
            dispatch(setSelectedActivityStatus(activityStatus.activity_status));
            await dispatch(getActivityStatusesList());
        }
    };

    const handleItemUpdate = async (data: ActivityStatusItemData) => {
        dispatch(setSelectedActivityStatus(null));
        const dispatchPromise = dispatch<any>(updateActivityStatus(data));
        await handleBusinessDataNotification({
            businessDataType: ACTIVITY_STATUS_NAME_KEY,
            businessDataDisplayNameLower: ACTIVITY_STATUSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.activity_status,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        activity_status,
        active,
    }: ActivityStatusItemData) => {
        const dispatchPromise = dispatch<any>(
            addActivityStatus(active, activity_status),
        );
        await handleBusinessDataNotification({
            businessDataType: ACTIVITY_STATUS_NAME_KEY,
            businessDataDisplayNameLower: ACTIVITY_STATUSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: activity_status,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementActivityStatuses">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={ACTIVITY_STATUSES_DISPLAY_SINGULAR}
                itemNameKey={ACTIVITY_STATUS_NAME_KEY}
                title={ACTIVITY_STATUSES_TABLE_TITLE}
                useItemList={useActivityStatuses}
            />
        </div>
    );
};

export default AdminActivityStatuses;
