import React from 'react';
import { Badge, ColumnLayout, Icon } from '@amzn/awsui-components-react';
import { formatStatusHelper } from '../../../../common/utils/formatHelper';
import {
    EMPTY_STRING,
    SectionContentType,
} from '../../../../common/constants/grimsby';
import {
    InstructorProfileData,
    Provider,
} from '../../../interfaces/instructorProfile';
import { ColumnContentData } from '../../../../common/components/Section/Section';
import formatDate from '../../../../common/utils/formatDate';
import { FormSectionProps } from '../../../../common/interfaces/formSectionProps';
import { ValidationType } from '../../../../common/utils/formValidation';
import { formatStringArray } from '../../../../common/utils/formatStringArray';
import { formatString } from '../../../../common/utils/formatString';

export interface ProviderAttributeEditorItem {
    providerName: string;
    awsClassroom: string;
    pk: string;
}

export interface InstructorControlArrayFormValues {
    providerAttributeEditorItems: ProviderAttributeEditorItem;
    sponsoringCompanyItems: ProviderAttributeEditorItem;
}

export type InstructorControlArrayErrors = {
    [instructorControlArrayFormValuesKey in keyof InstructorControlArrayFormValues]: {
        [key: number]: {
            [key in keyof InstructorControlArrayFormValues[instructorControlArrayFormValuesKey]]?: string;
        };
    };
};

export type InstructorFormSectionProps<T> = Omit<
    FormSectionProps<T> & {
        handleProviderItemEvent: (
            providerItems: Array<ProviderAttributeEditorItem>,
        ) => void;
        handleSponsoringCompanyItemEvent: (
            sponsoringCompanyItem: Partial<ProviderAttributeEditorItem>,
        ) => void;
        providerAttributeEditorItems: Array<ProviderAttributeEditorItem>;
        sponsoringCompanyItem: ProviderAttributeEditorItem;
        controlArrayErrors: InstructorControlArrayErrors;
    },
    'validateAndHandleFieldEvent'
>;

export const providerValidationConfig: {
    [instructorControlArrayFormValuesKey in keyof InstructorControlArrayFormValues]: {
        [key in ValidationType]?: Array<
            keyof InstructorControlArrayFormValues[instructorControlArrayFormValuesKey]
        >;
    };
} = {
    providerAttributeEditorItems: {
        required: ['awsClassroom', 'providerName'],
        email: ['awsClassroom'],
    },
    sponsoringCompanyItems: {
        required: ['awsClassroom', 'providerName'],
        email: ['awsClassroom'],
    },
};

export const getInstructorProviderValues = (providers: Array<Provider>) =>
    providers.reduce(
        (acc, provider) => {
            if (provider.is_sponsor_company) {
                acc.sponsoringCompany = provider;
            } else {
                acc.providers.push(provider);
            }
            return acc;
        },
        {
            sponsoringCompany: null,
            providers: [],
        } as { sponsoringCompany: Provider | null; providers: Array<Provider> },
    );

export const RAMPING_STATUS_VALUE = 'Ramping';

export const formatName = (firstName: string | null, lastName: string | null) =>
    formatStringArray([firstName, lastName], ' ');

export const formatAddress = (
    addressLine1: string | null,
    addressLine2: string | null,
    city: string | null,
    stateProvince: string | null,
    postalCode: string | null,
): string => {
    if (addressLine1 || addressLine2) {
        return formatStringArray([
            addressLine1,
            addressLine2,
            city,
            stateProvince,
            postalCode,
        ]);
    }
    return EMPTY_STRING;
};

export const formatLocation = (
    city: string | null,
    country: string | null,
): string => formatStringArray([city, country]);


export const SUSPENDED_STATUS = 'Suspended';

export const formatInstructorStatus = (
    instructorStatus: string = '',
): JSX.Element => {
    return formatStatusHelper([
        {
            status: 'Authorized',
            statusClass: 'awsui-util-status-positive',
            iconClass: 'status-positive' as Icon.Name,
            label: 'Authorized',
        },
        {
            status: 'Pending',
            statusClass: 'awsui-util-status-inactive',
            iconClass: 'status-pending' as Icon.Name,
            label: 'Pending',
        },
        {
            status: 'Suspended',
            statusClass: 'awsui-util-status-negative',
            iconClass: 'status-warning' as Icon.Name,
            label: 'Suspended',
        },
    ])(instructorStatus);
};

export const formatCourseStatus = (courseStatus: string = ''): JSX.Element => {
    return formatStatusHelper([
        {
            status: 'Approved',
            statusClass: 'awsui-util-status-positive',
            iconClass: 'status-positive' as Icon.Name,
            label: 'Approved',
        },
        {
            status: 'Ramping',
            statusClass: 'awsui-util-status-inactive',
            iconClass: 'status-pending' as Icon.Name,
            label: 'Ramping',
        },
        {
            status: 'Failed',
            statusClass: 'awsui-util-status-negative',
            iconClass: 'status-negative' as Icon.Name,
            label: 'Failed',
        },
        {
            status: 'Not Certified',
            statusClass: 'awsui-util-status-negative',
            iconClass: 'status-warning' as Icon.Name,
            label: 'Not Certified',
        },
    ])(courseStatus);
};

export const formatCertificationStatus = (
    certificationStatus: string = '',
): JSX.Element => {
    return formatStatusHelper([
        {
            status: 'Fetched',
            statusClass: 'awsui-util-status-positive',
            iconClass: 'status-positive' as Icon.Name,
            label: '',
        },
        {
            status: 'Fetching',
            statusClass: 'awsui-util-status-inactive',
            iconClass: 'status-pending' as Icon.Name,
            label: '',
        },
    ])(certificationStatus);
};

export const formatYesNoBoolean = (value: boolean | null | undefined) => {
    if (value == null) {
        return EMPTY_STRING;
    }
    return value ? 'Yes' : 'No';
};

export const formatLeadBoolean = (
    isLead: boolean | null | undefined,
): JSX.Element | string =>
    isLead ? (
        <span className="awsui-util-status-positive nowrap">
            <Icon name="status-positive" />{' '}
            <span className="awsui-util-ml-xs">Yes</span>
        </span>
    ) : (
        <span className="awsui-util-status-inactive nowrap">
            <Icon name="status-stopped" />{' '}
            <span className="awsui-util-ml-xs">No</span>
        </span>
    );

export const formatCertificationExpiration = (timestamp: number | null) => {
    if (timestamp === null) {
        return EMPTY_STRING;
    }

    const curTime = new Date();
    const expTime = new Date(timestamp * 1000);
    const threeMonthsAfterCurTime = new Date(
        curTime.valueOf() + 90 * 86400 * 1000,
    );

    if (expTime < curTime) {
        return (
            <div className="awsui-row">
                {formatDate(expTime)}{' '}
                <Badge color="red" className="awsui-util-mt-s">
                    Expired
                </Badge>
            </div>
        );
    } else if (expTime < threeMonthsAfterCurTime) {
        return (
            <div className="awsui-row">
                {formatDate(expTime)} <Badge color="grey">expiring soon</Badge>
            </div>
        );
    }

    return formatDate(expTime);
};

export const formatProviders = (
    providers: Array<Provider> | undefined = [],
) => {
    return providers
        ?.filter((item) => item)
        .map((item) => item.provider_name)
        .join(', ');
};

export type InstructorTypeValues =
    | 'AWS T&C'
    | 'Internal Resource'
    | 'External Presenter'
    | 'External'
    | 'iATP';

enum InstructorTypeMappedValues {
    External = 'external',
    Internal = 'internal',
}

export const INSTRUCTOR_TYPE_MAP: ReadonlyMap<
    InstructorTypeValues,
    InstructorTypeMappedValues
> = new Map<InstructorTypeValues, InstructorTypeMappedValues>([
    ['External Presenter', InstructorTypeMappedValues.External],
    ['External', InstructorTypeMappedValues.External],
    ['iATP', InstructorTypeMappedValues.External],
    ['Internal Resource', InstructorTypeMappedValues.Internal],
    ['AWS T&C', InstructorTypeMappedValues.Internal],
]);

export const getInstructorTypeMapping = (
    input: InstructorTypeValues,
): InstructorTypeMappedValues =>
    INSTRUCTOR_TYPE_MAP.has(input)
        ? (INSTRUCTOR_TYPE_MAP.get(input) as InstructorTypeMappedValues)
        : InstructorTypeMappedValues.Internal;

export const getIsExternalInstructor = (input: InstructorTypeValues): boolean =>
    getInstructorTypeMapping(input) === InstructorTypeMappedValues.External;

export const getInternalInstructorContent = (profile: InstructorProfileData) =>
    ({
        type: SectionContentType.Column,
        columnsCount: 3 as ColumnLayout.Columns,
        columns: [
            [
                {
                    key: 'Amazon alias',
                    value: formatString(profile.amazon_alias),
                },
                {
                    key: 'Manager email',
                    value: formatString(profile.amazon_manager_email),
                },
            ],
            [
                {
                    key: 'Amazon job role',
                    value: formatString(profile.amazon_job_role),
                },
            ],
            [
                {
                    key: 'Manager',
                    value: formatString(profile.amazon_manager_name),
                },
            ],
        ],
    } as ColumnContentData);
