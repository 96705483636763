import React, { useEffect, useMemo, useState } from 'react';
import {
    PropertyFilterProps,
    Header,
    Table,
    Box,
    PropertyFilter,
    Pagination,
    CollectionPreferences,
    Link,
} from '@amzn/awsui-components-react-v3';
import { Link as RouterLink } from 'react-router-dom';
import { formatStringArray } from '../../../common/utils/formatStringArray';
import { formatString } from '../../../common/utils/formatString';
import {
    ActivityData,
    CommercialPrivateCustomer,
    Customer,
} from '../../interfaces/activity';
import { formatGrimsbyDate } from '../Activity/Common/Common';
import { useSelector, useDispatch } from 'react-redux';
import {
    getActivityList,
    selectActivityList,
    selectIsLoading,
    selectPagesCount,
    selectCurrentPageIndex,
    selectTotalActivitiesCount,
    selectSize,
    setFrom,
    setCurrentPageIndex,
    setSize,
    initializeUserPreference,
    selectVisibleColumns,
    setVisibleColumns,
    setSortFields,
    setSavedFilter,
    setSearchText,
} from '../../store/slices/sfdcListSlice';
import {
    getLocationsList,
    selectAllActiveLocations,
    selectIsLoading as selectIsLocationListLoading,
    selectIsLoaded as selectIsLocationListLoaded,
} from '../../../common/store/slices/locationsSlice';
import {
    getGeographiesList,
    selectAllActiveGeographies as selectGeoList,
    selectIsLoading as selectIsGeoListLoading,
    selectIsLoaded as selectIsGeoListLoaded,
} from '../../../common/store/slices/geographiesSlice';
import {
    getActivityTypesList,
    selectAllActiveActivityTypes,
    selectIsLoaded as selectIsActivityTypeListLoaded,
    selectIsLoading as selectIsActivityTypeListLoading,
} from '../../../common/store/slices/activityTypesSlice';
import {
    getActivityAudiencesList,
    selectAllActiveActivityAudiences,
    selectIsLoaded as selectIsActivityAudienceListLoaded,
    selectIsLoading as selectIsActivityAudienceListLoading,
} from '../../../common/store/slices/activityAudiencesSlice';
import {
    getActivityModalitiesList,
    selectAllActiveActivityModalities,
    selectIsLoaded as selectIsActivityModalityListLoaded,
    selectIsLoading as selectIsActivityModalityListLoading,
} from '../../../common/store/slices/activityModalitiesSlice';
import {
    getPartnerInitiativesList,
    selectAllActivePartnerInitiatives,
    selectIsLoaded as selectIsPartnerInitiativeListLoaded,
    selectIsLoading as selectIsPartnerInitiativeListLoading,
} from '../../../common/store/slices/partnerInitiativesSlice';
import {
    getCoursesList,
    selectAllActiveCourses,
    selectIsLoaded as selectIsCourseListLoaded,
    selectIsLoading as selectIsCourseListLoading,
} from '../../../common/store/slices/coursesSlice';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import {
    selectUser,
    selectUserId,
    updatePreferencesAndSavedFilters,
} from '../../../common/store/slices/userSlice';
import { ScheduleManagementAPIQueryParams } from '../../interfaces/queryParams';
import handleActivityListNotification from '../../../common/utils/handleActivityListNotification';
import { PAGE_SELECTOR_OPTIONS } from '../../../common/constants/grimsby';
import {
    PropertyFilterKeys,
    PropertyFilterLabels,
} from '../../enums/propertyFilterKeys';
import {
    getRegionsList,
    selectAllActiveRegions,
    selectIsLoaded as selectIsRegionListLoaded,
    selectIsLoading as selectIsRegionListLoading,
} from '../../../common/store/slices/regionsSlice';

const SMT_SFDC_QUEUE_FILTER_STORAGE_KEY = 'sfdc_filter';

enum ActivityTableColumnId {
    StartDate = 'start_date',
    EndDate = 'end_date',
    Status = 'activity_status',
    Type = 'activity_type',
    Audience = 'activity_audience',
    CourseName = 'course_name',
    Modality = 'activity_modality',
    Customer = 'customer_name',
    Location = 'location',
    DeliveryLanguage = 'delivery_language',
    OperationsOwner = 'operations_owner',
    Scheduler = 'scheduler',
    Program = 'program',
    PartnerInitiative = 'partner_initiative',
    Providers = 'provider',
    Region = 'delivery_region',
    Requester = 'requester',
    TOFStatus = 'tof_status',
    SalesGeo = 'sales_geo',
    SFDCOpportunityID = 'sfdc_opportunity_id',
    SalesRegion = 'sales_region',
}

export const sfdcColumnDefinitions: Array<any> = [
    {
        id: ActivityTableColumnId.Customer,
        header: 'Customer',
        minWidth: 200,
        width: 400,
        cell: (activity: ActivityData) => (
            <RouterLink to={'/activities/opportunity/' + activity.pk}>
                {formatStringArray(
                    (activity.customers as Array<Customer>)?.map(
                        (c) => c.customer_name,
                    ),
                )}
            </RouterLink>
        ),
    },
    {
        id: ActivityTableColumnId.CourseName,
        header: 'Course name',
        minWidth: 250,
        cell: (activity: ActivityData) =>
            formatString(activity.sfdc_course_name),
    },
    {
        id: ActivityTableColumnId.SalesGeo,
        header: 'Sales geo',
        minWidth: 250,
        cell: (activity: ActivityData) => formatString(activity.sales_geo),
    },
    {
        id: ActivityTableColumnId.SalesRegion,
        header: 'Sales region',
        minWidth: 250,
        cell: (activity: ActivityData) => formatString(activity.sales_region),
    },
    {
        id: ActivityTableColumnId.StartDate,
        header: 'Start date',
        minWidth: 180,
        cell: (activity: ActivityData) =>
            activity?.delivery_sessions[0]?.start_timestamp
                ? formatGrimsbyDate(
                      activity.delivery_sessions[0].start_timestamp,
                      activity.delivery_timezone ?? 'UTC',
                  )
                : '',
    },
    {
        id: ActivityTableColumnId.EndDate,
        header: 'End date',
        minWidth: 180,
        cell: (activity: ActivityData) =>
            activity?.delivery_sessions[activity.delivery_sessions.length - 1]
                ?.end_timestamp
                ? formatGrimsbyDate(
                      activity.delivery_sessions[
                          activity.delivery_sessions.length - 1
                      ].end_timestamp,
                      activity.delivery_timezone ?? 'UTC',
                  )
                : '',
    },
    {
        id: ActivityTableColumnId.Type,
        header: 'Activity type',
        minWidth: 120,
        cell: (activity: ActivityData) => formatString(activity.activity_type),
    },
    {
        id: ActivityTableColumnId.Audience,
        header: 'Audience',
        minWidth: 250,
        cell: (activity: ActivityData) =>
            formatString(activity.activity_audience),
    },
    {
        id: ActivityTableColumnId.Modality,
        header: 'Modality',
        minWidth: 120,
        cell: (activity: ActivityData) =>
            formatString(activity.activity_modality),
    },
    {
        id: ActivityTableColumnId.OperationsOwner,
        header: 'Operations owner',
        minWidth: 200,
        cell: (activity: ActivityData) =>
            formatString(activity.operations_owner),
    },
    {
        id: ActivityTableColumnId.Program,
        header: 'Program',
        minWidth: 200,
        cell: (activity: ActivityData) => formatString(activity.program),
    },
    {
        id: ActivityTableColumnId.PartnerInitiative,
        header: 'Partner initiative',
        minWidth: 200,
        cell: (activity: ActivityData) => formatString(activity.partner_initiative),
    },
    {
        id: ActivityTableColumnId.Location,
        header: 'Location',
        minWidth: 320,
        cell: (activity: ActivityData) => formatString(activity.sfdc_location),
    },
    {
        id: ActivityTableColumnId.Requester,
        header: 'Requestor',
        minWidth: 200,
        cell: (activity: ActivityData) => formatString(activity.requestor),
    },
    {
        id: ActivityTableColumnId.SFDCOpportunityID,
        header: 'SFDC opportunity ID',
        minWidth: 200,
        cell: (activity: any) =>
            activity.customers && activity.customers.length > 0 ? (
                <Link
                    href={`https://aws-crm.my.salesforce.com/${activity.customers[0].sfdc_opportunity_id}`}
                    target="_blank"
                    data-testid="sfdc-link"
                >
                    {formatString(activity.customers[0].sfdc_opportunity_id)}
                </Link>
            ) : (
                '-'
            ),
    },
    {
        id: ActivityTableColumnId.TOFStatus,
        header: 'TOF status',
        minWidth: 180,
        width: 180,
        cell: (activity: ActivityData) =>
            formatStringArray(
                (activity.customers as Array<CommercialPrivateCustomer>)?.map(
                    (c) => c.tof_status,
                ),
            ),
    },
    {
        id: ActivityTableColumnId.Scheduler,
        header: 'Scheduler',
        minWidth: 200,
        cell: (activity: ActivityData) =>
            formatString(activity.scheduler),
    },
];

export const SFDC_CONTENT_SELECTOR_OPTIONS = {
    label: '',
    options: [
        {
            id: ActivityTableColumnId.Customer,
            label: 'Customer',
            editable: false,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.CourseName,
            label: 'Course name',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.SalesGeo,
            label: 'Sales geo',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.SalesRegion,
            label: 'Sales region',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.StartDate,
            label: 'Start Date',
            editable: true,
            visible: true,
            noFilter: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.EndDate,
            label: 'End Date',
            editable: true,
            visible: true,
            noFilter: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.Type,
            label: 'Activity type',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.Audience,
            label: 'Audience',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.Modality,
            label: 'Modality',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.OperationsOwner,
            label: 'Operations owner',
            editable: true,
            visible: true,
            noFilter: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.Program,
            label: 'Program',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.PartnerInitiative,
            label: 'Partner initiative',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.Location,
            label: 'Location',
            editable: true,
            visible: true,
            noFilter: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.Requester,
            label: 'Requester',
            editable: true,
            visible: true,
            noFilter: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.SFDCOpportunityID,
            label: 'SFDC opportunity ID',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.TOFStatus,
            label: 'TOF status',
            editable: true,
            visible: true,
            operators: ['='],
        },
        {
            id: ActivityTableColumnId.Scheduler,
            label: 'Scheduler',
            editable: true,
            visible: true,
            operators: ['='],
        },
    ],
};

const filteringProperties: any = [
    ...SFDC_CONTENT_SELECTOR_OPTIONS.options
        .filter((c) => {
            return !c.noFilter;
        })
        .map((c) => {
            return {
                key: c.id,
                operators: ['='],
                propertyLabel: c.label,
            };
        }),
    ...[],
].sort((a: any, b: any) => {
    if (a.propertyLabel < b.propertyLabel) {
        return -1;
    }

    if (a.propertyLabel > b.propertyLabel) {
        return 1;
    }

    return 0;
});

const SFDCQueue = () => {
    const isLoading = useSelector(selectIsLoading);
    const activityList = useSelector(selectActivityList);
    const pagesCount = useSelector(selectPagesCount);
    const currentPageIndex = useSelector(selectCurrentPageIndex);
    const totalActivitiesCount = useSelector(selectTotalActivitiesCount);
    const size = useSelector(selectSize);
    const visibleColumns = useSelector(selectVisibleColumns);
    const userId = useSelector(selectUserId);
    const [currentSortingColumn, setCurrentSortingColumn] = useState({
        sortingColumn: { sortingField: 'delivery_sessions.end_timestamp' },
        isDescending: false,
    });
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const isLocationListLoaded = useSelector(selectIsLocationListLoaded);
    const isActivityAudienceListLoaded = useSelector(
        selectIsActivityAudienceListLoaded,
    );
    const isActivityAudienceListLoading = useSelector(
        selectIsActivityAudienceListLoading,
    );
    const isCourseListLoading = useSelector(selectIsCourseListLoading);
    const isCourseListLoaded = useSelector(selectIsCourseListLoaded);
    const isActivityModalityListLoading = useSelector(
        selectIsActivityModalityListLoading,
    );
    const isActivityModalityListLoaded = useSelector(
        selectIsActivityModalityListLoaded,
    );
    const isPartnerInitiativeListLoading = useSelector(
        selectIsPartnerInitiativeListLoading,
    );
    const isPartnerInitiativeListLoaded = useSelector(
        selectIsPartnerInitiativeListLoaded,
    );
    const isActivityTypeListLoading = useSelector(
        selectIsActivityTypeListLoading,
    );
    const isActivityTypeListLoaded = useSelector(
        selectIsActivityTypeListLoaded,
    );
    const locationList = useSelector(selectAllActiveLocations);
    const activityAudienceList = useSelector(selectAllActiveActivityAudiences);
    const activityTypeList = useSelector(selectAllActiveActivityTypes);
    const courseList = useSelector(selectAllActiveCourses);
    const activityModalityList = useSelector(selectAllActiveActivityModalities);
    const partnerInitiativeList = useSelector(selectAllActivePartnerInitiatives);
    const isGeoListLoading = useSelector(selectIsGeoListLoading);
    const isGeoListLoaded = useSelector(selectIsGeoListLoaded);
    const geoList = useSelector(selectGeoList);
    const regionList = useSelector(selectAllActiveRegions);
    const isRegionListLoading = useSelector(selectIsRegionListLoading);
    const isRegionListLoaded = useSelector(selectIsRegionListLoaded);
    const userProfile = useSelector(selectUser);
    const { addNotification } = useNotifications();

    const [query, setQuery] = React.useState<PropertyFilterProps.Query>(
        localStorage.getItem(SMT_SFDC_QUEUE_FILTER_STORAGE_KEY)
            ? JSON.parse(
                  localStorage.getItem(
                      SMT_SFDC_QUEUE_FILTER_STORAGE_KEY,
                  ) as string,
              )
            : {
                  tokens: [],
                  operation: 'and', // Polaris wont allow set to null for some reason
              },
    );

    const dispatch = useDispatch();

    React.useEffect(() => {
        let propertyTokens: any = {};
        let freeText = [];
        for (let token of query.tokens) {
            if (token.propertyKey) {
                if (propertyTokens[token.propertyKey]) {
                    propertyTokens[token.propertyKey].push(token.value);
                } else {
                    propertyTokens[token.propertyKey]= [token.value];
                }
            } else {
                freeText.push(token.value);
            }
        }
        dispatch(initializeUserPreference());
        dispatch(setSavedFilter(propertyTokens));
        dispatch(setSearchText(freeText.join(' ')));
        dispatch(getActivityList());
    }, [query, dispatch]);

    useEffect(() => {
        localStorage.setItem(SMT_SFDC_QUEUE_FILTER_STORAGE_KEY, JSON.stringify(query));
    }, [query]);

    useEffect(() => {
        dispatch(initializeUserPreference());
        dispatch(getActivityList());
    }, [dispatch]);

    const filteringOptionValues: Array<any> = useMemo(() => {
        const defaultFilteringOptions = [
            {
                groupValuesLabel: PropertyFilterLabels.Audience,
                propertyKey: PropertyFilterKeys.Audience,
                propertyLabel: PropertyFilterLabels.Audience,
                values: activityAudienceList.map(
                    (activityAudience) => activityAudience.activity_audience,
                ),
            },
            {
                groupValuesLabel: PropertyFilterLabels.City,
                propertyKey: PropertyFilterKeys.City,
                propertyLabel: PropertyFilterLabels.City,
                values: locationList.map((locationData) => locationData.city),
            },
            {
                groupValuesLabel: PropertyFilterLabels.Country,
                propertyKey: PropertyFilterKeys.Country,
                propertyLabel: PropertyFilterLabels.Country,
                values: locationList
                    .sort((a, b) => {
                        if (a.country.toUpperCase() > b.country.toUpperCase()) {
                            return 1;
                        }

                        return -1;
                    })
                    .map((locationData) => locationData.country),
            },
            {
                groupValuesLabel: PropertyFilterLabels.Course,
                propertyKey: PropertyFilterKeys.Course,
                propertyLabel: PropertyFilterLabels.Course,
                values: courseList.map((course) => course.course),
            },
            {
                groupValuesLabel: PropertyFilterLabels.Modality,
                propertyKey: PropertyFilterKeys.Modality,
                propertyLabel: PropertyFilterLabels.Modality,
                values: activityModalityList.map(
                    (modality) => modality.activity_modality,
                ),
            },
            {
                groupValuesLabel: PropertyFilterLabels.Program,
                propertyKey: PropertyFilterKeys.Program,
                propertyLabel: PropertyFilterLabels.Program,
                values: userProfile?.profile.programs,
            },
            {
                groupValuesLabel: PropertyFilterLabels.TOFStatus,
                propertyKey: PropertyFilterKeys.TOFStatus,
                propertyLabel: PropertyFilterLabels.TOFStatus,
                values: [
                    'Not Started',
                    'With Customer',
                    'Signature Received',
                    'Expired',
                ],
            },
            {
                groupValuesLabel: PropertyFilterLabels.Type,
                propertyKey: PropertyFilterKeys.Type,
                propertyLabel: PropertyFilterLabels.Type,
                values: activityTypeList.map(
                    (activityType) => activityType.activity_type,
                ),
            },
            {
                groupValuesLabel: PropertyFilterLabels.SalesGeo,
                propertyKey: PropertyFilterKeys.SalesGeo,
                propertyLabel: PropertyFilterLabels.SalesGeo,
                values: geoList.map(
                    (geo) => geo.geo,
                ),
            },
            {
                groupValuesLabel: PropertyFilterLabels.SalesRegion,
                propertyKey: PropertyFilterKeys.SalesRegion,
                propertyLabel: PropertyFilterLabels.SalesRegion,
                values: regionList.map(
                    (region) => region.region
                ),
            }
        ];

        return defaultFilteringOptions.map((filteringOption) => {
            return {
                ...filteringOption,
                values: filteringOption.values?.filter((p) => {
                    return query?.tokens?.filter((token) => {
                        return token.propertyKey === filteringOption.propertyKey && token.value === p;
                    }).length === 0
                })
            }
        });
    }, [query, activityModalityList, partnerInitiativeList, geoList, regionList, userProfile, courseList, locationList, activityAudienceList, activityTypeList]);

    const filteringOptions = React.useMemo(() => {
        let newFilteringOptions = [];

        for (let filteringOption of filteringOptionValues) {
            for (let filterValue of filteringOption.values) {
                newFilteringOptions.push({
                    propertyKey: filteringOption.propertyKey,
                    value: filterValue,
                });
            }
        }

        return newFilteringOptions;
    }, [filteringOptionValues]);

    // lifecycle method to fetch (and refetch) business data
    useEffect(() => {
        (
            [
                [
                    !isLocationListLoaded && !isLocationListLoading,
                    getLocationsList,
                ],
                [
                    !isActivityAudienceListLoaded &&
                        !isActivityAudienceListLoading,
                    getActivityAudiencesList,
                ],
                [!isCourseListLoaded && !isCourseListLoading, getCoursesList],
                [
                    !isActivityModalityListLoading &&
                        !isActivityModalityListLoaded,
                    getActivityModalitiesList,
                ],
                [
                    !isPartnerInitiativeListLoading &&
                    !isPartnerInitiativeListLoaded,
                    getPartnerInitiativesList,
                ],
                [
                    !isActivityTypeListLoaded && !isActivityTypeListLoading,
                    getActivityTypesList,
                ],
                [
                    !isGeoListLoaded && !isGeoListLoading,
                    getGeographiesList,
                ],
                [
                    !isRegionListLoaded && !isRegionListLoading,
                    getRegionsList
                ]
            ] as ReadonlyArray<[boolean, Function]>
        ).forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    const handlePaginationChange = (event: any) => {
        const { currentPageIndex } = event.detail;
        let isSuccessful = true;

        // if the BE update is successful or no BE update, update the global state
        if (isSuccessful) {
            // the following logic handles both pagination operations and
            // page size changing due to the current Polaris design
            let updatedPageIndex = currentPageIndex;
            let from = (updatedPageIndex - 1) * size;

            // Update global state
            dispatch(setCurrentPageIndex(updatedPageIndex));
            dispatch(setFrom(from));
            dispatch(getActivityList());
        }
    };

    const generateContentSelectorOptions = (
        contentSelection: Array<string> | undefined,
    ): Array<any> => {
        if (!contentSelection) {
            return [SFDC_CONTENT_SELECTOR_OPTIONS];
        }

        const contentSelectionSet = new Set(contentSelection);
        return [
            {
                label: SFDC_CONTENT_SELECTOR_OPTIONS.label,
                options: SFDC_CONTENT_SELECTOR_OPTIONS.options.map((opt) => ({
                    id: opt.id,
                    label: opt.label,
                    editable: opt.editable,
                    visible: contentSelectionSet.has(opt.id),
                })),
            },
        ];
    };

    const updateUserPreference = (data: {
        [key: string]: number | Array<string>;
    }): Promise<boolean> => {
        if (userId && data) {
            return dispatch<any>(
                updatePreferencesAndSavedFilters(userId, {
                    preferences: {
                        sfdc_list: data,
                    },
                }),
            );
        }
        return Promise.resolve(false);
    };

    const refreshPagination = () => {
        dispatch(setCurrentPageIndex(1));
        dispatch(setFrom(0));
    };

    const handleSortingChange = async (event: any) => {
        const { sortingColumn, isDescending } = event.detail;
        const order = isDescending ? 'desc' : 'asc';

        setCurrentSortingColumn(event.detail);

        const sortFields: Array<ScheduleManagementAPIQueryParams.SortFieldsItem> =
            [
                {
                    [sortingColumn.sortingField]: {
                        order: order,
                    },
                },
            ];

        dispatch(setSortFields(sortFields));
        refreshPagination();
        dispatch(getActivityList());
    };

    const handleContentSelectionChange = async (event: any) => {
        const contentSelection = event.detail.visibleContent;

        if (contentSelection) {
            // update the user's preferences of visible columns
            const dispatchPromise = updateUserPreference({
                visible_columns: contentSelection,
                page_size: event.detail.pageSize,
            });

            // add notification
            await handleActivityListNotification(
                dispatchPromise,
                addNotification,
            );

            // update the global state no matter whether BE update is successful
            // since the current Polaris will auto filter out the invisible columns
            // by CSS and we don't have full control on it by only JS.
            dispatch(setVisibleColumns(contentSelection));
            dispatch(setSize(event.detail.pageSize));
            refreshPagination();
            dispatch(getActivityList());
        }
    };

    return (
        <Table
            columnDefinitions={sfdcColumnDefinitions}
            items={activityList}
            loadingText="Loading activities"
            data-testid="sfdc-queue-table"
            key="sfdc-queue-table"
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No activities in queue</b>
                    <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                        No activities to display.
                    </Box>
                </Box>
            }
            filter={
                <PropertyFilter
                    filteringProperties={filteringProperties}
                    filteringOptions={filteringOptions}
                    i18nStrings={{
                        filteringAriaLabel:
                            'Search queue by opportunity ID or property',
                        dismissAriaLabel: 'Dismiss',
                        filteringPlaceholder: 'Search',
                        groupValuesText: 'Values',
                        groupPropertiesText: 'Properties',
                        operatorsText: 'Operators',
                        operationAndText: 'and',
                        operationOrText: 'or',
                        operatorLessText: 'Less than',
                        operatorLessOrEqualText: 'Less than or equal',
                        operatorGreaterText: 'Greater than',
                        operatorGreaterOrEqualText: 'Greater than or equal',
                        operatorContainsText: 'Contains',
                        operatorDoesNotContainText: 'Does not contain',
                        operatorEqualsText: 'Equals',
                        operatorDoesNotEqualText: 'Does not equal',
                        editTokenHeader: 'Edit filter',
                        propertyText: 'Property',
                        operatorText: 'Operator',
                        valueText: 'Value',
                        cancelActionText: 'Cancel',
                        applyActionText: 'Apply',
                        allPropertiesLabel: 'All properties',
                        tokenLimitShowMore: 'Show more',
                        tokenLimitShowFewer: 'Show fewer',
                        clearFiltersText: 'Clear filters',
                        removeTokenButtonAriaLabel: () => 'Remove token',
                        enteredTextLabel: (text) => `Use: "${text}"`,
                    }}
                    query={query}
                    onChange={(e) => setQuery(e.detail)}
                    hideOperations
                />
            }
            loading={isLoading}
            header={<Header>SFDC queue ({totalActivitiesCount})</Header>}
            pagination={
                <Pagination
                    currentPageIndex={currentPageIndex}
                    onChange={handlePaginationChange}
                    pagesCount={pagesCount}
                    ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: (pageNumber) =>
                            `Page ${pageNumber} of all pages`,
                    }}
                />
            }
            wrapLines={false}
            visibleColumns={visibleColumns}
            resizableColumns
            sortingColumn={currentSortingColumn?.sortingColumn}
            sortingDescending={currentSortingColumn?.isDescending}
            onSortingChange={handleSortingChange}
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={{
                        visibleContent: visibleColumns,
                        pageSize: size,
                    }}
                    onConfirm={handleContentSelectionChange}
                    pageSizePreference={{
                        title: 'Page size',
                        options: PAGE_SELECTOR_OPTIONS,
                    }}
                    visibleContentPreference={{
                        title: 'Select visible columns',
                        options: generateContentSelectorOptions(visibleColumns),
                    }}
                />
            }
        />
    );
};

export default SFDCQueue;
