import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Header } from "@amzn/awsui-components-react-v3";
import { FormSectionProps } from "../../../../common/interfaces/formSectionProps";
import { CourseFormData } from "../../../interfaces/courseForm";
import { getOptionsAndLookupForSelectInputPolarisV3 } from "../../../../imt/components/Instructor/FormSections/FormSections.common";
import AdminCourseMilestonesEditTable from './AdminCourseMilestonesEditFormTable';
import {
    selectActivePlusCourseSelection as selectCertificationList,
    selectError as selectCertificationsListError,
    selectIsLoaded as selectIsCertificationsListLoaded,
    selectIsLoading as selectIsCertificationsListLoading,
} from '../../../../common/store/slices/certificationsSlice';
import {
    getMilestoneTypesList,
    selectAllActiveMilestoneTypes as selectMilestoneTypesList,
    selectError as selectMilestoneTypesError,
    selectIsLoaded as selectIsMilestoneTypesLoaded,
    selectIsLoading as selectIsMilestoneTypesLoading,
} from '../../../../common/store/slices/milestoneTypesSlice';
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import { Actions } from "../../../../common/constants/auth";
import Can from "../../../../common/components/Can";
import { AdminCourseMilestoneModalForm } from "../Edit/AdminCourseMilestoneModalForm";
import { MilestoneItemData } from "../../../../common/interfaces/businessDataItem/milestoneItem";
import { MilestoneTypeItemData } from "../../../../common/interfaces/businessDataItem/milestoneTypeItem";
import { CertificationItemData } from "../../../../common/interfaces/businessDataItem/certificationItem";
import { v4 as uuidv4 } from 'uuid';
import useFormValidation from "../../../../common/utils/formValidation";


const initialFormState = {
    active: true,
    milestone_type: 'Information',
    milestone_requirement: null,
    milestone_id: null,
    milestone_digital_course: null,
    milestone_digital_course_link: null,
    milestone_certification: null,
}

const milestoneValidationConfig ={
    Certification: {
        required: ['milestone_type','milestone_requirement', 'milestone_certification'],
    },
    Digital: {
        required: ['milestone_type','milestone_requirement','milestone_digital_course','milestone_digital_course_link'],
    },
    rest: {
        required: ['milestone_type','milestone_requirement'],
    },
};

export const AdminCoursesFormMilestonesSection = ({
    formValues,
    errors,
    handleFieldEvent,
    name,
    mode,
}: Omit<FormSectionProps<CourseFormData>, 'validateAndHandleFieldEvent'> & {
    name: string;
}) => {
    const [items, setItems] = useState(formValues?.course_milestones ? formValues.course_milestones : []);
    const [selectedItem, setSelectedItem] = useState(null);
    const [milestone, setMilestone] = useState(initialFormState);
    const isCertificationListLoading = useSelector(selectIsCertificationsListLoading);
    const isCertificationListLoaded = useSelector(selectIsCertificationsListLoaded);
    const certificationListError = useSelector(selectCertificationsListError);
    const certificationList = useSelector(selectCertificationList);
    const isMilestoneTypesLoading = useSelector(selectIsMilestoneTypesLoading);
    const isMilestoneTypesLoaded = useSelector(selectIsMilestoneTypesLoaded);
    const milestoneTypesError = useSelector(selectMilestoneTypesError);
    const milestoneTypesList = useSelector(selectMilestoneTypesList);
    const dispatch = useDispatch();

    const businessDataInitList: ReadonlyArray<[boolean, () => Function]> = [
        [
            !isMilestoneTypesLoaded && !isMilestoneTypesLoading,
            getMilestoneTypesList,
        ],
    ];

    useEffect(() => {
        businessDataInitList.forEach(([shouldFetch, getList]) => {
            if (shouldFetch) {
                dispatch(getList());
            }
        });
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updatingFlag, setUpdatingFlag] = useState(false);
    const [creatingFlag, setCreatingFlag] = useState(false);

    const { valueLookup: certificationLookup, valueOptions: certificationOptions } =
    getOptionsAndLookupForSelectInputPolarisV3<CertificationItemData>(
            certificationList,
            (certification: CertificationItemData) => ({
                label: certification.certification,
                value: certification.pk as string,
            }),
        );
    const { valueLookup: milestoneTypesLookup, valueOptions: milestoneTypesOptions } =
    getOptionsAndLookupForSelectInputPolarisV3<MilestoneTypeItemData>(
            milestoneTypesList,
            (milestoneType: MilestoneTypeItemData) => ({
                label: milestoneType.milestone_type,
                value: milestoneType.milestone_type,
            }),
        );

    const [selectedCertification, setSelectedCertification] =
        useState(selectedItem?.milestone_certification ?
            certificationLookup[selectedItem.milestone_certification] :
            certificationOptions[0]);

    const [selectedMilestoneType, setSelectedMilestoneType] =
        useState(selectedItem?.milestone_type ?
            milestoneTypesLookup[selectedItem.milestone_type] :
            milestoneTypesOptions[0]);

    const { isInvalid, errors:milestoneErrors, validateForm } = useFormValidation<MilestoneItemData>();

    const validateMilestoneForm = () => {
        return(validateForm(
            milestone,
            (Object.keys(milestoneValidationConfig).includes(milestone.milestone_type))?
            milestoneValidationConfig[milestone.milestone_type]:
            milestoneValidationConfig.rest,
        ));
    }

    const updateMilestones = async () => {

        let item: MilestoneItemData = {
            milestone_id: null,
            active: milestone.active,
            milestone_type: selectedMilestoneType.label,
            milestone_requirement: milestone.milestone_requirement,
        };

        if (selectedItem) {
            item.milestone_id = selectedItem.id;
        } else {
            item.milestone_id = uuidv4();
        }
        switch (selectedMilestoneType.label) {
            case 'Certification':
                item['milestone_certification'] = selectedCertification ? selectedCertification?.label : "";
                break;
            case 'Digital':
                item['milestone_digital_course'] = milestone.milestone_digital_course;
                item['milestone_digital_course_link'] = milestone.milestone_digital_course_link;
                break;
            default:
                break;
        }

        if (selectedItem) {
            //remove existing item
            setItems([...items.map(_item => {
                if (_item.milestone_id === selectedItem.milestone_id) {
                    return { ...item };
                } else {
                    return _item;
                }
            })]);

        } else {
            setItems([...items, item]);
        }
    }

    useEffect(() => {
        // save when the milestones items list is updated
        handleFieldEvent({
            course_milestones: items
        })
    }, [items]);

    useEffect(() => {
        // setup the select options when 
        if (selectedItem && milestone.milestone_id !== selectedItem.id) {
            setMilestone(selectedItem);
        }
        if (selectedItem && selectedCertification?.label !== selectedItem.milestone_certification) {
            setSelectedCertification(certificationLookup[selectedItem.milestone_certification]);
        }
    }, [selectedItem]);

    const milestoneModalProps = {
        initialFormState,
        isModalOpen,
        setIsModalOpen,
        updateMilestones,
        creatingFlag,
        milestoneTypesOptions,
        selectedMilestoneType,
        setSelectedMilestoneType,
        isMilestoneTypesLoading,
        isMilestoneTypesLoaded,
        milestoneTypesError,
        certificationOptions,
        selectedCertification,
        setSelectedCertification,
        certificationLookup,
        isCertificationListLoading,
        isCertificationListLoaded,
        certificationListError,
        milestone,
        setMilestone,
        milestoneErrors,
        validateMilestoneForm,
    };

    useEffect(() => {
        if (!isModalOpen) {
            // modal is closed
            setSelectedItem(null);
            setCreatingFlag(false);
            setUpdatingFlag(false);
            setSelectedCertification(certificationOptions[0]);
            setSelectedMilestoneType(milestoneTypesOptions[0]);
        } else {
            // modal is open and we are updating so set the milestone type to the edited on
            if (updatingFlag && selectedMilestoneType !== milestoneTypesLookup[selectedItem.milestone_type]) {
                setSelectedMilestoneType(
                    milestoneTypesLookup[selectedItem.milestone_type]
                );
            }
        }
    }, [isModalOpen]);

    return (
        <Container
        data-testid="AdminCoursesManagementFormSection"
        header={<Header>Course Milestones</Header>}
    >
            <SpaceBetween size={"l"}>
                <AdminCourseMilestonesEditTable
                    items={items}
                    setUpdatingFlag={setUpdatingFlag}
                    setIsModalOpen={setIsModalOpen}
                    setSelectedItem={setSelectedItem}
                />
                <div>
                    <Can
                        perform={Actions.SYSTEM_ADMINISTRATION}
                        yes={() => (
                            <Button
                                iconName="add-plus"
                                variant="normal"
                                data-testid="AdminCourseMilestonesEditCreate"
                                onClick={() => {
                                    setMilestone(initialFormState);
                                    setSelectedMilestoneType(null);
                                    setSelectedCertification(null);
                                    setCreatingFlag(true);
                                    setIsModalOpen(true);
                                }}
                            >
                                Add milestone
                            </Button>
                        )}
                    />
                </div>
                <AdminCourseMilestoneModalForm {...milestoneModalProps} />
            </SpaceBetween>
        </Container>
    );
}
