import React, { useState } from 'react';

import {
    Modal,
    Button,
    Box,
    SpaceBetween,
    Alert,
    ColumnLayout,
    FormField,
    Select,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { unix as dayjsUnix } from 'dayjs';
import { ActivityData } from '../../../../interfaces/activity';

interface ValidateReassignInstructorModalProps {
    visible: boolean;
    onConfirm: any;
    onCancel: any;
    instructorConflict: any;
    handleFieldEvent: (values: Partial<any>) => void;
}

const CONFIRM_ASSIGNMENT_TEXT = 'Confirm assignment';
const CONFIRM_ASSIGNMENT_DESCRIPTION_TEXT =
    'Please provide a reason for reassigning this instructor to proceed.';

const EditDeliveryDetailsReassignModal = ({
    onCancel,
    onConfirm,
    instructorConflict,
    visible,
    handleFieldEvent,
}: ValidateReassignInstructorModalProps) => {
    const [
        reassignReason,
        setReassignReason,
    ] = useState<OptionDefinition | null>(null);
    const [error, setError] = useState<boolean>(false);

    const getDisplayDate = (displayTimestamp: number | null | undefined) => {
        if (!displayTimestamp) {
            return '';
        }

        return dayjsUnix(displayTimestamp).format('dddd MMMM D');
    };

    const reassignReasons = [
        {
            label: 'Instructor no longer available',
            id: 'Instructor no longer available',
        },
        {
            label: 'Part of re-shuffle',
            id: 'Part of re-shuffle',
        },
        {
            label: 'Other',
            id: 'Other',
        },
    ];

    const handleConfirm = () => {
        if (!reassignReason) {
            setError(true);
            return;
        }

        handleFieldEvent({
            instructor_assignment_override_reason: reassignReason.label,
        });

        onConfirm();
    };

    return (
        <Modal
            onDismiss={() => onCancel()}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            data-testid={`ConfirmAssignModal`}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={() => onCancel()}
                            data-testid={`ConfirmAssignInstructorCancel`}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={`ConfirmAssignInstructorConfirm`}
                            onClick={() => {
                                handleConfirm();
                            }}
                        >
                            Continue
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={CONFIRM_ASSIGNMENT_TEXT}
        >
            {instructorConflict?.instructor && (
                <Alert
                    onDismiss={() => {}}
                    visible={true}
                    dismissAriaLabel="Close alert"
                    type="warning"
                >
                    {`${instructorConflict?.instructor?.full_name}`} is assigned
                    to another activity. Continuing requires assigning another
                    instructor to their current activity. Continue reassigning?
                </Alert>
            )}
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <div
                        data-awsui-column-layout-root="true"
                        className="reassign-adjacent-activity"
                        key={`reassign-adjacent-activity`}
                    >
                        <span
                            className="reassign-adjacent-activity-title"
                            key={`adjacent-activities-title`}
                        >
                            Booked{' '}
                            {instructorConflict.instructor.booked_activities
                                .length > 1
                                ? 'activities'
                                : 'activity'}{' '}
                            for {instructorConflict.instructor.full_name}
                        </span>
                        {instructorConflict?.instructor.booked_activities.map(
                            (activity: ActivityData, index: number) => {
                                return (
                                    <Box
                                        key={`adjacent-activity-${instructorConflict.instructor.pk}-${activity.pk}-${index}`}
                                        className="reassign-adjacent-activity-item"
                                    >
                                        <span
                                            className="reassign-adjacent-activity-name"
                                            key={`adjacent-activity-name-${index}`}
                                        >
                                            <a
                                                data-testid="reassign-adjacent-activity-name-link"
                                                href={`/activities/${activity?.pk}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="reassign-adjacent-activity-name"
                                            >
                                                {activity?.activity_name}
                                            </a>
                                        </span>
                                        <span key={`adjacent-session-${index}`}>
                                            {getDisplayDate(
                                                activity?.delivery_sessions[0]
                                                    ?.start_timestamp,
                                            )}{' '}
                                            -{' '}
                                            {getDisplayDate(
                                                activity?.delivery_sessions[
                                                    activity.delivery_sessions
                                                        .length - 1
                                                ]?.start_timestamp,
                                            )}
                                        </span>
                                        <span>
                                            {activity?.delivery_city},{' '}
                                            {activity?.delivery_state},{' '}
                                            {activity?.delivery_country}
                                        </span>
                                    </Box>
                                );
                            },
                        )}
                    </div>
                    <FormField
                        label="Reassign reason"
                        description={CONFIRM_ASSIGNMENT_DESCRIPTION_TEXT}
                        className="reassign-reason-form-field"
                        errorText={
                            error ? 'You must select a reassign reason' : null
                        }
                    >
                        <Select
                            placeholder={`Select a reassignment reason`}
                            options={reassignReasons}
                            selectedOption={reassignReason}
                            onChange={(e) => {
                                setReassignReason(e?.detail?.selectedOption);
                            }}
                            loadingText="Loading activity statuses"
                            errorText="An error occurred while loading activity statuses"
                            recoveryText="Retry"
                            data-testid={`ReassignInstructorReason`}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </Modal>
    );
};

export default EditDeliveryDetailsReassignModal;
