import React from 'react';
import Notes from '../../../../../common/components/Notes/Notes';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedActivity } from '../../../../store/slices/selectedActivitySlice';
import {
    ActivityData,
    Notes as ActivityNotes,
} from '../../../../interfaces/activity';
import { NotesProps } from '../../../../../common/interfaces/notesProps';
import getPhoneToolUrl from '../../../../../common/utils/getPhoneToolUrl';
import { selectUser } from '../../../../../common/store/slices/userSlice';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';
import {
    formatGrimsbyDate,
    getHourTimeFormat,
    showEditButton,
} from '../../Common/Common';
import { Actions } from '../../../../../common/constants/auth';

const ACTIVITY_NOTES_FOOTER_KEY = 'Edited';

type ActivityNotesKeys = 'operations_notes' | 'instructor_notes';

const getEditTime = (timestamp: number) => {
    return getHourTimeFormat(timestamp);
};

const getEditDateTimeString = (timestamp: number) => {
    const { time, meridiem } = getEditTime(timestamp);

    return `${formatGrimsbyDate(
        timestamp,
    )} ${time}${meridiem?.toLocaleLowerCase()}`;
};

const getNotesObject = (notes: ActivityNotes) =>
    notes ??
    ({
        description: '',
        updated_by: '',
        timestamp: null,
    } as ActivityNotes);

const NotesTab = ({ id, activity }: { id: string; activity: ActivityData }) => {
    const { addNotification } = useNotifications();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const displayEditButton = showEditButton(activity);

    const action = Actions.ACTIVITY_MODIFY;
    const instructorNotes: ActivityNotes = getNotesObject(
        activity.instructor_notes?.[0],
    );
    const operationalNotes: ActivityNotes = getNotesObject(
        activity.operations_notes?.[0],
    );
    const handleNotification = (isSuccessful: boolean) => {
        addNotification({
            id: `edit-activity-notes-${Date.now()}`,
            ...(isSuccessful
                ? {
                      type: 'success',
                      content: 'Activity notes have been successfully updated.',
                  }
                : {
                      type: 'error',
                      content:
                          'An error occurred while updating activity notes.',
                  }),
        });
    };

    const getFooterProps = (notes: ActivityNotes) =>
        notes.timestamp && notes.updated_by
            ? {
                  key: ACTIVITY_NOTES_FOOTER_KEY,
                  value: (
                      <>
                          {getEditDateTimeString(notes.timestamp)} by{' '}
                          <a
                              data-testid="UserInfoPhoneToolLink"
                              href={getPhoneToolUrl(notes.updated_by)}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              {notes.updated_by}
                          </a>
                      </>
                  ),
              }
            : null;
    const getNotesClickHandlerFunction = (key: ActivityNotesKeys) => (
        notes: string | null,
    ) => async () => {
        const isSuccessful = await dispatch<any>(
            updateSelectedActivity(id, {
                [key]: [
                    {
                        description: notes,
                        updated_by: user?.profile.alias || '',
                        timestamp: Math.floor(Date.now() / 1000),
                    },
                ],
            }),
        );
        handleNotification(isSuccessful);
    };

    const instructorNotesProps: NotesProps = {
        action,
        testId: 'ActivityInstructor',
        label: 'Instructor notes',
        initialValue: instructorNotes.description,
        emptyNotesValue:
            'There are no instructor notes. Instructor notes will be sent to instructors in an activity invite.',
        getNotesClickHandler: getNotesClickHandlerFunction('instructor_notes'),
        footerProps: getFooterProps(instructorNotes),
        enableEdit: displayEditButton,
    };

    const operationsNotesProps: NotesProps = {
        action,
        testId: 'ActivityOperations',
        label: 'Operational notes',
        initialValue: operationalNotes.description,
        emptyNotesValue:
            'There are no operational notes. Operational notes are visible to other users here.',
        getNotesClickHandler: getNotesClickHandlerFunction('operations_notes'),
        footerProps: getFooterProps(operationalNotes),
        enableEdit: displayEditButton,
    };

    return (
        <div data-testid="ActivityNotesTab">
            <Notes {...instructorNotesProps} />
            <Notes {...operationsNotesProps} />
        </div>
    );
};

export default NotesTab;
