import { HandleRequestData } from '../interfaces/handleRequest';
import {
    AdminUpdateBusinessDataPayloadData,
    AdminUpdateCertificationsPayload,
} from '../interfaces/adminUpdateBusinessDataPayload';
import {
    AdminCreateInstructorTypePayloadData,
    AdminCreateInstructorStatusPayloadData,
    AdminCreateProgramTypePayloadData,
    AdminCreateDeliveryCountryPayloadData,
    AdminCreateCertificationPayloadData,
    AdminCreateCourseDomainPayloadData,
    AdminCreateCourseCategoryPayloadData,
    AdminCreateMilestoneTypePayloadData,
} from '../interfaces/adminCreateBusinessDataPayload';
import { AdminCreatePartnerInitiativePayloadData } from '../interfaces/adminCreateBusinessDataPayload/partnerInitiativePayload';

import { ResponseData } from '../interfaces/responseData';
import createQueryString from '../utils/createQueryString';
import handleGrimsbyApiRequest from '../utils/handleGrimsbyApiRequest';
import { BusinessDataApiQueryParams } from '../interfaces/queryParams';
import { DeliveryCountriesResponseData } from '../interfaces/businessDataResponse/deliveryCountriesResponse';
import { CertificationsResponseData } from '../interfaces/businessDataResponse/certificationsResponse';
import { InstructorStatusesResponseData } from '../interfaces/businessDataResponse/instructorStatusesResponse';
import { InstructorTypesResponseData } from '../interfaces/businessDataResponse/instructorTypesResponse';
import { ProgramTypesResponseData } from '../interfaces/businessDataResponse/programTypesResponse';
import { locationsApi } from './businessData/businessDataApi.locations';
import { coursesApi } from './businessData/businessDataApi.courses';
import deliveryLanguagesApi from './businessData/businessDataApi.deliveryLanguages';
import domainSkillsApi from './businessData/businessDataApi.domainSkills';
import instructorCourseStatusesApi from './businessData/businessDataApi.instructorCourseStatuses';
import { API_MAIN_PATH } from '../constants/grimsby';
import freelancerStatusApi from './businessData/businessDataApi.freelancerStatus';
import regionsApi from './businessData/businessDataApi.regions';
import geographiesApi from './businessData/businessDataApi.geographies';
import atpCompaniesApi from './businessData/businessDataApi.atpCompanies';
import countriesApi from './businessData/businessDataApi.countries';
import activityAudiencesApi from './businessData/businessDataApi.activityAudiences';
import activityStatusesApi from './businessData/businessDataApi.activityStatuses';
import activityModalitiesApi from './businessData/businessDataApi.activityModalities';
import activityTypesApi from './businessData/businessDataApi.activityTypes';
import revenueTypesApi from './businessData/businessDataApi.revenueTypes';
import costTypesApi from './businessData/businessDataApi.costTypes';
import instructorRoleTypesApi from './businessData/businessDataApi.instructorRoleTypes';
import instructorBlockedTimeTypesApi from './businessData/businessDataApi.instructorBlockedTimeTypes';
import partnerInitiativesApi from './businessData/businessDataApi.partnerInitiatives';
import { PartnerInitiativesResponseData } from '../interfaces/businessDataResponse/partnerInitiativesResponse';
import { CourseDomainResponseData } from '../interfaces/businessDataResponse/courseDomainResponse';
import { CourseCategoryResponseData } from '../interfaces/businessDataResponse/courseCategoryResponse';
import { MilestoneTypesResponseData } from '../interfaces/businessDataResponse/milestoneTypesResponse';
// comment for hygen script to append new import above

export const BUSINESS_DATA_PATH = API_MAIN_PATH.BUSINESS_DATA_MANAGEMENT;

/**
 * Method to request the full list of instructor types
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getInstructorTypes = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<InstructorTypesResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/instructor-types${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<InstructorTypesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an instructor type.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateInstructorType = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/instructor-types`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add an instructor type.
 * @param {string} instructorTypeName - the name of the new instructor type
 * @param {boolean} active - the status of the new instructor type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addInstructorType = (
    instructorTypeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateInstructorTypePayloadData = {
        instructor_type: instructorTypeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/instructor-types`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to request the full list of instructor statuses
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getInstructorStatuses = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<InstructorStatusesResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/instructor-status${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<InstructorStatusesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update an instructor status.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateInstructorStatus = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/instructor-status`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a program type.
 * @param {string} instructorStatusName - the name of the new instructor status
 * @param {boolean} active - the status of the new instructor status
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addInstructorStatus = (
    instructorStatusName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateInstructorStatusPayloadData = {
        instructor_status: instructorStatusName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/instructor-status`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to request the full list of course domains
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getCourseCategories = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<CourseCategoryResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/course-categories${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<CourseCategoryResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a course domain.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateCourseCategory = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/course-categories`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a program type.
 * @param {string} courseDomainName - the name of the new program type
 * @param {boolean} active - the status of the new program type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addCourseCategory = (
    courseCategoryName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateCourseCategoryPayloadData = {
        course_category: courseCategoryName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/course-categories`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to request the full list of course domains
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getCourseDomains = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<CourseDomainResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/course-domains${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<CourseDomainResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a course domain.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateCourseDomain = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/course-domains`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a program type.
 * @param {string} courseDomainName - the name of the new program type
 * @param {boolean} active - the status of the new program type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addCourseDomain = (
    courseDomainName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateCourseDomainPayloadData = {
        course_domain: courseDomainName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/course-domains`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to request the full list of program types
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getMilestoneTypes = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<MilestoneTypesResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/milestone-types${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<MilestoneTypesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a program type.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateMilestoneType = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/milestone-types`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a program type.
 * @param {string} programTypeName - the name of the new program type
 * @param {boolean} active - the status of the new program type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addMilestoneType = (
    milestoneTypeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateMilestoneTypePayloadData = {
        milestone_type: milestoneTypeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/milestone-types`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};
/**
 * Method to request the full list of program types
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getProgramTypes = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<ProgramTypesResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/program-types${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<ProgramTypesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a program type.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateProgramType = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/program-types`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a program type.
 * @param {string} programTypeName - the name of the new program type
 * @param {boolean} active - the status of the new program type
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addProgramType = (
    programTypeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateProgramTypePayloadData = {
        program_type: programTypeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/program-types`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to request the full list of partner initiatives
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which initiative(s) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getPartnerInitiatives = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<PartnerInitiativesResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/partner-initiative${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<PartnerInitiativesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * Method to update a partner initiative.
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updatePartnerInitiative = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/partner-initiative`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a partner initiative.
 * @param {string} partnerInitiativeName - the name of the new partner initiative
 * @param {boolean} active - the status of the new partner initiative
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addPartnerInitiative = (
    partnerInitiativeName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreatePartnerInitiativePayloadData = {
        partner_initiative: partnerInitiativeName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/partner-initiative`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to request the full list of delivery countries
 * @param {BusinessDataApiQueryParams.GetDeliveryCountries} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getDeliveryCountries = (
    queryParams?: BusinessDataApiQueryParams.GetDeliveryCountries,
): Promise<HandleRequestData<DeliveryCountriesResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/delivery-countries${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<DeliveryCountriesResponseData>(path, {
        method: 'GET',
    });
};

/**
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateDeliveryCountry = (
    uuid: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateBusinessDataPayloadData = {
        uuid: uuid,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/delivery-countries`,

        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a delivery country.
 * @param {string} deliveryCountryName - the name of the new delivery country
 * @param {boolean} active - the status of the new delivery country
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addDeliveryCountry = (
    deliveryCountryName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateDeliveryCountryPayloadData = {
        delivery_country: deliveryCountryName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/delivery-countries`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to request the full list of certifications
 * @param {BusinessDataApiQueryParams.GenericGet} queryParams - which status(es) to retrieve
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const getCertifications = (
    queryParams?: BusinessDataApiQueryParams.GenericGet,
): Promise<HandleRequestData<CertificationsResponseData>> => {
    let path = `${BUSINESS_DATA_PATH}/certifications${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<CertificationsResponseData>(path, {
        method: 'GET',
    });
};

/**
 * @param {string} uuid - the uuid (pk) of the item to update
 * @param {boolean} active - the status to update on the item
 * @param {string} certification - certification name
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const updateCertification = (
    uuid: string,
    active: boolean,
    certification: string,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminUpdateCertificationsPayload = {
        certification,
        uuid,
        active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/certifications`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

/**
 * Method to add a certification.
 * @param {string} certificationName - the name of the new certification
 * @param {boolean} active - the status of the new certification
 * @return: A promise that resolves with an object containing the result or error of the request
 */
const addCertification = (
    certificationName: string,
    active: boolean,
): Promise<HandleRequestData<ResponseData>> => {
    const body: AdminCreateCertificationPayloadData = {
        certification: certificationName,
        active: active,
    };
    return handleGrimsbyApiRequest<ResponseData>(
        `${BUSINESS_DATA_PATH}/certifications`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );
};

const businessDataManagementApi = {
    ...instructorRoleTypesApi,
    ...instructorBlockedTimeTypesApi,
    ...costTypesApi,
    ...revenueTypesApi,
    ...locationsApi,
    ...coursesApi,
    ...deliveryLanguagesApi,
    ...domainSkillsApi,
    ...instructorCourseStatusesApi,
    ...freelancerStatusApi,
    ...regionsApi,
    ...atpCompaniesApi,
    ...geographiesApi,
    ...countriesApi,
    ...activityAudiencesApi,
    ...activityStatusesApi,
    ...activityModalitiesApi,
    ...activityTypesApi,
    ...partnerInitiativesApi,
    getInstructorTypes,
    updateInstructorType,
    addInstructorType,
    getInstructorStatuses,
    updateInstructorStatus,
    addInstructorStatus,
    getProgramTypes,
    updateProgramType,
    addProgramType,
    getCourseDomains,
    addCourseDomain,
    updateCourseDomain,
    getCourseCategories,
    addCourseCategory,
    updateCourseCategory,
    addMilestoneType,
    updateMilestoneType,
    getMilestoneTypes,
    getPartnerInitiatives,
    updatePartnerInitiative,
    addPartnerInitiative,
    getDeliveryCountries,
    updateDeliveryCountry,
    addDeliveryCountry,
    getCertifications,
    updateCertification,
    addCertification,
};

export default businessDataManagementApi;
