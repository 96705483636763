import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import scheduleManagementApi from '../../api/scheduleManagementApi';
import { AppDispatch, GlobalState } from '../../../main/store';
import { GenericStoreState } from '../../../common/interfaces/genericStoreState';
import { ActivityListResponseData } from '../../interfaces/activityListResponse';
import { ActivityData } from '../../interfaces/activity';
import { selectActivityListPreference } from '../../../common/store/slices/userSlice';
import { ScheduleManagementAPIQueryParams } from '../../interfaces/queryParams';
import { DateRangePickerProps } from '@amzn/awsui-components-react-v3';

export interface ActivityListState extends GenericStoreState {
    activityList: ActivityData[];
    from: number;
    pagesCount: number;
    currentPageIndex: number;
    totalActivitiesCount: number;
    size: number;
    searchText: string | null;
    savedFilter: ScheduleManagementAPIQueryParams.PropertyFilterParams | null;
    sourceFields: Array<ScheduleManagementAPIQueryParams.SourceFieldsItem> | null;
    sortFields: Array<ScheduleManagementAPIQueryParams.SortFieldsItem> | null;
    isLoaded: boolean;
    visibleColumns: Array<string> | undefined;
    startTimestamp: number | null;
    endTimestamp: number | null;
    dateRangeFilter: DateRangePickerProps.Value | null;
}

export const defaultVisibleColumns = [
    'activity_name',
    'start_date',
    'end_date',
    'activity_status',
    'activity_type',
    'activity_audience',
    'course_name',
    'instructors',
    'lms_id',
    'activity_modality',
    'customers',
    'location',
];

/**
 * instructorListSlice manages all app instructor list state, and contains instructor list actions as well as instructor list state reducers.
 * Note that while the logic in the reducers appears to mutate the state, it does not.
 * The redux toolkit uses Immer to ensure that no mutations occur.
 */
export const activityListSlice = createSlice({
    name: 'activityList',
    initialState: {
        activityList: [],
        error: null,
        isLoading: false,
        from: 0,
        pagesCount: 0,
        currentPageIndex: 1,
        totalActivitiesCount: 0,
        size: 25,
        searchText: null,
        savedFilter: null,
        sourceFields: null,
        sortFields: null,
        isLoaded: false,
        visibleColumns: defaultVisibleColumns,
        startTimestamp: null,
        endTimestamp: null,
        dateRangeFilter: null,
    } as ActivityListState,
    reducers: {
        setActivityList: (state, action: PayloadAction<ActivityData[]>) => {
            state.activityList = action.payload;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setPagesCount: (state, action: PayloadAction<number>) => {
            state.pagesCount = action.payload;
        },
        setCurrentPageIndex: (state, action: PayloadAction<number>) => {
            state.currentPageIndex = action.payload;
        },
        setTotalActivitiesCount: (state, action: PayloadAction<number>) => {
            state.totalActivitiesCount = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },
        setSavedFilter: (
            state,
            action: PayloadAction<ScheduleManagementAPIQueryParams.PropertyFilterParams | null>,
        ) => {
            state.savedFilter = action.payload;
        },
        setSourceFields: (
            state,
            action: PayloadAction<
                ScheduleManagementAPIQueryParams.SourceFieldsItem[] | null
            >,
        ) => {
            state.sourceFields = action.payload;
        },
        setSortFields: (
            state,
            action: PayloadAction<
                ScheduleManagementAPIQueryParams.SortFieldsItem[] | null
            >,
        ) => {
            state.sortFields = action.payload;
        },
        setIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoaded = action.payload;
        },
        setVisibleColumns: (state, action: PayloadAction<Array<string>>) => {
            if (action.payload) {
                state.visibleColumns = action.payload;
            } else {
                state.visibleColumns = defaultVisibleColumns;
            }
        },
        setStartTimestamp: (state, action: PayloadAction<number | null>) => {
            state.startTimestamp = action.payload;
        },
        setEndTimestamp: (state, action: PayloadAction<number | null>) => {
            state.endTimestamp = action.payload;
        },
        setDateRangeFilter: (
            state,
            action: PayloadAction<DateRangePickerProps.Value | null>,
        ) => {
            state.dateRangeFilter = action.payload;
        },
    },
});

export const {
    setActivityList,
    setError,
    setIsLoading,
    setFrom,
    setPagesCount,
    setCurrentPageIndex,
    setTotalActivitiesCount,
    setSize,
    setSearchText,
    setSavedFilter,
    setSourceFields,
    setSortFields,
    setIsLoaded,
    setVisibleColumns,
    setDateRangeFilter,
    setStartTimestamp,
    setEndTimestamp,
} = activityListSlice.actions;

export const initializeUserPreference = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));

        // activity list preferences will go here
        const preference = selectActivityListPreference(state);
        if (preference?.page_size) {
            dispatch(setSize(preference.page_size));
        }
        if (preference?.visible_columns) {
            dispatch(setVisibleColumns(preference.visible_columns));
        }

        dispatch(setIsLoading(false));
    };
};

/**
 * getActivityList is an async action used to fetch activity list data.
 * There is no explicit inclusion of redux-thunk logic, as the redux toolkit takes care of this for us.
 */
export const getActivityList = () => {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        dispatch(setIsLoading(true));

        const {
            from,
            size,
            searchText: search_text,
            savedFilter: saved_filter,
            sourceFields: source_fields,
            sortFields: sort_fields,
            dateRangeFilter: date_range_filter,
        } = state.activityList;

        let start_timestamp = null;
        let end_timestamp = null;

        let appliedSortFields = sort_fields;

        if (!sort_fields) {
            appliedSortFields = [
                {
                    'delivery_sessions.end_timestamp': {
                        order: 'asc',
                    },
                },
            ];
        }

        if (date_range_filter?.type === 'relative') {
            // unit (month / week / year) & amount
            let currentDate = new Date();
            //set start date to beginning of the day
            currentDate.setHours(0, 0, 0, 0);

            let offsets: any = {
                day: 0,
                week: 0,
                month: 0,
                year: 0,
            };

            offsets[date_range_filter.unit] = date_range_filter.amount;

            let newDate = new Date(
                currentDate.getFullYear() + offsets.year,
                currentDate.getMonth() + offsets.month,
                currentDate.getDate() + offsets.week * 7 + offsets.day,
            );

            if (date_range_filter.amount > 0) {
                start_timestamp = (currentDate.getTime() / 1000) | 0;
                end_timestamp = (newDate.getTime() / 1000) | 0;
            } else {
                start_timestamp = (newDate.getTime() / 1000) | 0;
                end_timestamp = (currentDate.getTime() / 1000) | 0;
            }
        } else {
            if (date_range_filter?.type === 'absolute') {
                let startDate = new Date(date_range_filter?.startDate);
                let endDate = new Date(date_range_filter?.endDate);

                start_timestamp = (startDate.getTime() / 1000) | 0;
                end_timestamp = (endDate.getTime() / 1000) | 0;
            }
        }

        let filters = {
            ...saved_filter,
        } as any;

        try {
            const {
                result: { activities = [], total_activities = 0 },
            }: HandleRequestData<ActivityListResponseData> =
                await scheduleManagementApi.getActivities({
                    from,
                    size,
                    search_text,
                    ...filters,
                    source_fields,
                    sort_fields: appliedSortFields,
                    start_timestamp,
                    end_timestamp,
                    activity_status: filters.activity_status ?? [
                        'Active',
                        'Completed',
                        'Canceled',
                        'Hold',
                        'Tentative',
                    ],
                } as ScheduleManagementAPIQueryParams.GetActivities);
            dispatch(setActivityList(activities));
            dispatch(setTotalActivitiesCount(total_activities));
            dispatch(setPagesCount(Math.ceil(total_activities / size)));
        } catch (error: any) {
            dispatch(setError(error.toString()));
        } finally {
            if (!state.activityList.isLoaded) {
                dispatch(setIsLoaded(true));
            }
            dispatch(setIsLoading(false));
        }
    };
};

export const selectActivityList = (state: GlobalState) =>
    state.activityList.activityList;
export const selectError = (state: GlobalState) => state.activityList.error;
export const selectIsLoading = (state: GlobalState) =>
    state.activityList.isLoading;
export const selectIsLoaded = (state: GlobalState) =>
    state.activityList.isLoaded;
export const selectPagesCount = (state: GlobalState) =>
    state.activityList.pagesCount;
export const selectCurrentPageIndex = (state: GlobalState) =>
    state.activityList.currentPageIndex;
export const selectTotalActivitiesCount = (state: GlobalState) =>
    state.activityList.totalActivitiesCount;
export const selectSize = (state: GlobalState) => state.activityList.size;
export const selectVisibleColumns = (state: GlobalState) =>
    state.activityList.visibleColumns;
export const selectSearchText = (state: GlobalState) =>
    state.activityList.searchText;
export const selectSavedFilter = (state: GlobalState) =>
    state.activityList.savedFilter;
export const selectStartTimestamp = (state: GlobalState) =>
    state.activityList.startTimestamp;
export const selectEndTimestamp = (state: GlobalState) =>
    state.activityList.endTimestamp;
export const selectDateRangeFilter = (state: GlobalState) =>
    state.activityList.dateRangeFilter;

export default activityListSlice.reducer;
