export const formatRelativeRange = (range: any) => {
    const unit = Math.abs(range.amount) === 1 ? range.unit : `${range.unit}s`;
    if (range.amount > 0) {
        return `Next ${range.amount} ${unit}`;
    }

    return `Last ${Math.abs(range.amount)} ${unit}`;
};

export const differenceInDays = (dateOne: any, dateTwo: any) => {
    let start: any = new Date(dateTwo);
    let end: any = new Date(dateOne);
    const milliseconds = Math.abs(start - end);
    const days = Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
    return days;
};

export const dateRangeFilterI18nStrings = {
    todayAriaLabel: 'Today',
    nextMonthAriaLabel: 'Next month',
    previousMonthAriaLabel: 'Previous month',
    customRelativeRangeDurationLabel: 'Duration',
    customRelativeRangeDurationPlaceholder: 'Enter duration',
    customRelativeRangeOptionLabel: 'Custom duration',
    customRelativeRangeOptionDescription: 'Set a custom range',
    customRelativeRangeUnitLabel: 'Unit of time',
    formatUnit: (unit: string, value: number) =>
        value === 1 ? unit : `${unit}s`,
    formatRelativeRange: formatRelativeRange,
    dateTimeConstraintText: '',
    relativeModeTitle: 'Date range',
    absoluteModeTitle: 'Exact dates',
    relativeRangeSelectionHeading: 'Choose a range',
    startDateLabel: 'Start date',
    endDateLabel: 'End date',
    startTimeLabel: 'Start time',
    endTimeLabel: 'End time',
    clearButtonLabel: 'Clear',
    cancelButtonLabel: 'Cancel',
    applyButtonLabel: 'Apply',
};
