import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getInstructorBlockedTimeTypesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllInstructorBlockedTimeTypes,
    selectError,
    updateInstructorBlockedTimeType,
    selectSelectedInstructorBlockedTimeType,
    setSelectedInstructorBlockedTimeType,
    addInstructorBlockedTimeType,
    selectCount,
    resetInstructorBlockedTimeTypesSlice,
} from '../../../common/store/slices/instructorBlockedTimeTypesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { InstructorBlockedTimeTypeItemData } from '../../../common/interfaces/businessDataItem/instructorBlockedTimeTypeItem';

const INSTRUCTOR_BLOCKEDTIME_TYPES_TABLE_TITLE = 'Instructor blocked time types';
const INSTRUCTOR_BLOCKEDTIME_TYPES_DISPLAY_SINGULAR = 'Instructor blocked time type';
export const INSTRUCTOR_BLOCKEDTIME_TYPE_NAME_KEY = 'instructor_blockedtime_type';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (instructorBlockedTimeType: InstructorBlockedTimeTypeItemData) =>
            formatStatus(instructorBlockedTimeType.active.toString()),
    },
];

/**
 * Editable form fields for InstructorBlockedTimeType
 * The keys must map to InstructorBlockedTimeTypeItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<InstructorBlockedTimeTypeItemData> = {
    // the keys must match InstructorBlockedTimeTypeItem properties
    instructor_blockedtime_type: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${INSTRUCTOR_BLOCKEDTIME_TYPES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (
            val: string,
        ): Partial<InstructorBlockedTimeTypeItemData> => {
            return { instructor_blockedtime_type: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<InstructorBlockedTimeTypeItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<InstructorBlockedTimeTypeItemData> = {
    ...createFormSchema,
    instructor_blockedtime_type: {
        ...createFormSchema.instructor_blockedtime_type,
        disabled: true,
    },
};

const AdminInstructorBlockedTimeTypes = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useInstructorBlockedTimeTypes(): AdminBusinessDataSelectors<
        InstructorBlockedTimeTypeItemData
    > {
        const dispatch = useDispatch();

        const items: InstructorBlockedTimeTypeItemData[] = useSelector(
            selectAllInstructorBlockedTimeTypes,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: InstructorBlockedTimeTypeItemData | null = useSelector(
            selectSelectedInstructorBlockedTimeType,
        );
        useEffect(() => {
            dispatch(getInstructorBlockedTimeTypesList());
            dispatch(setSelectedInstructorBlockedTimeType(null));

            return () => {
                dispatch(resetInstructorBlockedTimeTypesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        instructorBlockedTimeType: InstructorBlockedTimeTypeItemData,
    ) => {
        if (instructorBlockedTimeType) {
            dispatch(
                setSelectedInstructorBlockedTimeType(
                    instructorBlockedTimeType.instructor_blockedtime_type,
                ),
            );
            await dispatch(getInstructorBlockedTimeTypesList());
        }
    };

    const handleItemUpdate = async (data: InstructorBlockedTimeTypeItemData) => {
        dispatch(setSelectedInstructorBlockedTimeType(null));
        const dispatchPromise = dispatch<any>(updateInstructorBlockedTimeType(data));
        await handleBusinessDataNotification({
            businessDataType: INSTRUCTOR_BLOCKEDTIME_TYPE_NAME_KEY,
            businessDataDisplayNameLower: INSTRUCTOR_BLOCKEDTIME_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.instructor_blockedtime_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        instructor_blockedtime_type,
        active,
    }: InstructorBlockedTimeTypeItemData) => {
        const dispatchPromise = dispatch<any>(
            addInstructorBlockedTimeType(active, instructor_blockedtime_type),
        );
        await handleBusinessDataNotification({
            businessDataType: INSTRUCTOR_BLOCKEDTIME_TYPE_NAME_KEY,
            businessDataDisplayNameLower: INSTRUCTOR_BLOCKEDTIME_TYPES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: instructor_blockedtime_type,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementInstructorBlockedTimeTypes">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={INSTRUCTOR_BLOCKEDTIME_TYPES_DISPLAY_SINGULAR}
                itemNameKey={INSTRUCTOR_BLOCKEDTIME_TYPE_NAME_KEY}
                title={INSTRUCTOR_BLOCKEDTIME_TYPES_TABLE_TITLE}
                useItemList={useInstructorBlockedTimeTypes}
            />
        </div>
    );
};

export default AdminInstructorBlockedTimeTypes;
