import { HandleRequestData } from '../../common/interfaces/handleRequest';
import createQueryString from '../../common/utils/createQueryString';
import { API_MAIN_PATH } from '../../common/constants/grimsby';
import {
    BatchActivityAPIResponseProps,
    CreateActivityData,
} from '../interfaces/activity';
import handleGrimsbyApiRequest from '../../common/utils/handleGrimsbyApiRequest';
import { CreateActivityResponseData } from '../interfaces/createActivityResponse';
import { SelectedActivityResponseData } from '../interfaces/selectedActivityResponse';
import { ResponseData } from '../../common/interfaces/responseData';
import { ScheduleManagementAPIQueryParams } from '../interfaces/queryParams';
import { ActivityListResponseData } from '../interfaces/activityListResponse';
import {
    InstructorAvailabilityResponseData,
    InstructorListResponseData,
    RecommendedInstructorsResponseData,
} from '../interfaces/instructorsResponse';
import { ExportClipperInvoiceResponseData } from '../interfaces/exportClipperInvoiceResponse';

export const SCHEDULE_MANAGEMENT_PATH = `${API_MAIN_PATH.SCHEDULE_MANAGEMENT}/activities`;
export const BILLING_MANAGEMENT_PATH = `${API_MAIN_PATH.SCHEDULE_MANAGEMENT}/invoices`;

const getActivities = (
    queryParams?: ScheduleManagementAPIQueryParams.GetActivities,
): Promise<HandleRequestData<ActivityListResponseData>> => {
    let path = `${SCHEDULE_MANAGEMENT_PATH}${
        queryParams ? createQueryString(queryParams) : ''
    }`;
    return handleGrimsbyApiRequest<ActivityListResponseData>(path, {
        method: 'GET',
    });
};

const createActivity = (activity: CreateActivityData) => {
    return handleGrimsbyApiRequest<CreateActivityResponseData>(
        SCHEDULE_MANAGEMENT_PATH,
        {
            method: 'POST',
            body: JSON.stringify(activity),
        },
    );
};

const getActivityById = (
    queryParams: ScheduleManagementAPIQueryParams.GetActivityById,
): Promise<HandleRequestData<SelectedActivityResponseData>> => {
    return handleGrimsbyApiRequest<SelectedActivityResponseData>(
        `${SCHEDULE_MANAGEMENT_PATH}/${queryParams.id}`,
        {
            method: 'GET',
        },
    );
};

const updateActivityById = (
    queryParams: ScheduleManagementAPIQueryParams.UpdateActivityById,
): Promise<HandleRequestData<ResponseData>> => {
    return handleGrimsbyApiRequest<ResponseData>(
        `${SCHEDULE_MANAGEMENT_PATH}/${queryParams.id}`,
        {
            method: 'PUT',
            body: JSON.stringify(queryParams.activity),
        },
    );
};

const getClipperInvoiceByGeo = (
    queryParams: ScheduleManagementAPIQueryParams.GetClipperInvoiceByGeo,
): Promise<HandleRequestData<ExportClipperInvoiceResponseData>> => {
    return handleGrimsbyApiRequest<ExportClipperInvoiceResponseData>(
        `${BILLING_MANAGEMENT_PATH}?geo=${queryParams.geo}`,
        {
            method: 'GET',
        },
    );
};

const getRecommendedInstructors = (
    queryParams: ScheduleManagementAPIQueryParams.GetRecommendedInstructors,
): Promise<HandleRequestData<RecommendedInstructorsResponseData>> => {
    const { id, ...params } = queryParams;
    params.provider = ['AWS'];

    const path = `${SCHEDULE_MANAGEMENT_PATH}/${id}/recommend-instructors${createQueryString(
        params,
    )}`;

    return handleGrimsbyApiRequest<RecommendedInstructorsResponseData>(path, {
        method: 'GET',
    });
};

const validateActivityInstructors = (
    queryParams: ScheduleManagementAPIQueryParams.GetValidatedActivityInstructors,
): Promise<HandleRequestData<InstructorAvailabilityResponseData>> => {
    const { id, ...params } = queryParams;

    const path = `${SCHEDULE_MANAGEMENT_PATH}/${id}/validate-activity${createQueryString(
        params,
    )}`;

    return handleGrimsbyApiRequest<InstructorAvailabilityResponseData>(path, {
        method: 'GET',
    });
};

const getInstructorsWithActivitiesList = (
    queryParams: ScheduleManagementAPIQueryParams.InstructorWithActivitesParams,
): Promise<HandleRequestData<InstructorListResponseData>> => {
    const { ...params } = queryParams;
    params.provider = ['AWS'];
    const path = `${
        API_MAIN_PATH.SCHEDULE_MANAGEMENT
    }/instructors${createQueryString(params)}`;

    return handleGrimsbyApiRequest<InstructorListResponseData>(path, {
        method: 'GET',
    });
};

const mergeActivity = (coreActivityPk: string, mergeActivityPk: string) => {
    const params = {
        activityToMerge: coreActivityPk,
    };
    return handleGrimsbyApiRequest<any>(
        `${SCHEDULE_MANAGEMENT_PATH}/${mergeActivityPk}${createQueryString(
            params,
        )}`,
        {
            method: 'POST',
            body: JSON.stringify({
                merge: coreActivityPk,
            }),
        },
    );
};

const batchAddActivity = (base64EncodedFile: string) => {
    return handleGrimsbyApiRequest<BatchActivityAPIResponseProps>(
        `${SCHEDULE_MANAGEMENT_PATH}/batch`,
        {
            method: 'POST',
            body: JSON.stringify({
                excel_file: base64EncodedFile,
            }),
        },
    );
};

const scheduleManagementApi = {
    getActivities,
    createActivity,
    getActivityById,
    updateActivityById,
    getRecommendedInstructors,
    validateActivityInstructors,
    getInstructorsWithActivitiesList,
    getClipperInvoiceByGeo,
    mergeActivity,
    batchAddActivity,
};

export default scheduleManagementApi;
