import React, { Dispatch, SetStateAction } from 'react';
import { Table, TableProps } from '@amzn/awsui-components-react-v3';
import { MilestoneItemData } from '../../../../common/interfaces/businessDataItem/milestoneItem';
import { formatStatus } from '../../AdminBusinessData/AdminBusinessData.Status';
import { Link as PolarisLink } from '@amzn/awsui-components-react-v3';
import { Link as RouterLink, useLocation } from 'react-router-dom';

function formatResource(milestone: MilestoneItemData) {
    switch (milestone.milestone_type) {
        case 'Digital':
            return <PolarisLink external={true} href={milestone?.milestone_digital_course_link}>{milestone?.milestone_digital_course}</PolarisLink>;
        case 'Certification':
            return milestone?.milestone_certification;
        default:
            return '-';
    }
}

export const AdminCourseMilestonesEditTable = ({
    items,
    setUpdatingFlag,
    setSelectedItem,
    setIsModalOpen,
}: {
    items: Array<MilestoneItemData>,    
    setUpdatingFlag: Dispatch<SetStateAction<boolean>>,
    setSelectedItem: Dispatch<SetStateAction<MilestoneItemData>>,
    setIsModalOpen: Dispatch<SetStateAction<boolean>>,
}) => {
    const location = useLocation();
    const columnDefinitions: Array<TableProps.ColumnDefinition<MilestoneItemData>> = [
        {
            id: 'type',
            header: 'Type',
            cell: (milestone: MilestoneItemData) => {
                return (
                    <RouterLink
                        to={location.pathname}
                        onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSelectedItem(milestone);
                            setUpdatingFlag(true);
                            setIsModalOpen(true);
                        }}
                        data-testid={`AdminBusinessData-${milestone.milestone_type}`}
                    >
                        {milestone.milestone_type}
                    </RouterLink>
            )},
        },
        {
            id: 'requirement',
            header: 'Requirement',
            cell: (milestone: MilestoneItemData) => (
                milestone.milestone_requirement),
        },
        {
            id: 'resources',
            header: 'Resources',
            cell: (milestone: MilestoneItemData) => (
                formatResource(milestone)),
        },
        {
            id: 'active',
            header: 'Status',
            cell: (milestone: MilestoneItemData) => {
                    return (formatStatus(milestone.active.toString()));
            },
        },
    ];

    return (
        <>
            <Table
                columnDefinitions={columnDefinitions}
                items={items}
                empty={<div className="awsui-util-t-c">
                    <p className="awsui-util-mb-s">
                        No milestones to display
                    </p>
                </div>}
            ></Table>
        </>
    );
};

export default AdminCourseMilestonesEditTable;
