import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from '@amzn/awsui-components-react';

import {
    getInstructorStatusesList,
    selectIsLoading,
    selectIsLoaded,
    selectAllInstructorStatuses,
    selectError,
    updateInstructorStatus,
    selectSelectedInstructorStatus,
    setSelectedInstructorStatus,
    addInstructorStatus,
    selectCount,
    resetInstructorStatusesSlice,
} from '../../../common/store/slices/instructorStatusesSlice';
import {
    AdminBusinessDataFormInputType,
    AdminBusinessDataFormSchema,
} from '../../interfaces/adminBusinessDataFormSchema';
import { AdminBusinessDataSelectors } from '../../interfaces/adminBusinessDataSelectors';
import AdminBusinessData from '../AdminBusinessData';
import { formatStatus } from '../AdminBusinessData/AdminBusinessData.Status';
import { useNotifications } from '../../../common/context/grimsbyNotifications';
import handleBusinessDataNotification from '../../../common/utils/handleBusinessDataNotification';
import parseBoolean from '../../../common/utils/parseBoolean';
import { InstructorStatusItemData } from '../../../common/interfaces/businessDataItem/instructorStatusItem';

const INSTRUCTOR_STATUSES_TABLE_TITLE = 'Instructor statuses';
const INSTRUCTOR_STATUSES_DISPLAY_SINGULAR = 'Instructor status';
export const INSTRUCTOR_STATUSES_NAME_KEY = 'instructor_status';

// this array must exclude the "name" column because
// we need to define it inside AdminBusinessData so we can attach an eventHandler to that link
export const columnDefinitions: Array<Table.ColumnDefinition> = [
    {
        id: 'active',
        header: 'Status',
        cell: (instructorStatus: InstructorStatusItemData) =>
            formatStatus(instructorStatus.active.toString()),
    },
];

/**
 * Editable form fields for InstructorStatus
 * The keys must map to InstructorStatusItemData properties
 */
export const createFormSchema: AdminBusinessDataFormSchema<InstructorStatusItemData> = {
    // the keys must match InstructorDataStatus properties
    instructor_status: {
        type: AdminBusinessDataFormInputType.Text,
        label: `${INSTRUCTOR_STATUSES_DISPLAY_SINGULAR} name`,
        required: true,
        defaultValue: '',
        disabled: false,
        formDataTransform: (val: string): Partial<InstructorStatusItemData> => {
            return { instructor_status: val };
        },
    },
    active: {
        type: AdminBusinessDataFormInputType.StatusRadio,
        label: 'Status',
        required: true,
        defaultValue: true,
        disabled: false,
        formDataTransform: (
            val: 'true' | 'false',
        ): Partial<InstructorStatusItemData> => {
            return { active: parseBoolean(val) };
        },
    },
};

// The update form schema is the same as the create form schema except the "name" field is disabled
export const updateFormSchema: AdminBusinessDataFormSchema<InstructorStatusItemData> = {
    ...createFormSchema,
    instructor_status: {
        ...createFormSchema.instructor_status,
        disabled: true,
    },
};

const AdminInstructorStatuses = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    function useInstructorStatuses(): AdminBusinessDataSelectors<
        InstructorStatusItemData
    > {
        const dispatch = useDispatch();

        const items: InstructorStatusItemData[] = useSelector(
            selectAllInstructorStatuses,
        );
        const itemCount: number = useSelector(selectCount);
        const isLoading: boolean = useSelector(selectIsLoading);
        const isLoaded: boolean = useSelector(selectIsLoaded);
        const error = useSelector(selectError);
        const currentItem: InstructorStatusItemData | null = useSelector(
            selectSelectedInstructorStatus,
        );
        useEffect(() => {
            dispatch(getInstructorStatusesList());
            dispatch(setSelectedInstructorStatus(null));

            return () => {
                dispatch(resetInstructorStatusesSlice());
            };
        }, [dispatch]);
        return { items, itemCount, isLoading, isLoaded, error, currentItem };
    }

    const handleItemSelect = async (
        instructorStatus: InstructorStatusItemData,
    ) => {
        if (instructorStatus) {
            dispatch(
                setSelectedInstructorStatus(instructorStatus.instructor_status),
            );
            await dispatch(getInstructorStatusesList());
        }
    };

    const handleItemUpdate = async (data: InstructorStatusItemData) => {
        dispatch(setSelectedInstructorStatus(null));
        const dispatchPromise = dispatch<any>(updateInstructorStatus(data));
        await handleBusinessDataNotification({
            businessDataType: INSTRUCTOR_STATUSES_NAME_KEY,
            businessDataDisplayNameLower: INSTRUCTOR_STATUSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: data.instructor_status,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'update',
        });
    };

    const handleItemCreate = async ({
        instructor_status,
        active,
    }: InstructorStatusItemData) => {
        const dispatchPromise = dispatch<any>(
            addInstructorStatus(active, instructor_status),
        );
        await handleBusinessDataNotification({
            businessDataType: INSTRUCTOR_STATUSES_NAME_KEY,
            businessDataDisplayNameLower: INSTRUCTOR_STATUSES_DISPLAY_SINGULAR.toLowerCase(),
            itemName: instructor_status,
            dispatchPromise: dispatchPromise,
            addNotification: addNotification,
            action: 'create',
        });
    };

    return (
        <div data-testid="AdminBusinessDataManagementInstructorStatuses">
            <AdminBusinessData
                columnDefinitions={columnDefinitions}
                createFormSchema={createFormSchema}
                updateFormSchema={updateFormSchema}
                handleItemCreate={handleItemCreate}
                handleItemSelect={handleItemSelect}
                handleItemUpdate={handleItemUpdate}
                itemDisplayNameSingular={INSTRUCTOR_STATUSES_DISPLAY_SINGULAR}
                itemNameKey={INSTRUCTOR_STATUSES_NAME_KEY}
                title={INSTRUCTOR_STATUSES_TABLE_TITLE}
                useItemList={useInstructorStatuses}
            />
        </div>
    );
};

export default AdminInstructorStatuses;
