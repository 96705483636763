import { Alert, Spinner } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { AJAX_CALL_ERROR } from '../../../common/constants/grimsby';

import { ActivityData } from '../../interfaces/activity';import {
    getSelectedActivity,
    selectError as selectActivityError,
    selectIsLoading as selectIsActivityLoading,
    selectSelectedActivity,
} from '../../store/slices/selectedActivitySlice';
import OpportunityForm from './OpportunityForm';

const OpportunityDetails = () => {
    const match = useRouteMatch<{ id: string }>();
    const isLoading = useSelector(selectIsActivityLoading);
    const error: any = useSelector(selectActivityError);
    const activity: ActivityData | undefined = useSelector(
        selectSelectedActivity,
    )?.activity;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSelectedActivity(match.params.id));
    }, [dispatch, match.params.id]);

    if (error) {
        return (
            <Alert
                data-testid="EditDeliveryDetailsError"
                header="Error"
                type="error"
            >
                {AJAX_CALL_ERROR}
            </Alert>
        );
    } else if (!isLoading && !!activity) {
        return <OpportunityForm initialFormState={activity} />;
    } else {
        return <Spinner data-testid="EditDeliveryDetailsSpinner" />;
    }
};

export default OpportunityDetails;
