import {
    ColumnLayout,
    CustomDetailEvent,
    DatePicker,
    FormField,
    FormSection,
    Input,
    Multiselect,
    Select,
} from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    booleanFieldOptionLookup,
    booleanFieldOptions,
    booleanFieldOptionsBoolLookup,
    FORM_ERROR_SELECTOR,
    getOptionsAndLookupForSelectInput,
    getStatusType,
} from './FormSections.common';
import {
    getIsExternalInstructor,
    InstructorFormSectionProps,
    InstructorTypeValues,
} from '../Common/Common';
import {
    getLocationsList,
    setSearchText,
    selectAllActiveLocations,
    selectIsLoading as selectIsLocationListLoading,
    selectIsLoaded as selectIsLocationListLoaded,
    selectError as selectLocationListError,
    selectSearchText,
    resetPartialLocationsSlice,
    resetLocationsSlice,
    initializeLocationsListQueryParams,
} from '../../../../common/store/slices/locationsSlice';
import {
    getInstructorTypesList,
    resetInstructorTypesSlice,
    selectAllActiveInstructorTypes,
    selectError as selectTypeListError,
    selectIsLoaded as selectIsTypeListLoaded,
    selectIsLoading as selectIsTypeListLoading,
} from '../../../../common/store/slices/instructorTypesSlice';
import {
    getProgramTypesList,
    resetProgramTypesSlice,
    selectActivePlusInstructorSelection,
    selectError as selectProgramListError,
    selectIsLoaded as selectIsProgramListLoaded,
    selectIsLoading as selectIsProgramListLoading,
} from '../../../../common/store/slices/programTypesSlice';
import {
    getInstructorStatusesList,
    resetInstructorStatusesSlice,
    selectAllActiveInstructorStatuses,
    selectError as selectStatusListError,
    selectIsLoaded as selectIsStatusListLoaded,
    selectIsLoading as selectIsStatusListLoading,
} from '../../../../common/store/slices/instructorStatusesSlice';
import { LocationItemData } from '../../../../common/interfaces/businessDataItem/locationItem';
import { ProgramTypeItemData } from '../../../../common/interfaces/businessDataItem/programTypeItem';
import { InstructorStatusItemData } from '../../../../common/interfaces/businessDataItem/instructorStatusItem';
import { InstructorTypeItemData } from '../../../../common/interfaces/businessDataItem/instructorTypeItem';
import { InstructorProfileData } from '../../../interfaces/instructorProfile';
import {
    FormSectionMode,
    LocationFormFieldText,
    ProgramFormFieldText,
} from '../../../../common/constants/forms';
import { GRIMSBY_FILTER_RESULTS_COUNT } from '../../../../common/constants/grimsby';
import handlePreSelectedValue from '../../../../common/utils/handlePreSelectedValue';
import { selectSelectedInstructor } from '../../../store/slices/selectedInstructorSlice';
import InstructorDetailsFormSection from './InstructorDetailsFormSection';
import { formatStringArray } from '../../../../common/utils/formatStringArray';

// TODO: move these to their own files
export const getISOString = (date: number) =>
    new Date(date).toISOString().split('T')[0];
export const getMilliseconds = (date: string) => new Date(date).getTime();
export const getCityString = (
    cityData: {
        city: string | null;
        state?: string;
        state_province?: string | null;
        country: string;
    },
    shouldIncludeCountry = true,
) => {
    const { city, country, state, state_province } = cityData;
    if (city && country) {
        return formatStringArray([
            city,
            state_province ?? state,
            ...(shouldIncludeCountry ? [country] : []),
        ]);
    }
    return '';
};

const statusesLoadingText = 'Loading instructor statuses';
const typesLoadingText = 'Loading instructor types';

const BasicInfoFormSection = ({
    formValues,
    errors,
    handleFieldEvent,
    mode,
    handleProviderItemEvent,
    handleSponsoringCompanyItemEvent,
    providerAttributeEditorItems,
    sponsoringCompanyItem,
    controlArrayErrors,
}: InstructorFormSectionProps<InstructorProfileData>) => {
    const isLocationListLoading = useSelector(selectIsLocationListLoading);
    const isLocationListLoaded = useSelector(selectIsLocationListLoaded);
    const citySearchText = useSelector(selectSearchText);
    const locationListError = useSelector(selectLocationListError);
    const isProgramListLoading = useSelector(selectIsProgramListLoading);
    const isProgramListLoaded = useSelector(selectIsProgramListLoaded);
    const programListError = useSelector(selectProgramListError);
    const isStatusListLoading = useSelector(selectIsStatusListLoading);
    const isStatusListLoaded = useSelector(selectIsStatusListLoaded);
    const statusListError = useSelector(selectStatusListError);
    const isTypeListLoading = useSelector(selectIsTypeListLoading);
    const isTypeListLoaded = useSelector(selectIsTypeListLoaded);
    const typeListError = useSelector(selectTypeListError);
    // instructor update api will be changed to allow user to save instructor location in any region
    // for now the list is not filtered, so the API call will fail if the user selects a location outside the user's region.
    const originalLocationList = useSelector(selectAllActiveLocations);
    const preselectedCityId = getCityString(formValues);
    const shouldCheckForValue = !!(
        formValues.city &&
        formValues.country &&
        formValues.geo &&
        formValues.instructor_region
    );
    const locationList = handlePreSelectedValue(
        {
            city: formValues.city as string,
            state: formValues.state_province as string,
            country: formValues.country,
            geo: formValues.geo,
            region: formValues.instructor_region,
            active: true,
            pk: `location-${Date.now()}`,
            city_timezone: formValues.city_timezone ?? '',
        },
        shouldCheckForValue && isLocationListLoaded,
        originalLocationList,
        (location) => getCityString(location) === preselectedCityId,
    );

    // program type multi-select allows user to keep or remove any inactive program types
    // until the admin update instructor API allows user to save instructor with any program types
    // the API call will fail when the user is not in all of the programs saved on this instructor.
    // as a temp workaround, the user **could** add themself to all the programs if the user is a sysadmin EXCEPT for inactive programs.
    const programList = useSelector(selectActivePlusInstructorSelection);
    const statusList = useSelector(selectAllActiveInstructorStatuses);
    const typeList = useSelector(selectAllActiveInstructorTypes);
    const instructor = useSelector(selectSelectedInstructor);
    const dispatch = useDispatch();

    // lifecycle method to initialize and reset business data slices
    useEffect(() => {
        // initialize query params for business data slices
        // this code block should only run once
        dispatch(
            initializeLocationsListQueryParams({
                active: null,
                size: GRIMSBY_FILTER_RESULTS_COUNT,
            }),
        );

        return () => {
            // reset business data slices
            // this code block should only run once
            [
                resetLocationsSlice,
                resetInstructorTypesSlice,
                resetProgramTypesSlice,
                resetInstructorStatusesSlice,
            ].forEach((resetFunction) => dispatch(resetFunction()));
        };
    }, [dispatch]);

    // lifecycle method to fetch (and refetch) business data
    useEffect(() => {
        ([
            [!isLocationListLoaded && !isLocationListLoading, getLocationsList],
            [
                !isProgramListLoaded && !isProgramListLoading,
                getProgramTypesList,
            ],
            [
                !isStatusListLoaded && !isStatusListLoading,
                getInstructorStatusesList,
            ],
            [!isTypeListLoaded && !isTypeListLoading, getInstructorTypesList],
        ] as ReadonlyArray<[boolean, Function]>).forEach(
            ([shouldFetch, getList]) => {
                if (shouldFetch) {
                    dispatch(getList());
                }
            },
        );
    });

    const {
        valueLookup: programLookup,
        valueOptions: programOptions,
    } = getOptionsAndLookupForSelectInput<ProgramTypeItemData>(
        programList,
        (program: ProgramTypeItemData) => ({
            label: program.program_type,
            id: program.pk as string,
        }),
    );

    const {
        valueLookup: instructorStatusLookup,
        valueOptions: instructorStatusOptions,
    } = getOptionsAndLookupForSelectInput<InstructorStatusItemData>(
        statusList,
        (status: InstructorStatusItemData) => ({
            label: status.instructor_status,
            id: status.pk as string,
        }),
    );

    const {
        valueLookup: instructorTypeLookup,
        valueOptions: instructorTypeOptions,
    } = getOptionsAndLookupForSelectInput<InstructorTypeItemData>(
        typeList,
        (status: InstructorTypeItemData) => ({
            label: status.instructor_type,
            id: status.pk as string,
        }),
    );

    const {
        citiesOptions,
        citiesOptionLookup,
        citiesEntityLookup,
    } = locationList.reduce(
        (acc, city) => {
            const option = {
                label: getCityString(city, false),
                id: getCityString(city),
            };
            acc.citiesEntityLookup[option.id] = city;
            acc.citiesOptionLookup[option.id] = option;

            const shouldAddOptionToList = !(
                citySearchText && option.id === preselectedCityId
            );

            if (shouldAddOptionToList) {
                acc.citiesOptions.push(option);
            }

            return acc;
        },
        {
            citiesOptions: [] as Array<Select.Option>,
            citiesOptionLookup: {} as {
                [key: string]: Select.Option;
            },
            citiesEntityLookup: {} as {
                [key: string]: LocationItemData;
            },
        },
    );

    const handleLocationDelayedFilteringChange = (
        e: CustomDetailEvent<Select.DelayedFilteringChangeDetail>,
    ) => {
        if (e.detail.value !== citySearchText) {
            dispatch(setSearchText(e.detail.value));
            dispatch(getLocationsList());
        }
    };

    const handleLocationChange = (
        e: CustomDetailEvent<Select.ChangeDetail>,
    ) => {
        if (e.detail.selectedOption) {
            dispatch(setSearchText(null));
            const {
                city,
                state: state_province,
                country,
                geo,
                region: instructor_region,
                city_timezone,
            } = citiesEntityLookup[e.detail.selectedOption.id];
            handleFieldEvent({
                city,
                state_province,
                country,
                geo,
                instructor_region,
                city_timezone,
            });
        }
    };

    const instructorDetailsProps: Omit<
        InstructorFormSectionProps<InstructorProfileData>,
        'validateAndHandleFieldEvent'
    > = {
        formValues,
        errors,
        handleFieldEvent,
        handleProviderItemEvent,
        handleSponsoringCompanyItemEvent,
        providerAttributeEditorItems,
        sponsoringCompanyItem,
        controlArrayErrors,
        mode: FormSectionMode.InlineEdit,
    };

    return (
        <FormSection
            data-testid="BasicInfoFormSection"
            header="Basic information"
        >
            <ColumnLayout>
                <div data-awsui-column-layout-root="true">
                    <FormField
                        label="First name"
                        errorText={errors?.first_name}
                    >
                        <Input
                            className={
                                errors?.first_name && FORM_ERROR_SELECTOR
                            }
                            value={formValues.first_name}
                            onInput={(e) =>
                                handleFieldEvent({
                                    first_name: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorFirstName`}
                        />
                    </FormField>
                    <FormField label="Last name" errorText={errors?.last_name}>
                        <Input
                            className={errors?.last_name && FORM_ERROR_SELECTOR}
                            value={formValues.last_name}
                            onInput={(e) =>
                                handleFieldEvent({
                                    last_name: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorLastName`}
                        />
                    </FormField>
                    <FormField
                        label="Primary email address"
                        errorText={errors?.email}
                    >
                        <Input
                            className={errors?.email && FORM_ERROR_SELECTOR}
                            value={formValues.email}
                            onInput={(e) =>
                                handleFieldEvent({
                                    email: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorEmail`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                AWS LMS email <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.aws_lms_email}
                    >
                        <Input
                            className={
                                errors?.aws_lms_email && FORM_ERROR_SELECTOR
                            }
                            value={formValues.aws_lms_email}
                            onInput={(e) =>
                                handleFieldEvent({
                                    aws_lms_email: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorAwsLmsEmail`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Phone number <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.phone_number}
                    >
                        <Input
                            className={
                                errors?.phone_number && FORM_ERROR_SELECTOR
                            }
                            value={formValues.phone_number}
                            onInput={(e) =>
                                handleFieldEvent({
                                    phone_number: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorPhoneNumber`}
                        />
                    </FormField>
                    <FormField
                        label="City, State"
                        errorText={
                            errors?.city ||
                            errors?.instructor_region ||
                            errors?.geo ||
                            errors?.country
                        }
                    >
                        <Select
                            className={
                                (errors?.city ||
                                    errors?.instructor_region ||
                                    errors?.geo ||
                                    errors?.country) &&
                                FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isLocationListLoading
                                    ? LocationFormFieldText.Loading
                                    : LocationFormFieldText.Placeholder
                            }
                            empty={LocationFormFieldText.Empty}
                            loadingText={LocationFormFieldText.Loading}
                            filteringType="manual"
                            errorText={LocationFormFieldText.Error}
                            recoveryText={LocationFormFieldText.Recovery}
                            statusType={getStatusType(
                                isLocationListLoading,
                                isLocationListLoaded,
                                locationListError,
                            )}
                            options={citiesOptions}
                            selectedOption={(() => {
                                const cityString = getCityString(formValues);
                                return cityString
                                    ? citiesOptionLookup[cityString]
                                    : null;
                            })()}
                            filteringPlaceholder={
                                LocationFormFieldText.FilteringPlaceholder
                            }
                            onDelayedFilteringChange={
                                handleLocationDelayedFilteringChange
                            }
                            onChange={handleLocationChange}
                            onRecoveryClick={() => {
                                dispatch(setSearchText(''));
                                dispatch(resetPartialLocationsSlice());
                                dispatch(getLocationsList());
                            }}
                            data-testid={`${mode}InstructorCity`}
                            disabled={
                                mode === FormSectionMode.Edit &&
                                !isLocationListLoaded
                            }
                        />
                    </FormField>
                    {formValues.country && (
                        <FormField label="Country">
                            <Input
                                disabled={true}
                                value={formValues.country}
                                data-testid={`${mode}InstructorCountry`}
                            />
                        </FormField>
                    )}
                    {formValues.instructor_region && (
                        <FormField label="Region">
                            <Input
                                disabled={true}
                                value={formValues.instructor_region}
                                data-testid={`${mode}InstructorRegion`}
                            />
                        </FormField>
                    )}
                    {formValues.city_timezone && (
                        <FormField label="Timezone">
                            <Input
                                disabled={true}
                                value={formValues.city_timezone}
                                data-testid={`${mode}InstructorCityTimezone`}
                            />
                        </FormField>
                    )}
                    <FormField
                        className={
                            errors?.address_line_1 && FORM_ERROR_SELECTOR
                        }
                        label={
                            <span>
                                Address 1 <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.address_line_1}
                    >
                        <Input
                            value={formValues.address_line_1}
                            onInput={(e) =>
                                handleFieldEvent({
                                    address_line_1: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorAddress1`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Address 2 <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.address_line_2}
                    >
                        <Input
                            className={
                                errors?.address_line_2 && FORM_ERROR_SELECTOR
                            }
                            value={formValues.address_line_2}
                            onInput={(e) =>
                                handleFieldEvent({
                                    address_line_2: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorAddress2`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                ZIP/postal code <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.postal_code}
                    >
                        <Input
                            className={
                                errors?.postal_code && FORM_ERROR_SELECTOR
                            }
                            value={formValues.postal_code}
                            onInput={(e) =>
                                handleFieldEvent({
                                    postal_code: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorZip`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.instructor_status}
                        label="Instructor status"
                    >
                        <Select
                            className={
                                errors?.instructor_status && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isStatusListLoading
                                    ? statusesLoadingText
                                    : 'Select an instructor status'
                            }
                            options={instructorStatusOptions}
                            selectedOption={
                                instructorStatusLookup[
                                    formValues.instructor_status
                                ]
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    instructor_status:
                                        e.detail.selectedOption.label,
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetInstructorStatusesSlice());
                                dispatch(getInstructorStatusesList());
                            }}
                            loadingText={statusesLoadingText}
                            errorText="An error occurred while loading instructor statuses"
                            recoveryText="Retry"
                            empty="No instructor statuses found"
                            statusType={getStatusType(
                                isStatusListLoading,
                                isStatusListLoaded,
                                statusListError,
                            )}
                            disabled={
                                mode === FormSectionMode.Edit &&
                                isStatusListLoading
                            }
                            data-testid={`${mode}InstructorStatus`}
                        />
                    </FormField>
                    <FormField
                        errorText={errors?.instructor_type}
                        label="Instructor type"
                    >
                        <Select
                            className={
                                errors?.instructor_type && FORM_ERROR_SELECTOR
                            }
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isTypeListLoading
                                    ? typesLoadingText
                                    : 'Select an instructor type'
                            }
                            options={instructorTypeOptions}
                            selectedOption={
                                instructorTypeLookup[formValues.instructor_type]
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    instructor_type:
                                        e.detail.selectedOption.label,
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetInstructorTypesSlice());
                                dispatch(getInstructorTypesList());
                            }}
                            loadingText={typesLoadingText}
                            errorText="An error occurred while loading instructor types"
                            recoveryText="Retry"
                            empty="No instructor types found"
                            statusType={getStatusType(
                                isTypeListLoading,
                                isTypeListLoaded,
                                typeListError,
                            )}
                            data-testid={`${mode}InstructorType`}
                        />
                    </FormField>
                    {mode === FormSectionMode.Edit &&
                        getIsExternalInstructor(
                            instructor?.instructor
                                .instructor_type as InstructorTypeValues,
                        ) !==
                            getIsExternalInstructor(
                                formValues.instructor_type as InstructorTypeValues,
                            ) && (
                            <InstructorDetailsFormSection
                                {...instructorDetailsProps}
                            />
                        )}
                    <FormField
                        label="Onboarding date"
                        errorText={errors?.onboarding_date}
                        stretch={true}
                    >
                        <DatePicker
                            className={
                                errors?.onboarding_date && FORM_ERROR_SELECTOR
                            }
                            placeholder="YYYY/MM/DD"
                            todayLabel="Today"
                            nextMonthLabel="Next month"
                            previousMonthLabel="Previous month"
                            value={
                                formValues.onboarding_date
                                    ? getISOString(formValues.onboarding_date)
                                    : ''
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    onboarding_date: getMilliseconds(
                                        e.detail.value,
                                    ),
                                })
                            }
                            data-testid={`${mode}InstructorOnboardingDate`}
                        />
                    </FormField>
                    <FormField errorText={errors?.programs} label="Programs">
                        <Multiselect
                            className={errors?.programs && FORM_ERROR_SELECTOR}
                            options={programOptions}
                            checkboxes={true}
                            placeholder={
                                mode === FormSectionMode.Edit &&
                                isProgramListLoading
                                    ? ProgramFormFieldText.Loading
                                    : ProgramFormFieldText.Placeholder
                            }
                            selectedOptions={
                                isProgramListLoaded && !isProgramListLoading
                                    ? formValues?.programs.reduce(
                                          (acc, program) => {
                                              if (programLookup[program]) {
                                                  acc.push(
                                                      programLookup[program],
                                                  );
                                              }
                                              return acc;
                                          },
                                          [] as Select.Option[],
                                      )
                                    : null
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    programs: e.detail.selectedOptions.map(
                                        (option) => option.label,
                                    ),
                                })
                            }
                            onRecoveryClick={() => {
                                dispatch(resetProgramTypesSlice());
                                dispatch(getProgramTypesList());
                            }}
                            loadingText={ProgramFormFieldText.Loading}
                            errorText={ProgramFormFieldText.Error}
                            recoveryText={ProgramFormFieldText.Recovery}
                            empty={ProgramFormFieldText.Empty}
                            statusType={getStatusType(
                                isProgramListLoading,
                                isProgramListLoaded,
                                programListError,
                            )}
                            disabled={
                                mode === FormSectionMode.Edit &&
                                isProgramListLoading
                            }
                            data-testid={`${mode}InstructorPrograms`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Willing to travel <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.prefer_travel}
                    >
                        <Select
                            className={
                                errors?.prefer_travel && FORM_ERROR_SELECTOR
                            }
                            placeholder="Select a travel preference"
                            options={booleanFieldOptions}
                            selectedOption={
                                formValues.prefer_travel !== undefined &&
                                formValues.prefer_travel !== null
                                    ? booleanFieldOptionLookup[
                                          formValues.prefer_travel.toString() as
                                              | 'true'
                                              | 'false'
                                      ]
                                    : null
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    prefer_travel:
                                        booleanFieldOptionsBoolLookup[
                                            e.detail.selectedOption.label as
                                                | 'Yes'
                                                | 'No'
                                        ],
                                })
                            }
                            data-testid={`${mode}InstructorPreferTravel`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Preferred airport code <i>- optional</i>
                            </span>
                        }
                        errorText={errors?.preferred_airport_code}
                    >
                        <Input
                            className={
                                errors?.preferred_airport_code &&
                                FORM_ERROR_SELECTOR
                            }
                            value={formValues.preferred_airport_code}
                            onInput={(e) =>
                                handleFieldEvent({
                                    preferred_airport_code: e.detail.value,
                                })
                            }
                            data-testid={`${mode}InstructorPreferredAirport`}
                        />
                    </FormField>
                    <FormField
                        label={
                            <span>
                                Final approval <i>- optional</i>
                            </span>
                        }
                        stretch={true}
                    >
                        <DatePicker
                            className={
                                errors?.final_approval_date &&
                                FORM_ERROR_SELECTOR
                            }
                            placeholder="YYYY/MM/DD"
                            todayLabel="Today"
                            nextMonthLabel="Next month"
                            previousMonthLabel="Previous month"
                            value={
                                formValues.final_approval_date
                                    ? getISOString(
                                          formValues.final_approval_date,
                                      )
                                    : ''
                            }
                            onChange={(e) =>
                                handleFieldEvent({
                                    final_approval_date: getMilliseconds(
                                        e.detail.value,
                                    ),
                                })
                            }
                            data-testid={`${mode}InstructorFinalApproval`}
                        />
                    </FormField>
                </div>
            </ColumnLayout>
        </FormSection>
    );
};

export default BasicInfoFormSection;
