import React, { useCallback, useEffect, useState } from 'react';
import { Button, ColumnLayout, Wizard } from '@amzn/awsui-components-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    InstructorProfileData,
    Provider,
} from '../../../interfaces/instructorProfile';
import BasicInfoFormSection, {
    getCityString,
} from '../FormSections/BasicInfoFormSection';
import {
    formatAddress,
    formatInstructorStatus,
    getInstructorProviderValues,
    getInternalInstructorContent,
    getIsExternalInstructor,
    InstructorControlArrayFormValues,
    InstructorFormSectionProps,
    InstructorTypeValues,
    ProviderAttributeEditorItem,
    providerValidationConfig,
} from '../Common/Common';
import InstructorDetailsFormSection from '../FormSections/InstructorDetailsFormSection';
import { FORM_ERROR_SELECTOR } from '../FormSections/FormSections.common';
import Section, {
    ColumnContentData,
    SectionProps,
} from '../../../../common/components/Section/Section';
import {
    EMPTY_STRING,
    SectionContentType,
} from '../../../../common/constants/grimsby';
import {
    createInstructor,
    selectIsLoading,
    setIsNewInstructor,
} from '../../../store/slices/selectedInstructorSlice';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import useFormValidation, {
    ValidationType,
} from '../../../../common/utils/formValidation';
import { FormSectionMode } from '../../../../common/constants/forms';
import formatDate from '../../../../common/utils/formatDate';
import { StatusCode } from '../../../../common/constants/statusCode';
import { CreateInstructorActionReturnValue } from '../../../interfaces/createInstructorActionReturnValue';
import ExternalInstructorDetails from '../Details/ExternalInstructorDetails/ExternalInstructorDetails';
import { formatStringArray } from '../../../../common/utils/formatStringArray';
import { formatString } from '../../../../common/utils/formatString';
import { INSTRUCTOR_VALIDATION_FIELDS } from '../Common/Validations';

const mode = FormSectionMode.Create;

export const NOTIFICATION_MESSAGE = {
    SUCCESS: 'You have successfully created an instructor.',
    ERROR_CONFLICT: 'An instructor record with the same email already exists.',
    ERROR_DEFAULT: 'An error occurred while creating the instructor.',
} as const;

const InstructorForm = ({
    initialFormState,
}: {
    initialFormState: Partial<InstructorProfileData>;
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isLoading = useSelector(selectIsLoading);
    const { addNotification } = useNotifications();
    const [formValues, setFormValues] = useState(
        initialFormState as InstructorProfileData,
    );
    const { sponsoringCompany, providers } = getInstructorProviderValues(
        formValues.providers || [],
    );
    const [
        providerAttributeEditorItems,
        setProviderAttributeEditorItems,
    ] = useState(
        providers.map((provider) => ({
            awsClassroom: provider.aws_classrooms_email,
            providerName: provider.provider_name,
            pk: provider.pk,
        })) as Array<ProviderAttributeEditorItem>,
    );
    const [sponsoringCompanyItem, setSponsoringCompanyItem] = useState({
        awsClassroom: sponsoringCompany?.aws_classrooms_email,
        providerName: sponsoringCompany?.provider_name,
        pk: sponsoringCompany?.pk,
    } as ProviderAttributeEditorItem);
    const {
        isInvalid,
        isControlArrayInvalid,
        errors,
        controlArrayErrors,
        validateForm,
        validateFormControlArray,
    } = useFormValidation<
        Partial<InstructorProfileData>,
        InstructorControlArrayFormValues
    >();
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const i18nStrings = {
        stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
        collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
            `Step ${stepNumber} of ${stepsCount}`,
        cancelButton: 'Cancel',
        previousButton: 'Previous',
        nextButton: 'Next',
        submitButton: 'Add instructor',
        optional: 'optional',
    };
    const handleFieldEvent = (changes: Partial<InstructorProfileData>) =>
        setFormValues((values) => ({
            ...values,
            ...changes,
        }));
    const handleProviderItemEvent = (
        providerItems: Array<ProviderAttributeEditorItem>,
    ) => {
        setProviderAttributeEditorItems(providerItems);
    };
    const handleSponsoringCompanyItemEvent = (
        sponsoringCompanyItem: Partial<ProviderAttributeEditorItem>,
    ) => {
        setSponsoringCompanyItem((previousValue) => ({
            ...previousValue,
            ...sponsoringCompanyItem,
        }));
    };
    const handleScrollToError = () => {
        // this may require attention later for consistent experience in all browsers
        const topMostError = document.querySelector(`.${FORM_ERROR_SELECTOR}`);

        topMostError?.scrollIntoView({
            behavior: 'smooth',
        });
    };
    const handleWizardNavigation = (e: CustomEvent<Wizard.StepClickDetail>) => {
        e.preventDefault();
        const invalid = validateForm(formValues, getValidationConfig());
        const isExternal = getIsExternalInstructor(
            formValues.instructor_type as InstructorTypeValues,
        );
        const controlArrayInvalid =
            isExternal && activeStepIndex !== 0
                ? validateFormControlArray(
                      {
                          providerAttributeEditorItems,
                          sponsoringCompanyItems: [sponsoringCompanyItem],
                      },
                      providerValidationConfig,
                  )
                : false;

        const isInvalid = invalid || controlArrayInvalid;

        if (!isInvalid) {
            setActiveStepIndex(e.detail.requestedStepIndex);
        } else {
            handleScrollToError();
        }
    };
    const getValidationConfig = useCallback((): {
        [key in ValidationType]?: Array<keyof InstructorProfileData>;
    } => {
        if (activeStepIndex === 0) {
            return {
                required: INSTRUCTOR_VALIDATION_FIELDS.BASIC_REQUIRED,
                email: INSTRUCTOR_VALIDATION_FIELDS.BASIC_EMAIL,
            };
        } else {
            if (
                getIsExternalInstructor(
                    formValues.instructor_type as InstructorTypeValues,
                )
            ) {
                let required = [
                    ...INSTRUCTOR_VALIDATION_FIELDS.EXTERNAL_REQUIRED,
                    ...(formValues.is_freelancer
                        ? INSTRUCTOR_VALIDATION_FIELDS.EXTERNAL_FREELANCER_REQUIRED
                        : []),
                ];
                let email = [
                    ...INSTRUCTOR_VALIDATION_FIELDS.EXTERNAL_EMAIL,
                    ...(formValues.is_freelancer
                        ? INSTRUCTOR_VALIDATION_FIELDS.EXTERNAL_FREELANCER_EMAIL
                        : []),
                ];

                return {
                    required,
                    email: email,
                };
            } else {
                return {
                    required: INSTRUCTOR_VALIDATION_FIELDS.INTERNAL_REQUIRED,
                    email: INSTRUCTOR_VALIDATION_FIELDS.INTERNAL_EMAIL,
                };
            }
        }
    }, [activeStepIndex, formValues.instructor_type, formValues.is_freelancer]);

    const props: Omit<
        InstructorFormSectionProps<InstructorProfileData>,
        'validateAndHandleFieldEvent'
    > = {
        formValues,
        errors,
        handleFieldEvent,
        mode,
        handleProviderItemEvent,
        handleSponsoringCompanyItemEvent,
        providerAttributeEditorItems,
        sponsoringCompanyItem,
        controlArrayErrors,
    };
    useEffect(() => {
        if (isInvalid) {
            validateForm(formValues, getValidationConfig());
        }

        if (isControlArrayInvalid) {
            validateFormControlArray(
                {
                    providerAttributeEditorItems,
                    sponsoringCompanyItems: [sponsoringCompanyItem],
                },
                providerValidationConfig,
            );
        }
    }, [
        dispatch,
        formValues,
        getValidationConfig,
        isControlArrayInvalid,
        isInvalid,
        providerAttributeEditorItems,
        sponsoringCompanyItem,
        validateForm,
        validateFormControlArray,
    ]);

    const basicInfoReviewSettings: SectionProps = {
        testId: 'BasicInfoReviewSection',
        className: 'awsui-util-mb-l',
        header: {
            label: 'Contact information',
            buttons: null,
        },
        content: {
            type: SectionContentType.Column,
            columnsCount: 3 as ColumnLayout.Columns,
            columns: [
                [
                    {
                        key: 'Name',
                        value: `${formValues.first_name} ${formValues.last_name}`,
                    },
                    {
                        key: 'LMS email address',
                        value: formatString(formValues.aws_lms_email),
                    },
                    {
                        key: 'Country',
                        value: formValues.country,
                    },
                    {
                        key: 'Instructor status',
                        value: formatInstructorStatus(
                            formValues.instructor_status,
                        ),
                    },
                    {
                        key: 'Onboarding date',
                        value:
                            formValues.onboarding_date !== null
                                ? formatString(
                                      formatDate(formValues.onboarding_date),
                                  )
                                : EMPTY_STRING,
                    },
                ],
                [
                    {
                        key: 'Primary Email address',
                        value: formValues.email,
                    },
                    {
                        key: 'Address',
                        value: formatAddress(
                            formValues.address_line_1,
                            formValues.address_line_2,
                            null,
                            null,
                            null,
                        ),
                    },
                    {
                        key: 'Region',
                        value: formValues.instructor_region,
                    },
                    {
                        key: 'Programs',
                        value: formatStringArray(formValues.programs),
                    },
                ],
                [
                    {
                        key: 'Phone number',
                        value: formatString(formValues.phone_number),
                    },
                    {
                        key: 'City, State',
                        value: getCityString(formValues, false),
                    },
                    {
                        key: 'Geo',
                        value: formValues.geo,
                    },
                    {
                        key: 'Timezome',
                        value: formValues.city_timezone,
                    },
                    {
                        key: 'Instructor type',
                        value: formatString(formValues.instructor_type),
                    },
                ],
            ],
        } as ColumnContentData,
    };

    const internalInstructorDetailsReviewSettings: SectionProps = {
        testId: 'InternalInstructorDetailsReviewSection',
        header: {
            label: 'Internal instructor details',
            buttons: null,
        },
        content: getInternalInstructorContent(formValues),
    };

    const steps: Array<Wizard.Step> = [
        {
            title: 'Basic information',
            content: () => <BasicInfoFormSection {...props} />,
        },
        {
            title: 'Employment information',
            content: () => <InstructorDetailsFormSection {...props} />,
        },
        {
            title: 'Review and add instructor',
            content: () => (
                <>
                    <div className="awsui-util-action-stripe-large">
                        <div className="awsui-util-action-stripe-title">
                            <h3>Step 1: Basic information</h3>
                        </div>
                        <div
                            data-testid="EditBasicInstructorInformation"
                            className="awsui-util-action-stripe-group"
                        >
                            <Button
                                data-testid="EditBasicInstructorInformationButton"
                                onClick={() => setActiveStepIndex(0)}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                    <Section {...basicInfoReviewSettings} />
                    <div className="awsui-util-action-stripe-large">
                        <div className="awsui-util-action-stripe-title">
                            <h3>Step 2: Employment information</h3>
                        </div>
                        <div
                            data-testid="EditInstructorInformation"
                            className="awsui-util-action-stripe-group"
                        >
                            <Button
                                data-testid="EditInstructorInformationButton"
                                onClick={() => setActiveStepIndex(1)}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                    {getIsExternalInstructor(
                        formValues.instructor_type as InstructorTypeValues,
                    ) ? (
                        <ExternalInstructorDetails
                            profile={getInstructorProfileData()}
                            showEditButton={false}
                            columns={4}
                        />
                    ) : (
                        <Section {...internalInstructorDetailsReviewSettings} />
                    )}
                </>
            ),
        },
    ];

    const getInstructorProfileData = (): InstructorProfileData => {
        const isExternal = getIsExternalInstructor(
            formValues.instructor_type as InstructorTypeValues,
        );
        return {
            ...formValues,
            ...(isExternal
                ? {
                      providers: [
                          ...providerAttributeEditorItems.map(
                              (providerItem) =>
                                  ({
                                      aws_classrooms_email:
                                          providerItem.awsClassroom,
                                      provider_name: providerItem.providerName,
                                      is_sponsor_company: false,
                                      pk: providerItem.pk,
                                  } as Provider),
                          ),
                          {
                              aws_classrooms_email:
                                  sponsoringCompanyItem.awsClassroom,
                              provider_name: sponsoringCompanyItem.providerName,
                              is_sponsor_company: true,
                              pk: sponsoringCompanyItem.pk,
                          } as Provider,
                      ],
                  }
                : {}),
        };
    };

    const handleSubmitButtonClick = async () => {
        const instructorData = getInstructorProfileData();

        const {
            isSuccessful,
            createdInstructorId,
            statusCode,
        }: CreateInstructorActionReturnValue = await dispatch<any>(
            createInstructor(instructorData),
        );

        addNotification({
            id: `create-instructor-${Date.now()}`,
            ...(isSuccessful
                ? {
                      type: 'success',
                      content: NOTIFICATION_MESSAGE.SUCCESS,
                  }
                : {
                      type: 'error',
                      content:
                          statusCode === StatusCode.Conflict
                              ? NOTIFICATION_MESSAGE.ERROR_CONFLICT
                              : NOTIFICATION_MESSAGE.ERROR_DEFAULT,
                  }),
        });

        if (isSuccessful) {
            dispatch(setIsNewInstructor(true));
            history.push({
                pathname: `/instructors/${createdInstructorId}`,
            });
        }
    };

    return (
        <form data-testid="InstructorForm">
            <Wizard
                data-testid="InstructorFormWizard"
                steps={steps}
                activeStepIndex={activeStepIndex}
                i18nStrings={i18nStrings}
                onNextButtonClick={handleWizardNavigation}
                onStepNavigationClick={handleWizardNavigation}
                isLoadingNextStep={isLoading}
                onCancelButtonClick={() =>
                    history.push({
                        pathname: `/instructors`,
                    })
                }
                onPreviousButtonClick={(e) => {
                    e.preventDefault();
                    setActiveStepIndex(e.detail.requestedStepIndex);
                }}
                onSubmitButtonClick={handleSubmitButtonClick}
            />
        </form>
    );
};

export default InstructorForm;
