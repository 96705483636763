import React, { memo } from 'react';
import {
    Alert,
    Box,
    Modal,
    SpaceBetween,
    Button,
    Link,
    ColumnLayout,
    Table,
} from '@amzn/awsui-components-react-v3';
import { updateSelectedActivity } from '../../../../store/slices/selectedActivitySlice';
import {
    ActivityData,
    Invoice,
    LineItem,
} from '../../../../interfaces/activity';
import Section, {
    GenericContentData,
    SectionProps,
    TieredColumnContentData,
} from '../../../../../common/components/Section/Section';
import {
    EMPTY_STRING,
    INVOICE_PAYMENT_TERM_KEYS,
    SectionContentType,
} from '../../../../../common/constants/grimsby';
import { formatString } from '../../../../../common/utils/formatString';
import TableHeader from '../../../../../common/components/TableHeader/TableHeader';
import Can from '../../../../../common/components/Can';
import { Actions } from '../../../../../common/constants/auth';
import { formatYesNoBoolean } from '../../../../../imt/components/Instructor/Common/Common';
import { formatStatusHelper } from '../../../../../common/utils/formatHelper';
import { formatStringArray } from '../../../../../common/utils/formatStringArray';
import { currencyFormatter } from '../../Common/Common';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useNotifications } from '../../../../../common/context/grimsbyNotifications';

enum BillingStatus {
    ToBeBilled = 'To be billed',
    ToBeAccrued = 'To be accrued',
    Accrued = 'Accrued',
    Billed = 'Billed',
}

const getBillingStatusComponent = formatStatusHelper([
    {
        status: BillingStatus.ToBeAccrued,
        statusClass: 'awsui-util-status-inactive',
        iconClass: 'status-pending',
        label: BillingStatus.ToBeAccrued,
    },
    {
        status: BillingStatus.ToBeBilled,
        statusClass: 'awsui-util-status-inactive',
        iconClass: 'status-pending',
        label: BillingStatus.ToBeBilled,
    },
    {
        status: BillingStatus.Accrued,
        statusClass: 'awsui-util-status-positive',
        iconClass: 'status-positive',
        label: BillingStatus.Accrued,
    },
    {
        status: BillingStatus.Billed,
        statusClass: 'awsui-util-status-positive',
        iconClass: 'status-positive',
        label: BillingStatus.Billed,
    },
]);

const revenueColumnDefinitions = (invoice: Invoice) => {
    return [
        {
            id: 'revenueType',
            header: 'Line item type',
            cell: (item: LineItem) => formatString(item.item_type),
        },
        {
            id: 'amount',
            header: 'Amount',
            cell: (item: LineItem) =>
                item.item_amount === undefined
                    ? EMPTY_STRING
                    : currencyFormatter(item.item_amount, invoice.currency),
        },
    ];
};

const getBillingAddress = (invoice: Invoice) => (
    <>
        {!!invoice.bill_to_address_1 && <p>{invoice.bill_to_address_1}</p>}
        {!!invoice.bill_to_address_2 && (
            <p>{`${invoice.bill_to_address_2},`}</p>
        )}
        <p>
            {formatStringArray([
                invoice.bill_to_city,
                invoice.bill_to_state,
                invoice.bill_to_country,
                invoice.bill_to_postal_code,
            ])}
        </p>
    </>
);

const getInvoiceView = (
    displayEditButton: boolean,
    invoice: Invoice,
    index: number,
    handleEditInvoice: any,
    handleRemoveInvoice: any,
) => {
    let invoiceColumns = [
        [
            {
                key: 'Legal entity name',
                value: formatString(invoice.legal_entity_name),
            },
            {
                key: 'OFA invoice URL',
                value: invoice.ofa_invoice_number ? (
                    <Link
                        href={`https://awsclipper.aka.corp.amazon.com/downloadInvoice?mastInvoiceNumber[]=${invoice.ofa_invoice_number}`}
                        target="_blank"
                        data-testid="class-sim-link"
                    >
                        {formatString(invoice.ofa_invoice_number)}
                    </Link>
                ) : (
                    EMPTY_STRING
                ),
            },
            {
                key: 'Customer AWS account ID',
                value: formatString(invoice.customer_aws_account_id),
            },
        ],
        [
            {
                key: 'SFDC opportunity ID',
                value: invoice.sfdc_opportunity_id ? (
                    <Link
                        href={`https://aws-crm.my.salesforce.com/${invoice.sfdc_opportunity_id}`}
                        target="_blank"
                        data-testid="sfdc-link"
                    >
                        {formatString(invoice.sfdc_opportunity_id)}
                    </Link>
                ) : (
                    EMPTY_STRING
                ),
            },
            {
                key: 'Currency',
                value: formatString(invoice.currency),
            },
            {
                key: 'Payment terms',
                value: formatString(
                    INVOICE_PAYMENT_TERM_KEYS.get(invoice.payment_terms),
                ),
            },
        ],
        [
            {
                key: 'PO number',
                value: formatString(invoice.po_number),
            },
            {
                key: 'Number of students',
                value: invoice.number_of_participants ?? EMPTY_STRING,
            },
            {
                key: 'RFF ID',
                value: invoice.request_for_funds_id ?? EMPTY_STRING,
            },
        ],
        [
            {
                key: 'Billing status',
                value: invoice.billing_status
                    ? getBillingStatusComponent(invoice.billing_status)
                    : EMPTY_STRING,
            },
            {
                key: 'Prepay',
                value: formatYesNoBoolean(invoice.prepay),
            },
            {
                key: 'Is reseller?',
                value: formatYesNoBoolean(invoice.is_reseller),
            },
        ],
    ];

    switch (invoice.bill_to_country) {
        case 'India':
            invoiceColumns[0].push({
                key: 'Special economic zone (SEZ)',
                value: formatString(
                    invoice.customer_sez
                        ? 'Yes'
                        : invoice.customer_sez_with_tax
                        ? 'With tax'
                        : 'None',
                ),
            });
            invoiceColumns[1].push({
                key: 'Customer non-body corporate',
                value: formatString(
                    invoice.customer_non_body_corporate ? 'Yes' : 'No',
                ),
            });
            invoiceColumns[2].push({
                key: 'Goods and services tax (GST) ID',
                value: formatString(invoice.gst_tax_id),
            });
            invoiceColumns[3].push({
                key: 'Customer event agency',
                value: formatString(
                    invoice.customer_event_agency ? 'Yes' : 'No',
                ),
            });
            break;
        case 'Italy':
            invoiceColumns[2].push({
                key: 'SDI email address',
                value: formatString(invoice.sdi_email_address),
            });
            invoiceColumns[3].push({
                key: 'SDI account ID',
                value: formatString(invoice.sdi_account_id),
            });
            break;
        case 'Canada':
            invoiceColumns[2].push({
                key: 'GST/HST applicable',
                value: formatString(invoice.gst_hst_applicable ? 'Yes' : 'No'),
            });
            invoiceColumns[3].push({
                key: 'PST applicable',
                value: formatString(invoice.pst_applicable ? 'Yes' : 'No'),
            });
            break;
        default:
            break;
    }

    const invoiceSectionProps: SectionProps = {
        testId: `ActivityDetailsInvoice${index}`,
        className: 'awsui-util-mb-n',
        header: {
            label: invoice.sfdc_opportunity_id
                ? `${invoice.customer_name} (${invoice.sfdc_opportunity_id}) invoice`
                : `${invoice.customer_name} invoice`,
            buttons: displayEditButton ? (
                <Can
                    perform={Actions.ACTIVITY_MODIFY}
                    yes={() => (
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    data-testid={`DeleteInvoice${index}`}
                                    onClick={() => handleRemoveInvoice()}
                                >
                                    Delete
                                </Button>
                                <Button
                                    data-testid={`EditInvoice${index}`}
                                    onClick={() => handleEditInvoice(index)}
                                >
                                    Edit
                                </Button>
                            </SpaceBetween>
                        </Box>
                    )}
                    no={() => null}
                />
            ) : null,
        },
        content: {
            type: SectionContentType.TieredColumn,
            topContent: {
                columnsCount: 4,
                columns: invoiceColumns,
            },
            bottomContent: {
                tierTitle: 'Billing information',
                columnsCount: 4,
                columns: [
                    [
                        {
                            key: 'Bill to AWS account ID',
                            value: formatString(invoice.bill_to_aws_account_id),
                        },
                        {
                            key: 'Bill to tax ID',
                            value: formatString(invoice.bill_to_tax_id),
                        },
                    ],
                    [
                        {
                            key: 'Bill to contact person',
                            value: formatStringArray(
                                [
                                    invoice.bill_to_first_name,
                                    invoice.bill_to_last_name,
                                ],
                                ' ',
                            ),
                        },
                        {
                            key: 'Bill to email',
                            value: invoice.bill_to_email ? (
                                <a
                                    data-testid="BillToEmail"
                                    href={`mailto:${invoice.bill_to_email}`}
                                >
                                    {invoice.bill_to_email}
                                </a>
                            ) : (
                                EMPTY_STRING
                            ),
                        },
                    ],
                    [
                        {
                            key: 'Billing address',
                            value: getBillingAddress(invoice),
                        },
                    ],
                    [
                        {
                            key: 'Custom invoice note',
                            value: formatString(invoice.customer_invoice_notes),
                        },
                    ],
                ],
            },
        } as TieredColumnContentData,
    };

    return (
        <div className="awsui-util-mb-l" key={index}>
            <Section {...invoiceSectionProps} />
            {!!invoice.line_items?.length && (
                <Table
                    data-testid={`InvoiceLineItemsTable${index}`}
                    columnDefinitions={revenueColumnDefinitions(invoice)}
                    items={[
                        ...invoice.line_items,
                        {
                            item_type: 'Total',
                            item_amount: invoice.billed_amount,
                            item_currency: invoice.currency,
                        },
                    ]}
                    header={
                        <TableHeader
                            title="Revenue Items"
                            actions={<React.Fragment />}
                            count={invoice.line_items?.length}
                        />
                    }
                />
            )}
        </div>
    );
};

const InvoicesTab = memo(({ activity }: { activity: ActivityData }) => {
    const [activeRemoveIndex, setActiveRemoveIndex] = React.useState<
        number | null
    >(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();

    const handleEditInvoice = (index: number) => {
        history.push({
            pathname: `/activities/${activity.pk}/edit-invoice/${index}`,
        });
    };

    const handleRemoveInvoice = async () => {
        // remove from array and save
        if (activeRemoveIndex === null || !activity?.pk) {
            setActiveRemoveIndex(null);
            return;
        }

        let updatedInvoices = [...activity.billing_invoices];

        updatedInvoices.splice(activeRemoveIndex, 1);

        const isSuccessful = await dispatch<any>(
            updateSelectedActivity(activity.pk, {
                ...activity,
                billing_invoices: updatedInvoices,
            }),
        );

        addNotification({
            id: `customer-tab-${Date.now()}`,
            ...(isSuccessful
                ? {
                      type: 'success',
                      content: 'The invoice has been removed.',
                  }
                : {
                      type: 'error',
                      content: 'An error occurred while removing the invoice.',
                  }),
        });

        if (isSuccessful) {
            history.push({
                pathname: `/activities/${activity.pk}`,
                search: 'tabId=invoices',
            });
        }
    };

    const addInvoiceButtonComponent = (
        <Can
            perform={Actions.ACTIVITY_MODIFY}
            yes={() => (
                <Button
                    data-testid="ActivityDetailsAddInvoice"
                    onClick={() =>
                        history.push({
                            pathname: `/activities/${activity.pk}/create-invoice`,
                        })
                    }
                >
                    Add invoice
                </Button>
            )}
            no={() => null}
        />
    );

    const emptyInvoicesSectionProps: SectionProps = {
        testId: 'ActivityDetailsEmptyInvoicesSection',
        header: {
            label: 'Invoices',
        },
        content: {
            type: SectionContentType.Generic,
            children: (
                <div className="awsui-util-t-c awsui-util-status-inactive">
                    <div className="awsui-util-pt-s awsui-util-mb-xs">
                        <b>No invoices</b>
                    </div>
                    <p className="awsui-util-mb-s">
                        Invoices will display here.
                    </p>
                    {addInvoiceButtonComponent}
                </div>
            ),
        } as GenericContentData,
    };

    return (
        <div data-testid="ActivityDetailsInvoicesTab">
            {((invoices: Array<Invoice>) => {
                if (!invoices?.length) {
                    return <Section {...emptyInvoicesSectionProps} />;
                } else {
                    return (
                        <SpaceBetween size="xs">
                            <Box textAlign="center">
                                {addInvoiceButtonComponent}
                            </Box>
                            {invoices.map((invoice, index) =>
                                getInvoiceView(
                                    true,
                                    invoice,
                                    index,
                                    handleEditInvoice,
                                    () => setActiveRemoveIndex(index),
                                ),
                            )}
                        </SpaceBetween>
                    );
                }
            })(activity.billing_invoices)}
            <Can
                perform={Actions.ACTIVITY_MODIFY}
                yes={() => (
                    <Modal
                        onDismiss={() => setActiveRemoveIndex(null)}
                        visible={activeRemoveIndex !== null}
                        closeAriaLabel="Close modal"
                        size="medium"
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        data-testid="ActivityDetailsCancelDelete"
                                        onClick={() =>
                                            setActiveRemoveIndex(null)
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        data-testid="ActivityDetailsDeleteInvoice"
                                        variant="primary"
                                        onClick={() => handleRemoveInvoice()}
                                    >
                                        Delete invoice
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                        header="Delete this invoice?"
                    >
                        {activeRemoveIndex !== null && (
                            <Alert type="warning">
                                Are you sure you want to delete the{' '}
                                {
                                    activity.billing_invoices[activeRemoveIndex]
                                        .customer_name
                                }{' '}
                                invoice from this activity?
                            </Alert>
                        )}
                    </Modal>
                )}
                no={() => null}
            />
        </div>
    );
});

export default InvoicesTab;
