import 'regenerator-runtime';
import { useDispatch } from 'react-redux';
import { Workbook } from 'exceljs';
import React, { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Container,
    Header,
    Icon,
    Link,
    SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import FileUpload, { FileType } from '../../../../common/components/FileUpload';
import { useHistory } from 'react-router-dom';
import { BlockedTimesExcelUtil } from '../../../../common/utils/excelBlockedTimes';
import instructorManagementApi from '../../../../imt/api/instructorManagementApi';
import { useNotifications } from '../../../../common/context/grimsbyNotifications';
import { useBusinessDatum } from '../../Common/hooks/useBusinessDatum';
import {
    getInstructorBlockedTimeTypesList,
    resetInstructorBlockedTimeTypesSlice,
} from '../../../../common/store/slices/instructorBlockedTimeTypesSlice';

export const PAGE_TITLE = 'Import internal instructors blocked times';
export const CONTAINER_HEADER = 'File select';
export const DOWNLOAD_TEXT = 'Download Excel template';
const IMPORT_BLOCKED_TIMES_BUTTON = 'Import';
const IMPORTING_BLOCKED_TIMES_BUTTON = 'Importing';
const CANCEL_IMPORT_BLOCKED_TIMES_BUTTON = 'Cancel';
const DOWNLOAD_URL = '/templates/bulk_block_times_upload_template_v1.0.xlsx';

export const ImportBlockedTimes = () => {
    const [file, setFile] = useState<FileType>(null);
    const [resetKey, setResetKey] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [importing, setImporting] = useState(false);

    const { addNotification } = useNotifications();
    const history = useHistory();
    // get instructor blocked time types
    var { userProfile, instructorBlockedTimeTypeList } = useBusinessDatum();
    const instructorBlockedTimeTypes = instructorBlockedTimeTypeList.map(
        (item) => item.instructor_blockedtime_type,
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInstructorBlockedTimeTypesList());

        return () => {
            // reset business data slices
            // this code block should only run once
            dispatch(resetInstructorBlockedTimeTypesSlice());
        };
    }, [dispatch, userProfile]);

    const handleFileUpload = async () => {
        setImporting(true);
        const workbook = new Workbook();
        const excelUtil = new BlockedTimesExcelUtil(
            workbook,
            instructorBlockedTimeTypes,
        );
        await excelUtil.readWorkbook(file);
        if (excelUtil.errors.length > 0) {
            addNotification({
                id: `import-blocked-times-${Date.now()}`,
                ...{
                    type: 'error',
                    content: `The following errors found in the import file: ${excelUtil.errors}. Please use the template file.`,
                },
            });
        } else if (excelUtil.formattingErrors.size > 0) {
            setErrorMessage(
                `Formatting errors in rows: : ${excelUtil.skippedRows}. Download excel file with the errors.`,
            );
            excelUtil.generateErrorReport().saveAsUrl();
            return;
        } else {
            if (excelUtil.skippedRows.length > 0) {
                addNotification({
                    id: `import-blocked-times-${Date.now()}`,
                    ...{
                        type: 'success',
                        content: `Rows, containing no values will be skipped: ${excelUtil.skippedRows}`,
                    },
                });
            }
            const instructors_blocked_times = [];
            for (const [_, value] of Object.entries(
                excelUtil.instructorBlockedTimes.instructors_blocked_times,
            )) {
                instructors_blocked_times.push(value);
            }

            const bulkBlockedTimesPaylod = {
                start_date: excelUtil.instructorBlockedTimes.start_date,
                end_date: excelUtil.instructorBlockedTimes.end_date,
                instructors_blocked_times,
            };

            try {
                const response =
                    await instructorManagementApi.batchAddBlockedTimes(
                        bulkBlockedTimesPaylod,
                    );
                history.push({
                    pathname: '/activities/instructors',
                });

                addNotification({
                    id: `import-blocked-times-${Date.now()}`,
                    ...{
                        type: 'success',
                        content:
                            'Instructors blocked times updated successfully.',
                    },
                });
            } catch (error) {
                if (
                    error.response.message ===
                    'New blocked times conflicting with previous assignments.'
                ) {
                    setErrorMessage(
                        'Download excel file containing the instructors and their conflicting new blocked times.',
                    );
                    excelUtil
                        .generateConflictReport(
                            error.response.conflicting_assignments,
                        )
                        .saveAsUrl();
                } else if (
                    error.response.message ===
                    'Instructors not found or not Internal'
                ) {
                    setErrorMessage(
                        'Download excel file containing not found instructors.',
                    );
                    excelUtil
                        .generateErrorReport(
                            error.response.not_found_instructors,
                        )
                        .saveAsUrl();
                }

                addNotification({
                    id: `import-blocked-times-${Date.now()}`,
                    ...{
                        type: 'error',
                        content: error.response.message,
                    },
                });
            } finally {
                setImporting(false);
            }
        }
    };

    const handleFileChange = (event: CustomEvent<any>) => {
        const value: FileType = event.detail.value;
        setResetKey(Math.random().toString(36));
        setFile(value);
        setErrorMessage('');
        setImporting(false);
    };

    const handleCancelImport = () => {
        history.push({
            pathname: '/activities/instructors',
        });
    };

    return (
        <>
            <Header variant="h1">{PAGE_TITLE}</Header>
            <Box margin={{ top: 'l' }}>
                <Container
                    header={<Header variant="h2">{CONTAINER_HEADER}</Header>}
                >
                    <SpaceBetween direction="vertical" size="xs">
                        <Link
                            data-testid="template-download-btn"
                            href={DOWNLOAD_URL}
                        >
                            <>
                                <Icon name="download" variant="link" />{' '}
                                {DOWNLOAD_TEXT}
                            </>
                        </Link>

                        <Box variant="p">
                            Add the instructor email in column B, start/end date
                            and time columns E-H and Blocked time Name in column
                            I. Other fields are optional.
                        </Box>
                        <Box variant="p">
                            Upload the completed blocked time file. If there are
                            scheduling conflicts, the upload will fail and you
                            will be able to download an excel spreadsheet
                            containing all conflicting new blocked times.
                        </Box>
                        <Box margin={{ bottom: 'l' }}>
                            <FileUpload
                                buttonText={'Choose file'}
                                value={file}
                                onChange={(event) => handleFileChange(event)}
                                accept={'.xlsx,.xls'}
                                key={resetKey}
                            />
                        </Box>
                        <Alert visible={!!errorMessage} type="warning">
                            {errorMessage}{' '}
                            <a href="/" id="error-file-download">
                                {' '}
                            </a>
                        </Alert>
                    </SpaceBetween>
                </Container>
                <Box margin={{ top: 'l', bottom: 'l' }} float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={handleCancelImport}
                            data-testid="cancel-import-blocked-times"
                        >
                            {CANCEL_IMPORT_BLOCKED_TIMES_BUTTON}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleFileUpload}
                            disabled={!file || importing}
                            data-testid="import-blocked-times-btn"
                        >
                            {importing
                                ? IMPORTING_BLOCKED_TIMES_BUTTON
                                : IMPORT_BLOCKED_TIMES_BUTTON}
                        </Button>
                    </SpaceBetween>
                </Box>
            </Box>
        </>
    );
};

export default ImportBlockedTimes;
